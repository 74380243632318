<template>
    <div>
        <div v-if = '!edit' class="wrapper flex mb-5 mx-5 p-3" >
            <div class = 'w-full'>
                <div class=" w-full mb-3 h-10">
                    <el-button v-on:click = 'edit = true' type="primary" icon="el-icon-edit" circle class = 'float-right'></el-button>
                </div>
                <div class="flex w-full mb-5">
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'building' />
                            </div>
                            <div class="w-5/6 flex-col">
                                {{policy.verification.sub_contractor.name }}
                                <div class = 'subscript'><translate source = 'Subcontractor'/></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'user-tie' />
                            </div>
                            <div class="flex-col w-5/6">
                                {{policy.verification.sub_name}}
                                <div class = 'subscript'><translate source = 'Name Verification Sent To'/></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'envelope' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.verification.sub_email}}
                                    <div class = 'subscript'><translate source = 'Email Verification Sent To'/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class = 'flex w-full mb-5'>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'address-card' />
                            </div>
                            <div class="w-5/6 flex-col">
                                {{policy.verification.sub_contractor.address || 'No Address'}}
                                <br/>
                                {{policy.verification.sub_contractor.city}}
                                {{policy.verification.sub_contractor.state}}
                                {{policy.verification.sub_contractor.country}}
                                {{policy.verification.sub_contractor.zip}}
                                <div class = 'subscript'><translate source = 'Subcontractor' class = 'float-left pr-1'/> Address</div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'phone' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.verification.sub_contractor.phone || 'No Phone'}}
                                    <div class = 'subscript'><translate source = 'Subcontractor' class = 'float-left pr-1'/> Phone</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'envelope' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.agent_email}}
                                    <div class = 'subscript'><translate source = 'Agent Email'/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if = "policy.gc_notes" class = 'flex w-full mb-5'>
                    <b class = 'pr-3'>Note:</b>
                    {{ policy.gc_notes }}
                </div>
            </div>
        </div>
        <div v-else class="wrapper flex mb-5 mx-5 p-3" >
            <div class = 'w-full'>
                <div class=" w-full mb-3 h-10">
                    <el-button v-on:click = 'edit = false' type="danger" icon="el-icon-close" circle class = 'float-right'></el-button>
                    <el-button v-on:click = 'saveData' type="success" icon="el-icon-check" circle class = 'float-right mr-3'></el-button>
                </div>
                <div class="flex w-full mb-5">
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'building' />
                            </div>
                            <div class="w-5/6 flex-col">

                                <coi-input v-model = 'policyData.sub_name'/>
                                <div class = 'subscript'><translate source = 'Subcontractor'/></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'user-tie' />
                            </div>
                            <div class="flex-col w-5/6">
                                <coi-input v-model = 'policyData.verification_sub_name'/>
                                <div class = 'subscript'><translate source = 'Name Verification Sent To'/></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'envelope' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    <coi-input v-model = 'policyData.verification_sub_email'/>
                                    <div class = 'subscript'><translate source = 'Email Verification Sent To'/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class = 'flex w-full mb-5'>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'address-card' />
                            </div>
                            <div class="w-5/6 flex-col">
                                <coi-input v-model = 'policyData.address' placeholder = 'Address'/>
                                <coi-input v-model = 'policyData.city' placeholder = 'City'/>
                                <coi-input v-model = 'policyData.state' placeholder = 'State'/>
                                <coi-input v-model = 'policyData.zip' placeholder = 'Zip'/>
                                <div class = 'subscript'><translate source = 'Subcontractor' class = 'float-left pr-1'/> Address</div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'phone' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    <coi-input v-model = 'policyData.phone' placeholder = 'Phone'/>
                                    <div class = 'subscript'><translate source = 'Subcontractor' class = 'float-left pr-1'/> Phone</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'envelope' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    <coi-input v-model = 'policyData.agent_email'/>
                                    <div class = 'subscript'><translate source = 'Agent Email'/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HTTP from "../../../../utilities/http";
    import CoiInput from "../../../common/CoiInput";
    export default {
        name: 'PolicyData',
        props: ['policy'],
        components: {
            CoiInput
        },
        data() {
            return {
                edit: false,
                policyData: {},
            }
        },
        methods: {
            saveData() {
                HTTP({url: 'policy/save-data/', method: 'POST', data: this.policyData})
                    .then(resp => {
                        this.$notify({
                            title: 'Success',
                            message: resp.data.message,
                            type: 'success',
                            duration: 2500,
                        });
                        this.edit = false;
                        this.$emit('policy-data-saved');
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
        },
        watch: {
            policy: function(val) {
                this.policyData.sub_name = val.verification.sub_contractor.name;
                this.policyData.verification_sub_name = val.verification.sub_name;
                this.policyData.verification_sub_email = val.verification.sub_email;
                this.policyData.address = val.verification.sub_contractor.address;
                this.policyData.city = val.verification.sub_contractor.city;
                this.policyData.state = val.verification.sub_contractor.state;
                this.policyData.zip = val.verification.sub_contractor.zip;
                this.policyData.phone = val.verification.sub_contractor.phone;
                this.policyData.agent_email = val.agent_email;
                this.policyData.id = val.id;
            }
        }
    }
</script>
<style scoped>
    .wrapper{
        background-color: #f1f4ff;
    }
</style>
