import HTTP from "../../utilities/http";
import {AGENT_SUBMITTED_POLICY, CREATE_AGENT, GET_AGENT, SET_AGENT} from "../actions/agent";

const state = {
    agent: {
        user: [],
        policies: [],
    },
    token: null
};

const getters = {
    getAgent: state => state.agent,
};

const actions = {
    [AGENT_SUBMITTED_POLICY]: ((context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'agent/submitted-policy' , data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }),

    [CREATE_AGENT]: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'agent/create' , data: data, method: 'POST'})
                .then(resp => {
                    commit(SET_AGENT, resp.data.agent);
                    resolve(resp.data.agent);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [GET_AGENT]: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'agent/get' , data, method: 'POST'})
                .then(resp => {
                    commit(SET_AGENT, resp.data.agent);
                    resolve(resp.data.agent);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_AGENT]: (state, agent) => {
        state.agent = agent;
    },
};


export default {
    state,
    getters,
    actions,
    mutations,
}
