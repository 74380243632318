<template>
  <div>
    <div
      v-if="showModal"
      class="bg-white shadow overflow-hidden sm:rounded-lg mb-5"
    >
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Insurance Verification Request
        </h3>
        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {{ policy.name }}
        </p>
      </div>
    </div>

    <PolicyData :policy="policy" v-if="!showErroDiv" />
    <p class="shiftLeft">Contract Details</p>
    <ContractData
      :contract="policy"
      :policy="policy"
      v-if="!showErroDiv && policy.contract"
    />
    <p class="shiftLeft" v-else>No contract details found</p><br/>
    <p class="shiftLeft">Policy :- {{ policy.name }}</p>
    <PolicyFields
      v-if="policy.id"
      :fields="getFieldsForForm(policy)"
      :associated-files="policy.fields.associatedFiles"
      :editable="true"
      :showBack="showBack()"
      :policy-id="policy.id"
      :showDirections="policy.show_directions"
      :statusGroup="policy.status_group"
      v-on:policy-fields-saved="handlePolicyFieldsSave"
    />
    <div
      v-if="showModal"
      class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div
        class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Your Verification has been submitted
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Thank you for completing the policy verification. We have
                created an account for you so you can access all your
                verifications in one place at anytime. Please enter your name
                and a password below and we will complete the setup of your
                account and take you to your dashboard where you can see all
                policies in the system that belong to you.
              </p>
              <p>Thanks - The COI-Verify Team</p>
            </div>
            <div class="mt-2">
              <CoiInput v-model="agent_name" placeholder="Your Name" />
            </div>
            <div class="mt-2">
              <CoiInput v-model="agent_password" placeholder="Password" />
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <span class="flex w-full rounded-md shadow-sm">
            <button
              v-on:click="createAgent"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Save
            </button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="showErroDiv">
      <p>
        The Policy Verification Link you have is no longer valid. Please confirm
        you are using the most recent link. Verification requests are sent from
        <a href="mailto:noreplay@coi-verify.com" style="color: blue"
          >noreplay@coi-verify.com</a
        >. If you have previously created an account you can reach or open items
        dashboard by simply logging in
        <a :href="`${title}/login`" style="color: blue">here</a>. If you have
        not created an account and are unable to locate a valid link please
        notify your client and they can resend the current link.
      </p>
    </div>
  </div>
</template>

<script>
import PolicyData from "../agent/components/PolicyData";
import ContractData from "../agent/components/ContractData";
import PolicyFields from "../agent/components/PolicyFields";
const clone = require("lodash/cloneDeep");
import { GET_POLICY_FOR_INVITE } from "../../store/actions/policy";
import CoiInput from "../common/CoiInput";
import {
  AGENT_SUBMITTED_POLICY,
  CREATE_AGENT,
  GET_AGENT,
} from "../../store/actions/agent";

export default {
  name: "AgentInvite",
  components: {
    CoiInput,
    PolicyData,
    PolicyFields,
    ContractData,
  },
  data() {
    return {
      policy: {
        fields: {
          current: [],
        },
        verification: {
          sub_contractor: {},
        },
        contract: null,
      },
      contract: {},
      showModal: false,
      showErroDiv: false,
      agent_name: null,
      agent_password: null,
      title: process.env.VUE_APP_ROOT,
    };
  },
  methods: {
    showBack() {
      return this.$store.getters.getAgent && this.$store.getters.getAgent.id;
    },
    createAgent() {
      if (!this.agent_name.length || !this.agent_password.length) {
        this.$notify({
          title: "Warning",
          message: "You need to enter your name and desired password",
          type: "warning",
        });
        return;
      }
      this.$store
        .dispatch(CREATE_AGENT, {
          name: this.agent_name,
          email: this.policy.agent_email,
          password: this.agent_password,
        })
        .then((agent) => {
          this.$router.push("/agent?token=" + agent.user.api_token);
        })
        .catch();
    },
    getFieldsForForm(policy) {
      if (policy.fields.current.length) {
        return policy.fields.current;
      }
      return policy.fields.draft;
    },
    handlePolicyFieldsSave() {
      this.$store
        .dispatch(AGENT_SUBMITTED_POLICY, { policyId: this.policy.id })
        .then(() => {
          this.$store
            .dispatch(GET_AGENT, { email: this.policy.agent_email })
            .then((agent) => {
              if (!agent) {
                this.showModal = true;
              } else {
                if (agent.policies[0].status_group == "non-compliant") {
                  this.$notify({
                    title: "Error",
                    message:
                      "Your client will not be qualified to work for this certificate holder.  Otherwise if you change it to the required answer we will accept it.",
                    type: "error",
                  });
                } else {
                  this.$notify({
                    title: "Success",
                    message: "Your policy data has been saved",
                    type: "success",
                  });
                }

                this.$router.push("/agent?token=" + agent.user.api_token);
              }
            })
            .catch();
        })
        .catch();
    },
  },
  mounted() {
    this.$store
      .dispatch(GET_POLICY_FOR_INVITE, this.$route.params.inviteCode)
      .then((policy) => {
        this.policy = clone(policy);
        this.contract = policy.contract;
        // console.log("This is now good", this.policy.contract);
      })
      .catch(() => {
        this.showErroDiv = true;
      });
  },
};
</script>

<style scoped>
.shiftLeft {
  margin-left: 20px;
}
</style>
