<template>
    <div>
        <div class = 'flex'>
            <div class = 'w-10/12'>
                <div class="flex mt-5">
                    <div class="w-full">
                        <label class = 'mr-2'>
                            Select Template :
                        </label>
                    </div>
                </div>
                <div class="flex mt-5">
                    <div class="w-full">
                        <select v-model="selectedOption" @change="onChange($event)" placeholder = "Select One" class="formctrl selecttemplate">
                                <option value=""> Please Select One</option>
                                <option v-for="item in allPolicies" v-bind:key="item.id" :value='item.id'>
                                    {{item.name}}
                                </option>
                        </select>  
                    </div>
                </div>
            </div>
        </div>
        <transition name = 'slide'>
            <div class = 'flex' v-show="isShow">
                <div class = 'w-10/12'>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Name:
                            </label>
                            <coi-input v-model = 'name'/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class = 'w-2/12'>
            <div class = 'flex mt-5'>
                <div class=" w-full mb-3 h-10">
                    <el-button v-on:click = 'saveTemplateData' type="success" icon="el-icon-check" circle class = 'float-right mr-3'></el-button>
                    <el-button v-on:click = 'cancel' type="danger" icon="el-icon-close" circle class = 'float-right'></el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HTTP from "../../../../utilities/http";
    import CoiInput from "../../../common/CoiInput";
    export default {
        name: 'SettingsPolicyData',
        props: ['vendor', 'dialog'],
        components: {
            CoiInput,
        },
        data() {
            return {
                possibleDuplicates: [],
                allPolicies : [],
                selectedOption:'',
                isShow:false,
                name:'',
            }
        },
        methods: {
            cancel() {
                if(this.dialog) {
                    this.$emit('policy-close-dialog');
                    this.$emit('policyfield-close-dialog');
                }
                this.edit = false
            },
            getallPolicies() {
                HTTP({url: 'settings-policy/getall', method: 'GET'})
                    .then(resp => {
                        this.allPolicies = resp.data;
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            onChange(e) {
                let selectedText = e.target.options[e.target.options.selectedIndex].value;
//                console.log("==============>",selectedText);
                if (selectedText != "") {
                    this.isShow = true;
                    this.name = '';
                } else {
                    this.isShow = false;
                }
                // if(selectedText != 'Custom')
                // {
                //     this.isShow = true;
                //     this.name = '';
                // }
                // else{
                //     this.isShow = false;
                // }
            },
            saveTemplateData() {
                HTTP({url: 'settings-policy/policy-templates-add/', method: 'POST', data: {
                    name: this.name,
                    existingtemplate: this.selectedOption}})
                    .then(resp => {
                        this.$notify({
                            title: 'Success',
                            message: resp.data.message,
                            type: 'success',
                            duration: 2500,
                        });
                        this.$emit('policy-data-saved', resp.data.template);
                        if(this.dialog) {
                            this.$emit('policy-close-dialog');
                        }
                        this.$router.go(0);
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
                    
            },
        },
        mounted() {
            this.getallPolicies();
        }
    }
</script>
<style scoped>
    select{
        width: 90%;
    }
    .selecttemplate{
      height: 40px;
      border: 1px solid;
      background-color: white;
    }
</style>
