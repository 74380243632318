export const INIT_SETTINGS_POLICIES = 'INIT_SETTINGS_POLICIES';
export const SET_POLICY_STATE = 'SET_POLICY_STATE';
export const SET_POLICIES = 'SET_POLICIES';
export const HANDLE_POLICY_SAVE = 'HANDLE_POLICY_SAVE';
export const DELETE_POLICY = 'DELETE_POLICY';
export const SELECT_TEMPLATE_FILEDS = 'SELECT_TEMPLATE_FILEDS';
export const SET_POLICY_SORT = 'SET_POLICY_SORT';
export const SEARCH_POLICIES = 'SEARCH_POLICIES';
export const DELETE_POLICY_FIELD = 'DELETE_POLICY_FIELD';
export const DELETE_FIELD = 'DELETE_FIELD';









