<template>
    <div>
        <div v-if = "showModal" class="bg-white shadow overflow-hidden sm:rounded-lg mb-5">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Insurance Verification Request
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    {{ policy.name }}
                </p>
            </div>
        </div>
        <PolicyData :policy = 'policy' v-if = "policy.agent_email"/>
        <div v-if = "!policy.agent_email">
            <p>The Policy Verification Link you have is no longer valid.  Please confirm you are using the most recent link.  Verification requests are sent from <a href="mailto:noreplay@coi-verify.com" style='color:blue'>noreplay@coi-verify.com</a>.  If you have previously created an account you can reach or open items dashboard by simply logging in <a :href="`${title}/login`" style='color:blue'>here</a>.  If you have not created an account and are unable to locate a valid link please notify your client and they can resend the current link. </p>
        </div>
        <div class = 'wrapper mx-5 p-3' v-if = "policy.agent_email">
            <div  class="flex mb-5 p-2 ">
                <div class="w-1/2 flex">
                    <div>Thanks for letting us know you are no longer the agent of record for the policy above.  Please let us know any message or reason for removing removing yourself from this policy verification so we can notify the insured.  <p>Thanks </p> </div>
                </div>
                <div class="w-1/2">
                    <div class="subscript">
                        <textarea class = 'textarea mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' v-model="agentLeaveReason"></textarea>
                    </div>
                </div>
            </div>
            <div  class=" w-full mb-5">
                <button v-on:click = 'saveData' class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right mr-3">Save</button>
                <!-- <router-link v-if = "showBack" :to = "{name: 'agent-dashboard'}">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right mr-3">Dashboard</button>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script>
    import PolicyData from "../agent/components/PolicyData";
    const clone = require('lodash/cloneDeep');
    import {GET_POLICY_FOR_INVITE, CHANGE_AGENT} from "../../store/actions/policy";
    import {AGENT_SUBMITTED_POLICY, CREATE_AGENT, GET_AGENT} from "../../store/actions/agent";

    export default {
        name: 'AgentReject',
        components: {
            PolicyData, 
        },
        data() {
            return {
                policy: {
                    fields: {
                        current: []
                    },
                    verification: {
                        sub_contractor: {},
                    },
                },
                showModal: false,
                showErroDiv:false,
                agent_name: null,
                agent_password: null,
                title: process.env.VUE_APP_ROOT,
                agentLeaveReason: ''
            }

        },
        methods: {
            saveData() {
                if(!this.agentLeaveReason.length) {
                    this.$notify({
                        title: 'Warning',
                        message: 'You need to enter the valid reason',
                        type: 'warning'
                    });
                    return;
                }
                this.$store.dispatch(CHANGE_AGENT,  {
                    policyId: this.policy.id,
                    agentLeaveReason: this.agentLeaveReason,
                    
                }).then(() => {
                    this.$store.dispatch(GET_POLICY_FOR_INVITE, this.$route.params.inviteCode).then((policy) => {
                    this.policy = clone(policy);
                    this.$notify({
                        title: 'Success',
                        message: 'Your query submitted',
                        type: 'success'
                    });
                    return;
            }).catch(()=>{
                this.showErroDiv = true;
            });
                })
                .catch();
            },
            showBack() {
                return this.$store.getters.getAgent && this.$store.getters.getAgent.id;
            },
            createAgent() {
                if(!this.agent_name.length || !this.agent_password.length) {
                    this.$notify({
                        title: 'Warning',
                        message: 'You need to enter your name and desired password',
                        type: 'warning'
                    });
                    return;
                }
                this.$store.dispatch(CREATE_AGENT, {
                    name: this.agent_name,
                    email: this.policy.agent_email,
                    password: this.agent_password
                }).then((agent) => {
                    this.$router.push('/agent?token=' + agent.user.api_token);
                })
                .catch();
            },
            getFieldsForForm(policy) {
                if(policy.fields.current.length) {
                    return policy.fields.current;
                }
                return policy.fields.draft;
            },
            handlePolicyFieldsSave() {
                this.$store.dispatch(AGENT_SUBMITTED_POLICY, {policyId: this.policy.id}).then(() => {
                    this.$store.dispatch(GET_AGENT, {email: this.policy.agent_email}).then((agent) => {
                        if(!agent) {
                            this.showModal = true;
                        } else {
                            if (agent.policies[0].status_group == "non-compliant") {
                                this.$notify({
                                title: 'Error',
                                message: 'Your client will not be qualified to work for this certificate holder.  Otherwise if you change it to the required answer we will accept it.',
                                type: 'error'
                            });
                            } else {
                                this.$notify({
                                title: 'Success',
                                message: 'Your policy data has been saved',
                                type: 'success'
                            });
                            }
                            
                            this.$router.push('/agent?token=' + agent.user.api_token);
                        }
                    }).catch();
                }).catch();
            }
        },
        mounted() {
            this.$store.dispatch(GET_POLICY_FOR_INVITE, this.$route.params.inviteCode).then((policy) => {
                this.policy = clone(policy);
            }).catch(()=>{
                this.showErroDiv = true;
            });
        }
    }
</script>

<style scoped>
    .wrapper{
        background-color: #f1f4ff;
    }
    .file-select{
        width: 90%;
        background-color: #FFFFFF;
    }
    .textarea{
        width: 90%;
    }
</style>
