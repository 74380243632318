<template>
    <div>
        <div class = 'wrapper mx-5 p-3'>
            <!-- <div class="flex mb-5 p-2 ">
                <div class="w-2/6 flex">
                    <div>Name</div>
                </div>
                <div class="w-2/6 flex">
                    <div>Description</div>
                </div>
                <div class="w-2/6 flex">
                    <div>Required Answer</div>
                </div>
                <div class="w-2/6 flex">
                    <div>Instructions</div>
                </div>
            </div> -->
            <div v-for="(field) in fieldData " :key="field.id" :class = "getClass(field)" class="flex mb-5 p-2 "
            :style="[field.is_accept == 'Yes' ? {'background': '#90ee90'} : {'background': ''}]"
            >
                <div class="w-2/6 flex">
                    <div>{{field.name}}</div>
                </div>
                <div class="w-2/6">
                    <div class="subscript" v-if="field.help_text">
                        <span v-html = "field.help_text"></span>
                    </div>
                </div>
                <div class="w-2/6" v-if = "showDirections">
                    <div class="subscript" v-if="field.required_answer">
                        Required - <span v-html = "field.required_answer"></span>
                    </div>
                </div>
                <div class="w-2/6" v-if = "showDirections">
                    <div class="subscript" v-if="field.agent_directions">
                        Directions - <span v-html = "field.agent_directions"></span>
                    </div>
                </div>
                <div  class="w-2/6 pl-3 ">
                    <div v-if = "field.type == 'upload'">
                        <span v-if = 'field.attachment && field.is_accept == "Yes"' v-on:click = "openWindow(field.file_link)" class = 'cursor-pointer mb-3'>{{field.attachment.display_name}}</span>
                        <br/>
                        <input type="file" :id = "field.id" v-on:change="handleFileUpload" class = 'my-3' :disabled="field.is_accept == 'Yes'"/>
                        <br/>
                        <select v-if = 'field.associatedFile && field.is_accept == "Yes"' v-model = 'field.associatedFile' class = 'file-select mb-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' :disabled="field.is_accept == 'Yes'">
                            <option value = ''>Select a related file</option>
                            <option
                                v-for = 'item in associatedFiles'
                                :key = "item.id"
                                :value = "item.id"
                                >{{ item.display_name }}</option>
                        </select>
                    </div>
                    <div v-if = "field.type == 'select' || field.type == 'dropdown'" >
                        <select v-model = 'field.value' class = 'file-select mb-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' :disabled="field.is_accept == 'Yes'">
                            <option value = ''>Select One</option>
                            <option
                                    v-for = "item in field.data.split(',')"
                                    :key = "item"
                                    :value = "item"
                            >{{ item }}</option>
                        </select>
                    </div>
                    <div v-if = "field.type == 'date'" >
                        <el-date-picker
                                v-model="field.value"
                                type = "date"
                                format = 'M/dd/yyyy'
                                input-class = 'datepicker-input mb-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                :disabled="field.is_accept == 'Yes'"
                                required
                        >
                        </el-date-picker>
                    </div>
                    <div v-if = "field.type == 'text'">
                        <coi-input v-model = 'field.value'/>
                    </div>
                    <div v-if = "field.type == 'longtext'">
                        <textarea v-model = 'field.value' class = 'textarea mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' :disabled="field.is_accept == 'Yes'"></textarea>
                    </div>
                    <div v-if = "field.type == 'checkbox'">
                        <div class = 'float-left mr-5' v-for = "(labeltext, index) in field.data" :key = index>
                            <input type = "checkbox" :value = labeltext v-model = "field.valueArray" :disabled="field.is_accept == 'Yes'"/>
                            <label class = 'ml-2'>{{ labeltext}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div  class=" w-full mb-5">
                <button v-on:click = 'saveData' class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right mr-3">Save</button>
                <router-link v-if = "showBack" :to = "{name: 'agent-dashboard'}">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right mr-3">Dashboard</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    const clone = require('lodash/cloneDeep');
    import CoiInput from "../../common/CoiInput";
    import {SAVE_POLICY_CHANGESET_AGENT} from "../../../store/actions/policy";
    export default {
        name: 'PolicyFields',
        props: ['fields', 'editable', 'associatedFiles', 'policyId', 'showBack', 'showDirections', 'statusGroup'],
        components: {
            CoiInput
        },
        data() {
            return {
                edit: false,
                fieldData: {},
                file: '',
                files: [],
            }
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            getClass(field) {
                if(field.type == 'upload') {
                    return field.is_required && !this.file && !field.attachment_id ? 'bg-red-200' : '';
                }
                return field.is_required && !field.value ? 'bg-red-200' : '';
            },
            handleFileUpload(event) {
                this.files[event.target.id] = event.target.files[0];
            },
            saveData() {
                if(this.canSave()) {
                    let data = new FormData();
                    data.append('fields', JSON.stringify(this.fieldData, function(key, value) { return value}));
                    data.append('policyId', this.policyId);
                    this.files.forEach((element, index) => {
                        data.append('file_' + index, element);
                    });
                    this.$store.dispatch(SAVE_POLICY_CHANGESET_AGENT, data).then(() => {
                        this.$emit('policy-fields-saved');
                    }).catch();
                    this.edit = false;
                } else {
                    this.$notify({
                        title: 'Warning',
                        message: 'Please fill out all required fields',
                        type: 'warning'
                    });
                }
            },
            canSave() {
                let canSave = true;
                this.fieldData.forEach(field => {
                    if(field.is_required) {
                        if(field.type == 'upload') {
                            if(!field.attachment_id) {
                                if(!(field.id in this.files)) {
                                    canSave = false;
                                }
                            }
                        } else {
                            if(!field.value) {
                                canSave = false;
                            }
                        }
                    }
                    let currentData = new Date();
                    
                    
                    if (field.type == 'date') {
                        if (currentData >= field.value) {
                            field.value = field.value ? new Date(field.value) : "";
                            alert('Date must be in future');
                            canSave = false;
                        }                        
                    }
                });
                return canSave;
            },
            openWindow(link) {
                window.open(link,
                    'newwindow',
                    'width=900,height=800');
            },
        },
        mounted() {
            this.fieldData = clone(this.fields);
            this.fieldData.forEach(field => {
                        if(field.type == 'upload') {
                            if(field.is_accept == "No") {
                                field.attachment_id = "";
                                this.files = [];
                            } else if(this.statusGroup == "does-not-meet-requirements") {
                                field.attachment_id = "";
                                this.files = [];
                            }
                        } 
            });
        }
    }
</script>
<style scoped>
    .wrapper{
        background-color: #f1f4ff;
    }
    .file-select{
        width: 90%;
        background-color: #FFFFFF;
    }
    .textarea{
        width: 90%;
    }
</style>