<template>
  <div>
    <div class="flex header">
      <div class="w-1/3">
        <router-link :to="{name: 'reports'}">
          <div class="back-button flex">
            <div class="arrow">&#x2B60;</div>
            <div>Back to Reports</div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="text-lg font-bold count">Export - {{ reportName }}</div><br/>
    <div class="w-5/6 justify-end items-center">
      <el-date-picker
          class="daterange-picker mr-3"
          v-model="dateValue"
          type="daterange"
          start-placeholder="Start date"
          end-placeholder="End date"
          :default-value="defaultTime"
          unlink-panels
      />
      <el-button class='float' type='success' @click="downloadAttachments" :loading="loading">Download</el-button>
    </div>
  </div>

</template>

<script>
import {COMBINE_CERTIFICATES} from "@/store/actions/report";

export default {
  name: "Report",
  data() {
    return {
      dateValue: '',
      defaultTime: [
        new Date(2015, 0, 1),
        new Date()
      ],
      reportName: 'Certificates',
      loading: false,
    };
  },
  methods: {
    downloadAttachments() {
      this.loading = true;
      this.$store.dispatch(COMBINE_CERTIFICATES, this.dateValue)
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'CertificateExportDownload.pdf');

            document.body.appendChild(link)
;
            link.click();

            document.body.removeChild(link)
;
            window.URL.revokeObjectURL(url);
          })
          .catch(async err => {
            this.$notify({
              title: 'Warning',
              message: JSON.parse(await err.response.data.text()).message,
              type: 'warning'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>


<style>
.daterange-picker {
  width: 24rem !important;
}
</style>