<template>
    <div>
        <div class="wrapper flex mb-5 mx-5 p-3" >
            <div class = 'w-full'>
                <div class="flex w-full mb-5">
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'building' />
                            </div>
                            <div class="w-5/6 flex-col">
                                {{policy.verification.sub_contractor.name }}
                                <div class = 'subscript'>Subcontractor</div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'user-tie' />
                            </div>
                            <div class="flex-col w-5/6">
                                {{policy.verification.sub_name}}
                                <div class = 'subscript'>Name Verification Sent To</div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'envelope' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.verification.sub_email}}
                                    <div class = 'subscript'>Email Verification Sent To</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class = 'flex w-full mb-5'>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'address-card' />
                            </div>
                            <div class="w-5/6 flex-col">
                                {{policy.verification.sub_contractor.address || 'No Address'}}
                                <br/>
                                {{policy.verification.sub_contractor.city}}
                                {{policy.verification.sub_contractor.state}}
                                {{policy.verification.sub_contractor.country}}
                                {{policy.verification.sub_contractor.zip}}
                                <div class = 'subscript'>Subcontractor Address</div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'phone' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.verification.sub_contractor.phone || 'No Phone'}}
                                    <div class = 'subscript'>'Subcontractor Phone</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'envelope' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.agent_email}}
                                    <div class = 'subscript'>Agent Email</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class = 'flex w-full mb-5'>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'building' />
                            </div>
                            <div class="flex-col w-5/6">
                                <div>
                                    {{policy.verification.project.user.general_contractor.name}}
                                    <div class = 'subscript'>Certificate Holder</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-1/3'>
                        <div class = 'flex'>
                            <div class = 'fa-icon w-1/6 flex-col'>
                                <font-awesome-icon icon = 'address-card' />
                            </div>
                            <div class="w-5/6 flex-col">
                                {{policy.verification.project.user.general_contractor.address || 'No Address'}}
                                <br/>
                                {{policy.verification.project.user.general_contractor.city}}
                                {{policy.verification.project.user.general_contractor.state}}
                                {{policy.verification.project.user.general_contractor.zip}}
                                <div class = 'subscript'>Certificate Holder</div>
                            </div>
                        </div>
                    </div>

                    <div class = 'w-1/3'>
                        <div class = 'flex'>

                        </div>
                    </div>
                </div>
                <div v-if = "policy.gc_notes" class = 'flex w-full mb-5'>
                    <b class = 'pr-3'>Note:</b>
                    {{ policy.gc_notes }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PolicyData',
        props: ['policy'],
        components: {
        },
        data() {
            return {
            }
        },
        methods: {

        },
        watch: {

        }
    }
</script>
<style scoped>
    .wrapper{
        background-color: #f1f4ff;
    }
</style>
