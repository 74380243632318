<template>
  <div>
    <div v-show="!edit" class="wrapper mx-5 p-3">
      <div v-if="editable" class="w-full mb-5">
        <el-button v-on:click="edit = true" type="primary" icon="el-icon-edit" circle class="float-right">
        </el-button>
      </div>
      <div v-for="field in fields" :key="field.id" class="flex mb-5">
        <div class="w-2/6 flex">
          <div>{{ field.name }}</div>
        </div>
        <div class="w-2/6">
          <div class="subscript" v-if="field.help_text">
            <vue-simple-markdown :source="field.help_text">
            </vue-simple-markdown>
          </div>
        </div>
        <div v-if="field.file_link" class="w-2/6 pl-3">
          <span v-on:click="openWindow(field.file_link)" class="cursor-pointer">{{ field.attachment.display_name }}
          </span>
        </div>
        <div v-else class="w-2/6 pl-3">
          {{ field.value }}
        </div>
      <div class="w-2/6 pl-3">
        <template v-if="field.required_answer && field.required_answer.trim() !== ''">
            <span class="font-bold">Required Answer:</span> {{ field.required_answer }}
        </template>
        </div>
        <div class="w-2/6" v-if = "field.is_accept == 'Yes'">
            <div style="border:1px solid #90ee90; display: block;width: 80px;text-align: center;">Accepted</div>
        </div>

        <div class="w-2/6" v-if = "field.is_accept == 'No'">
            <div style="border:1px solid #FF0000; display: block;width: 80px;text-align: center;">Rejected</div>
        </div>

        <div class="w-2/6" v-if = "!field.is_accept">
            
        </div>
      </div>
    </div>
    <div v-if="editable" v-show="edit" class="wrapper mx-5 p-3">
      <div v-if="editable" class="w-full mb-5">
        <el-button
          v-on:click="edit = false"
          type="danger"
          icon="el-icon-close"
          circle
          class="float-right"
        ></el-button>
        <el-button
          v-on:click="saveData"
          type="success"
          icon="el-icon-check"
          circle
          class="float-right mr-3"
        ></el-button>
      </div>
      <div v-for="field in fieldData" :key="field.id" class="flex mb-5">
        <div class="w-2/6 flex">
          <div>{{ field.name }}</div>
        </div>
        <div class="w-2/6">
          <div class="subscript" v-if="field.help_text">
            <vue-simple-markdown
              :source="field.help_text"
            ></vue-simple-markdown>
          </div>
        </div>
        <div class="w-2/6 pl-3">
          <div v-if="field.type == 'upload'">
            <span
              v-on:click="openWindow(field.file_link)"
              class="cursor-pointer mb-3"
              >{{ field.attachment_display_name }}</span
            >
            <br />
            <input
              type="file"
              :id="field.id"
              v-on:change="handleFileUpload"
              class="my-3"
            />
            <br />
            <select
              v-model="field.associatedFile"
              class="file-select mb-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select a related file</option>
              <option
                v-for="item in associatedFiles"
                :key="item.id"
                :value="item.id"
              >
                {{ item.display_name }}
              </option>
            </select>
          </div>
          <div v-if="field.type == 'select' || field.type == 'dropdown'">
            <select
              v-model="field.value"
              class="file-select mb-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select One</option>
              <option v-for="item in field.data" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div v-if="field.type == 'date'">
            <datepicker
              v-model="field.value"
              format="MM/dd/yyyy"
              input-class="mb-1 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></datepicker>
          </div>
          <div v-if="field.type == 'text'">
            <coi-input v-model="field.value" />
          </div>
          <div v-if="field.type == 'longtext'">
            <textarea
              v-model="field.value"
              class="textarea mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></textarea>
          </div>
          <div v-if="field.type == 'checkbox'">
            <div class="float-left mr-5" v-for="(labeltext, index) in field.data" :key="index">
              <input type="checkbox" :value="labeltext" v-model="field.valueArray"/>
              <label class="ml-2">{{ labeltext }}</label>
            </div>
          </div>
        </div>
        <div class="w-2/6 pl-3">
          <template v-if="field.required_answer && field.required_answer.trim() !== ''">
            <span class="font-bold">Required Answer:</span> {{ field.required_answer }}
          </template>
        </div>
 
        <div class="w-2/6" v-if="field.type !== 'none'">

          <div>
            <div
              class="float-left mr-5">
              <input type="radio" :name="field.id" v-model="field.is_accept" :key="field.id" value="Yes" /> 
              <!-- labael as receive no accept per convention-->
              <label class="ml-2" for="AcceptField">Receive</label>
            </div>
          </div>

          <div>
            <div class="float-left mr-5">
              <input type="radio" :name="field.id" v-model="field.is_accept" :key="field.id" value="No" /> 
              <label class="ml-2" or="AcceptField">Reject</label>
            </div>
          </div>

          <div>
            <div
              class="float-left mr-5">
              <input type="radio" :name="field.id" v-model="field.is_accept" :key="field.id" value="" /> 
              <label class="ml-2" or="AcceptField">None</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoiInput from "../../../common/CoiInput";
import { SAVE_POLICY_CHANGESET } from "../../../../store/actions/policy";
import Datepicker from "vuejs-datepicker";
export default {
  name: "PolicyFields",
  props: ["fields", "editable", "associatedFiles", "policyId"],
  components: {
    CoiInput,
    Datepicker,
  },
  data() {
    return {
      edit: false,
      fieldData: {},
      file: "",
      files: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      this.files[event.target.id] = event.target.files[0];
    },
    saveData() {
      let data = new FormData();
      data.append(
        "fields",
        JSON.stringify(this.fieldData, function (key, value) {
          return value;
        })
      );
      data.append("policyId", this.policyId);
      this.files.forEach((element, index) => {
        data.append("file_" + index, element);
      });
      this.$store
        .dispatch(SAVE_POLICY_CHANGESET, data)
        .then(() => {
          this.$emit("policy-fields-saved");
        })
        .catch();
      this.edit = false;
    },
    openWindow(link) {
      window.open(link, "newwindow", "width=900,height=800");
    },
  },
  mounted() {
    this.fields.forEach((field) => {
      let data = {
        id: field.id,
        name: field.name,
        type: field.type,
        data: field.data ? field.data.split(",") : null,
        attachment_display_name: "",
        help_text: field.help_text,
        value: field.value,
        valueArray: field.value ? field.value.split(",") : [],
        file_link: field.file_link,
        is_accept: field.is_accept,
        required_answer: field.required_answer
      };
      if (field.attachment) {
        data.attachment_display_name = field.attachment.display_name;
      }
      this.fieldData[field.id] = data;
    });
  },
};
</script>
<style scoped>
.wrapper {
  background-color: #f1f4ff;
}
.file-select {
  width: 90%;
  background-color: #ffffff;
}
.textarea {
  width: 90%;
}
</style>
