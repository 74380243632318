<template>
    <div class="main">
    <p class="sign" align="center">Reset Password</p>
    <form class="form1">
      <p v-if="error" class="error-text">{{error}}</p>
      <p v-if="success" class="success-text">Email verification link send successfully</p>
      <input class="un" type="text" align="center" placeholder="Email" v-model="formData.email">
      <input class="un" type="password" align="center" placeholder="New Password" v-model="formData.new_password">
      <input class="un" type="password" align="center" placeholder="Confirm Password" v-model="formData.confirm_password">
      <!-- <input class="pass" type="password" align="center" placeholder="Password" v-model="formData.password"> -->
      <button class="submit" type="button" v-on:click = 'submitForm'>Submit</button>
      <!-- <p class="forgot" align="center"><router-link to="/login">Login</router-link></p> -->
    </form>            
    </div>
</template>
<script>
import HTTP from "../../utilities/http";
import Validator from "../common/Validator";
// import {AUTH_SET_TOKEN} from "../../store/actions/auth";
// import {LOAD_TRANSLATION} from "../../store/actions/translate";
export default {
        name: 'Resetpassword',
        components: {
        },
        data() {
            return {
                formData :{
                    email:"",
                    new_password: "",
                    confirm_password: "",
                    token: this.$route.params.id
                },
                error:"",
                success:false,
            }
        },
        methods: {
            getLink(link) {
                return process.env.VUE_APP_ROOT + link;
            },
           submitForm() {
               if(!Validator([
                    {field: this.formData.email, name: 'Email'},
                    {field: this.formData.new_password, name: 'New Password'},
                    {field: this.formData.confirm_password, name: 'Confirm Password'},
                    // {field: this.vendorData.primary_contact, name: 'Primary Contact'},
                    // {field: this.formData.password, name: 'Password'},
                ])) {
                    return;
                }

                HTTP({url: 'reset-password', method: 'POST', data: this.formData})
                    .then((resp) => {
                        if (resp.data.status == 200) {
                            this.$notify({
                            title: 'Success',
                            message: `Password reset successfully`,
                            type: 'success',
                            duration: 2500,
                           });
                            this.$router.push('/login');
                        } else {
                            this.error = resp.data.message;
                        }
                        
                        // console.log("=====>", resp);
                        // this.formData.email = "";
                        // this.success = true;
                        // this.$store.dispatch(AUTH_SET_TOKEN, {token: resp.data.api_token});
                        // this.$store.dispatch(LOAD_TRANSLATION).then(() => {}).catch();
                        //console.log("=====>", resp.data);
                        // if (resp.data.error) {
                        //     this.formData.email = "";
                        //     // this.formData.password = "";
                        //     this.error = resp.data.error;
                        // } else {
                        //     this.error="";
                        //     this.$notify({
                        //     title: 'Success',
                        //     message: `Welcome ${resp.data.display_name}`,
                        //     type: 'success',
                        //     duration: 2500,
                        // });
                        // window.location.href = process.env.VUE_APP_ROOT+'/create_auth/'+resp.data.api_token;
                        // this.getLink();
                            // if (resp.data.type == "agency") {
                            //     this.$router.push('/agency/dashboard');
                            // } else if (resp.data.type == "agent"){
                            //     this.$router.push('/agent');
                            // } else {
                            //     this.$router.push('/dashboard');
                            // }
                      //  }
                        
                    })
                    .catch(() => {
                        this.error = true;
                        // this.$notify({
                        //     title: 'Error',
                        //     message: err.response.data,
                        //     type: 'error'
                        // });
                    });
           }
        },
        computed: {
            
        },
        mounted() {
            
        }
    }
    </script>
    <style scoped>
    body {
        background-color: #F3EBF6;
        font-family: 'Ubuntu', sans-serif;
    }
    
    .main {
        background-color: #FFFFFF;
        width: 600px;
        height: 400px;
        margin: 7em auto;
        border-radius: 1.5em;
        box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    }
    
    .sign {
        padding-top: 40px;
        color: #255897;
        font-family: 'Ubuntu', sans-serif;
        font-weight: bold;
        font-size: 23px;
        margin: auto 0;
    }
    
    .un {
    width: 85%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
    }
    
    form.form1 {
        padding-top: 40px;
    }
    
    .pass {
    width: 85%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
    }
    
   
    .un:focus, .pass:focus {
        border: 2px solid rgba(0, 0, 0, 0.18) !important;
        
    }
    
    .submit {
      cursor: pointer;
        border-radius: 5em;
        color: #fff;
        background: linear-gradient(to right, #255897, #255880);
        border: 0;
        padding-left: 65px;
        padding-right: 65px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-family: 'Ubuntu', sans-serif;
        margin-left: 35%;
        font-size: 13px;
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
    }
    
    .forgot {
        text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
        color: #255880;
        padding-top: 15px;
    }
    
    a {
        text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
        color: #255880;
        text-decoration: none
    }
    
    @media (max-width: 600px) {
        .main {
            border-radius: 0px;
        }
    }
    .error-text {
        color:red;
        text-align:center;
    }
    .success-text {
        color:green;
        text-align:center;
    }
    </style>
