import {
    INIT_SETTINGS_POLICIES, SET_POLICIES, SET_POLICY_STATE, HANDLE_POLICY_SAVE, DELETE_POLICY, SELECT_TEMPLATE_FILEDS
} from "../actions/settings_policy";
import HTTP from "../../utilities/http";

const state = {
    initialized: false,
    policies: [],
    selectedTemplateField: [],
};

const getters = {
    getPolicies: state => state.policies,
    getTemplateField: state => state.selectedTemplateField,

};

const actions = {

    [INIT_SETTINGS_POLICIES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if (!state.initialized) {
                commit(SET_POLICY_STATE);
                HTTP({url: 'settings-policy/all', method: 'GET'})
                    .then(resp => {
                        commit(SET_POLICIES, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    },
    [HANDLE_POLICY_SAVE]: ({commit}, policy) => {
        return new Promise((resolve) => {
            commit(HANDLE_POLICY_SAVE, policy);
            resolve();
        });
    },
    [DELETE_POLICY]: ({commit}, policyId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'settings-policy/policy-templates-delete/' + policyId, method: 'GET'})
                .then(resp => {
                    commit(DELETE_POLICY, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [SELECT_TEMPLATE_FILEDS]: ({commit}, templateId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'settings-policy/policy-templates-edit/' + templateId, method: 'GET'})
                .then(resp => {
                    commit(SELECT_TEMPLATE_FILEDS, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
};

const mutations = {

    [SET_POLICIES]: (state, resp) => {
        state.policies = resp.data
    },
    [SET_POLICY_STATE]: (state) => {
        state.initialized = true;
    },
    [HANDLE_POLICY_SAVE]: (state, newPolicy) => {
        let newPolicys = [];
        let handled = false;
        state.policies.forEach(policy => {
            if(policy.id == newPolicy.id) {
                newPolicys.push(newPolicy);
                handled = true;
            } else {
                newPolicys.push(policy);
            }
        });
        if(!handled) {
            newPolicys.push(newPolicy);
        }
        state.policies = newPolicys;
    },
    [SELECT_TEMPLATE_FILEDS]: (state, templatefield) => {
        state.selectedTemplateField = templatefield.data;
    },
    
};

export default {
    state,
    getters,
    actions,
    mutations,
}
