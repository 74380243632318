import {
    INIT_POLICIES, GET_POLICIES, SET_POLICIES_STATE, SET_POLICIES,
    SELECT_POLICY, SEARCH_POLICIES, GET_STATUSES, SET_STATUSES,
    SET_POLICY_SEARCH_TEXT, SET_POLICY_FILTER_GROUP, SET_POLICY_SORT,
    ACCEPT_POLICY, SAVE_POLICY_CHANGESET, GET_ATTACHMENT_LINK, GET_POLICY_FOR_INVITE, DEACTIVATE_POLICY, ACTIVATE_POLICY, PAUSE_POLICY, NON_ELIGIBLE, CHANGE_AGENT, SAVE_POLICY_CHANGESET_AGENT, SET_POLICY_TEMPLATE, GET_TICKLER_HISTORY,
    COUNT_BY_STATUS, BULK_NOTIFY
} from '../actions/policy'
import HTTP from "../../utilities/http";
import FILE from "../../utilities/file";

const state = {
    initialized: false,
    policies: [],
    selectedPolicy: {
        verification: {
            sub_contractor: {}
        },
        fields: {
            current: [],
            historical: []
        },
        status: '',
        status_group: '',
    },
    statuses: [],
    policySearchText: '',
    policyFilterGroup: 'gc',
    policySort:{
        name:'',
        vendor:'',
        expiration:'',
        agent:'',
        status:''
    },
	templateId:''
};

const getters = {
    getPolicies: state => state.policies,
    getPolicy: state => state.selectedPolicy,
    getStatuses: state => state.statuses,
    getPolicySearchText: state => state.policySearchText,
    getPolicyFilterGroup: state => state.policyFilterGroup,
    getPolicySort: state => state.policySort,
	getPolicyTemplate:state=>state.templateId
};

const actions = {

    [ACTIVATE_POLICY]: (context, id) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/activate/' + id, method: 'GET'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

	[GET_TICKLER_HISTORY]: (context, id) => {
	return new Promise((resolve, reject) => {
            HTTP({url: 'policy/get-tickler-hostory/' + id, method: 'GET'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [PAUSE_POLICY]: (context, id) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/pause/' + id, method: 'GET'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [CHANGE_AGENT]: (context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/change-agent/', method: 'POST', data})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [NON_ELIGIBLE]: (context, id) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/non-eligible/' + id, method: 'GET'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [DEACTIVATE_POLICY]: (context, id) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/deactivate/' + id, method: 'GET'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [GET_ATTACHMENT_LINK]: (context, policyId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/attachment-link/' + policyId, method: 'GET'})
                .then(resp => {
                    resolve(resp.data.link);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [SAVE_POLICY_CHANGESET]: (context, data) => {
        return new Promise((resolve, reject) => {
            FILE({url: 'policy/save-changeset', method: 'POST', data})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [SAVE_POLICY_CHANGESET_AGENT]: (context, data) => {
        return new Promise((resolve, reject) => {
            FILE({url: 'policy/save-changeset-agent', method: 'POST', data})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [ACCEPT_POLICY]: ( context, policyId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/accept', data: {policyId}, method: 'POST'})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [GET_POLICY_FOR_INVITE]: (context , invitationCode) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/get-for-invite/' + invitationCode, method: 'GET'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [SELECT_POLICY]: ({commit}, policyId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/detail/' + policyId, method: 'GET'})
                .then(resp => {
                    commit(SELECT_POLICY, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [INIT_POLICIES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            // if (!state.initialized) {
                commit(SET_POLICIES_STATE);
                HTTP({url: 'policy/all', method: 'GET'})
                    .then(resp => {
                        commit(SET_POLICIES, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            // }
        });
    },

    [GET_POLICIES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/all', method: 'GET' })
                .then(resp => {
                    commit(SET_POLICIES, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [GET_STATUSES]: ({commit}) => {
            return new Promise((resolve, reject) => {
                if(!state.statuses.length) {
                    HTTP({url: 'utility/statuses', method: 'GET' })
                        .then(resp => {
                            commit(SET_STATUSES, resp);
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err);
                        });
                }
                resolve();
            });
    },

    [SEARCH_POLICIES]: ({commit}, options) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/all/', data: options, method: 'POST' })
                .then(resp => {
                    commit(SET_POLICIES, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [COUNT_BY_STATUS]: (context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/by-status', data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },

    [BULK_NOTIFY]: (context, data) => {
        return new Promise((resolve) => {
            HTTP({url: 'policy/bulk-notify', data, method: 'POST'})
                .then()
                .catch();
            resolve();
        })
    }
};

const mutations = {
    [SET_POLICIES]: (state, resp) => {
        state.policies = resp.data
    },
    [SET_POLICIES_STATE]: (state) => {
        state.initialized = true;
    },
    [SELECT_POLICY]: (state, policy) => {
        state.selectedPolicy = policy.data;
    },
    [SET_STATUSES]: (state, resp) => {
        state.statuses = resp.data;
    },
    [SET_POLICY_SEARCH_TEXT]: (state, searchText) => {
        state.policySearchText = searchText;
    },
    [SET_POLICY_FILTER_GROUP]: (state, filterGroup) => {
        state.policyFilterGroup = filterGroup;
    },
    [SET_POLICY_SORT]: (state, sort) => {
        state.policySort = sort;
    },
	[SET_POLICY_TEMPLATE]:(state, templateId)=>{
        state.templateId = templateId;
	}
};


export default {
    state,
    getters,
    actions,
    mutations,
}
