import {
    LOAD_TRANSLATION, RELOAD_TRANSLATION,
    SET_TRANSLATION,
    SET_TRANSLATION_STATE
} from "../actions/translate";
import HTTP from "../../utilities/http";

const state = {
    translation: [],
    initialized: false,
};

const getters = {
    translate: state => state.translation,
};

const actions = {
    [LOAD_TRANSLATION]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if (!state.initialized) {
                commit(SET_TRANSLATION_STATE);
                HTTP({url: 'translations', method: 'GET'})
                    .then(resp => {
                        commit(SET_TRANSLATION, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    },
    [RELOAD_TRANSLATION]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'translations', method: 'GET'})
                .then(resp => {
                    commit(SET_TRANSLATION, resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_TRANSLATION_STATE]: (state) => {
        state.initialized = true;
    },
    [SET_TRANSLATION]: (state, translation) => {
        state.translation = translation;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
