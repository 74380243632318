<template>
  <div>
    <div class = 'translated-text' @contextmenu = "handleRightClick($event)" v-html = "this.translations[this.source] || this.source"></div>
    <el-drawer
            size = '30%'
            :visible.sync="translatorAdmin"
            direction="ttb">
            <div class = 'grid grid-cols-3 gap-4 px-5'>
              <div>
                <label>
                  Default Value
                </label>
                <el-input
                        placeholder = "Default Value"
                        v-model = "defaultValue"
                        :disabled = "true"
                        clearable></el-input>
              </div>
              <div>
                <label>
                  Your Value
                </label>
                <el-input
                        placeholder = "Your Value"
                        v-model = "translatedValue"
                        clearable></el-input>
              </div>
              <div>
                <el-button class = 'mt-6'
                        v-on:click="saveForm"
                        type = 'success'>Save</el-button>
              </div>
            </div>
    </el-drawer>
  </div>
</template>

<script>

  import {LOAD_TRANSLATION, RELOAD_TRANSLATION} from "../../store/actions/translate";
  import HTTP from "../../utilities/http";

  export default {
    name: 'Translate',
    props: ['source'],
    data() {
      return {
        translatorAdmin: false,
        defaultValue: '',
        translatedValue: '',
      }
    },
    methods: {
      handleRightClick(e) {
        e.preventDefault();
        this.defaultValue = this.source;
        if(this.translations[this.source] === undefined) {
          this.translatedValue = '';
        } else {
          this.translatedValue = JSON.parse(JSON.stringify(this.translations[this.source]));
        }
        this.translatorAdmin = true;
      },
      saveForm() {
        let data = {
          defaultValue: this.defaultValue,
          translatedValue: this.translatedValue
        };
        HTTP({url: 'translations/save', data, method: 'POST'})
                .then(resp => {
                  this.defaultValue = '';
                  this.translatedValue = '';
                  this.translatorAdmin = false;
                  this.$notify({
                    title: 'Success',
                    message: resp.data.message,
                    type: 'success'
                  });
                  this.$store.dispatch(RELOAD_TRANSLATION).then(() => {}).catch();
                })
                .catch(err => {
                  this.$notify({
                    title: 'Error',
                    message: err.response.data,
                    type: 'error'
                  });
                });
      },
    },
    computed: {
      translations: function() {
        return this.$store.getters.translate;
      }
    },
    mounted() {
      this.$store.dispatch(LOAD_TRANSLATION).then(() => {}).catch();
    }
  }
</script>
<style scoped>
  .translated-text:hover{
    cursor: context-menu;
  }
</style>
