<template>
    <div class="projects mt-5">
        <div class="flex header">
            <div class="w-1/3 count">
                <h1>Users ({{ users.length}})</h1>
            </div>
            <div class="w-1/3 mx-auto">
                <div class="search-bar">
                    <div class="search-area">
                        <span> &#x1F50D;  </span><input v-model = 'filter' type="text"  placeholder='Search Users'>
                    </div>
                </div>
                <el-button
                    class = 'float-left'
                    type = 'success'
                    @click = "search">
                    Search
                </el-button>
            </div>
            <div class="w-1/3">
                <el-button
                        class = 'float-right'
                        type = 'success'
                        @click = "showNewUser = true">
                    New User
                </el-button>
            </div>
        </div>
        <div class = 'flex my-5 coi-card'>
            <div class="coi-card-border">
                <div class="coi-card-header flex">
                    <div class="flex w-full lg:w-5/6">
                        <div class="w-6/12 lg:w-5/12 flex sort sort-name clickable" v-on:click="sortBy('display_name')">
                            <div>Name</div>
                            <div class="sort-none" v-if="sort.display_name === sortDirection[0]">&#8691;</div>
                            <div class="sort-ascending" v-if="sort.display_name === sortDirection[1]">&#8679;</div>
                            <div class="sort-descending" v-if="sort.display_name === sortDirection[2]">&#8681;</div>
                        </div>
                        <div class="w-4/12 flex sort sort-projects clickable" v-on:click="sortBy('email')">
                            <div>Email </div>
                            <div class="sort-none" v-if="sort.email === sortDirection[0]">&#8691;</div>
                            <div class="sort-ascending" v-if="sort.email === sortDirection[1]">&#8679;</div>
                            <div class="sort-descending" v-if="sort.email === sortDirection[2]">&#8681;</div>
                        </div>
                        <!-- <div class="w-2/12 flex sort sort-policies clickable" v-on:click="sortBy('policies')">
                            <div>Policies </div>
                            <div class="sort-none" v-if="sort.policies === sortDirection[0]">&#8691;</div>
                            <div class="sort-ascending" v-if="sort.policies === sortDirection[1]">&#8679;</div>
                            <div class="sort-descending" v-if="sort.policies === sortDirection[2]">&#8681;</div>
                        </div> -->
                        <div class="w-2/12 lg:w-3/12 flex sort sort-status clickable" v-on:click="sortBy('status')">
                            <div>Status</div>
                            <div class="sort-none" v-if="sort.status === sortDirection[0]">&#8691;</div>
                            <div class="sort-ascending" v-if="sort.status === sortDirection[1]">&#8679;</div>
                            <div class="sort-descending" v-if="sort.status === sortDirection[2]">&#8681;</div>
                        </div>
                    </div>
                    <div class="w-1/6 actions"></div>
                </div>
                <div class="w-full">
                    <div class = 'w-full scrollable-box'>
                        <div v-for = "(user, index) in users" v-bind:key='user.id'  class = 'flex-col '>
                            <div class = 'w-full vendor-row flex' :class="{'show' : show[index]}" >
                                <div class="flex w-full" :class="user.status">
                                    <div class="w-full lg:w-5/6 clickable flex"  v-on:click = 'showMore(index)'>
                                        <div class="w-6/12 lg:w-5/12 flex row-item">
                                            <div class = 'fa-icon float-left h-full'><font-awesome-icon icon = 'chess-pawn'></font-awesome-icon></div>
                                            <div>{{user.display_name}}</div>
                                        </div>
                                        <div class="w-4/12 flex-col row-item">
                                            <div>{{user.email}}</div>
                                        </div>
                                        <!-- <div class="w-2/12 flex-col row-item">
                                            <div>{{user.policy_count}} Policies</div>
                                        </div> -->
                                        <div class="w-2/12 lg:w-3/12 flex">
                                            <div class="w-2/3 flex-col hideable">
                                                <div>{{user.is_active == "1" ? 'Active' : 'Inactive'}}</div>
                                            </div>
                                            <div class="w-1/3">
                                                <div v-if="user.status === 'green'" class="status-mark">
                                                    <div class="fa-icon float-left"><font-awesome-icon icon = 'check'></font-awesome-icon></div>
                                                </div>
                                                <!-- <div v-else class="status-mark">
                                                    <div class="fa-icon fa-icon-red float-left"><font-awesome-icon icon = 'exclamation-triangle'></font-awesome-icon></div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-1/6 flex actions">
                                        <!-- <div class=" view" v-on:click="goToUser(user.id)" title="View">
                                            <el-button
                                                class = 'float-left'
                                                type = 'primary'
                                                >
                                                View
                                             </el-button>
                                        </div> -->
                                        <div class="view" v-on:click="removeUser(user.id)" >
                                            <div class="fa-icon float-center" style="color:red;" title="Inactive">
                                                <el-button
                                                class = 'float-left'
                                                type = 'danger'
                                                v-if = "user.is_active == 1"
                                                >
                                                Inactivate
                                                </el-button>
                                            </div>
                                            <div class="fa-icon float-center" title="Active">
                                                <el-button
                                                class = 'float-left'
                                                type = 'success'
                                                v-if = "user.is_active == 0"
                                                >
                                                Activate
                                                </el-button>
                                            </div>
                                            <!-- View -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <transition name = 'slide'>
                                <div v-if = 'show[index]' class = 'coi-card-body'>
                                    <div class="coi-card-body-border flex-col">
                                        <UserData
                                                :dialog = false
                                                :vendor = "user"
                                                v-on:vendor-data-saved = 'handleUserDataSave' />
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
                title = 'New User'
                :visible.sync = 'showNewUser'>
            <UserData
                    :vendor = "{}"
                    :dialog = true
                    v-on:vendor-data-saved = 'handleUserDataSave'
                    v-on:vendor-close-dialog = 'handleUserCloseDialog' />
        </el-dialog>
    </div>
</template>

<script>
    import {
        INIT_USERS,
        SELECT_USER,
        SEARCH_USERS,
        HANDLE_USER_SAVE,
        GET_USERS,
        DELETE_USER
    } from "../../../store/actions/user";
    import UserData from "./components/UserData";
    export default {
        name: 'Users',
        components: {
            UserData
        },
        data() {
            return {
                show: [],
                sortDirection:['','asc','desc'],
                showNewUser: false,
            }
        },
        methods: {
            handleUserCloseDialog() {
                this.showNewUser = false;
                this.$store.dispatch(GET_USERS).then(() => {}).catch();
            },
            handleUserDataSave(vendor) {
                this.$store.dispatch(HANDLE_USER_SAVE, vendor);
            },
            sortBy(col){
                let sort = JSON.parse(JSON.stringify(this.sort));
                let index = this.sortDirection.indexOf(sort[col]);
                index >=2 ? index = 0 : index ++;
                sort[col] = this.sortDirection[index];
                for (const column in sort){
                    if (column !== col && sort.hasOwnProperty(column)){
                        sort[column] = this.sortDirection[0];
                    }
                }
                this.sort = sort;
                this.search();
            },
            search() {
                let sort = this.getSort();
                this.$store.dispatch(SEARCH_USERS, {
                    filter: this.filter,
                    column: sort.column,
                    direction: sort.direction
                }).then().catch();
            },
            getSort() {
                for (let column in this.sort) {
                    if(this.sort[column].length) {
                        return {
                            column: column,
                            direction: this.sort[column]
                        }
                    }
                }
                return {column: '', direction: ''};
            },
            goToUser(vendorId) {
                this.$store.dispatch(SELECT_USER, vendorId).then(() => {
                    this.$router.push({name: 'vendor', params: {id: vendorId}});
                }).catch();
            },
            removeUser(vendorId) {
                this.$store.dispatch(DELETE_USER, vendorId).then(() => {
                    this.$router.go(0)
                }).catch();
            },
            showMore(index) {
                if(this.show[index]) {
                    this.$set(this.show, index, false);
                }
                else {
                    this.$set(this.show, index, true);
                }
            },
            _getUsers() {
                this.$store.dispatch(INIT_USERS).then(() => {}).catch();
            },
        },
        computed: {
            users: function() {
                return this.$store.getters.getUsers;
            },
            filter: {
                get() {
                    return this.$store.getters.getUserFilter;
                },
                set(value) {
                    this.$store.commit('SET_USER_FILTER', value);
                }
            },
            sort: {
                get() {
                    return this.$store.getters.getUserSort;
                },
                set(value) {
                    this.$store.commit('SET_USER_SORT', value);
                }
            }
        },
        mounted() {
            this._getUsers();
        }
    }
</script>

<style scoped>
    .vendor-row{
        width: 100%;
        margin: 5px 0 5px;
        padding: 10px 0 15px;
        border-bottom: 1px solid #e3e8ff;
        border-radius: 8px 8px 0 0;
    }
    .row-item{
        margin-right: 40px;
        overflow: hidden;
    }
    .actions{
    }
    .vendor-row .actions{
        text-align: right;
        border-left: 1px solid #e3e8ff;
        padding-left: 20px;
        margin-left: 25px;
    }
    .coi-card-body-border{
        padding: 0 50px
    }
    .coi-card-body-border .subscript{
        border-top: 1px solid #e3e8ff;
        margin-top: 3px;
        padding-top: 2px;
    }
    .coi-card-body-border .actions{
        margin: 25px 0;
    }
    .details{
        margin: 20px 50px;
    }
    .details .flex-col{
        margin: 15px 0;
        color: #255897;
        font-size: 16px;
        line-height: 20px;
    }
    .view{
        margin-right: 15px;
    }
    .red .vendor-row{
        border-bottom: 1px solid #fb3030;
    }
    .scrollable-box{
        height: 580px;
    }
    .sort-name{
        margin-right: 0;
    }
    .sort-projects{
        margin-right: 30px;
    }
    .sort-policies{
        margin-right: 25px;
    }
    .sort-status{
        margin-right: 10px;
    }
    @media only screen and (max-width: 1024px){
        .vendor-row .actions, .coi-card-header .actions{
            display: none;
        }
        .sort-name{
            margin-right: 20px;
        }
        .sort-projects{
            /*margin-right: 10px;*/
        }
        .sort-policies{
            margin-right: 30px;
        }
        .sort-status{
            margin-right: 0;
        }
    }
</style>
