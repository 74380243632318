import {
    INIT_VENDORS, GET_VENDORS, SELECT_VENDOR, SET_VENDORS, SET_VENDORS_STATE,
    SET_VENDOR_FILTER, SEARCH_VENDORS, SET_VENDOR_SORT, HANDLE_VENDOR_SAVE, DELETE_VENDOR, CHANGE_VENDOR_STATE, GET_VENDORS_ALL, SET_VENDORS_ALL
} from "../actions/vendor";
import HTTP from "../../utilities/http";

const state = {
    initialized: false,
    vendors: [],
    vendorsAll:[],
    selectedVendor: {},
    filter: '',
    vendorSort:{
        name:'',
        projects:'',
        policies:'',
        status:'',
    },
};

const getters = {
    getVendors: state => state.vendors,
    getVendor: state => state.selectedVendor,
    getVendorFilter: state => state.filter,
    getVendorSort: state => state.vendorSort,
    getVendorsAll: state => state.vendorsAll
};

const actions = {

    [HANDLE_VENDOR_SAVE]: ({commit}, vendor) => {
        return new Promise((resolve) => {
            commit(HANDLE_VENDOR_SAVE, vendor);
            resolve();
        });
    },

    [SELECT_VENDOR]: ({commit}, vendorId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'vendor/detail/' + vendorId, method: 'GET'})
                .then(resp => {
                    commit(SELECT_VENDOR, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [DELETE_VENDOR]: ({commit}, vendorId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'vendor/delete/' + vendorId, method: 'GET'})
                .then(resp => {
                    commit(DELETE_VENDOR, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [CHANGE_VENDOR_STATE]: ({commit}, vendorId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'vendor/active-deactive/' + vendorId, method: 'GET'})
                .then(resp => {
                    commit(DELETE_VENDOR, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    [INIT_VENDORS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if (!state.initialized) {
                commit(SET_VENDORS_STATE);
                HTTP({url: 'vendor/all', method: 'GET'})
                    .then(resp => {
                        commit(SET_VENDORS, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    },

    [GET_VENDORS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'vendor/all', method: 'GET' })
                .then(resp => {
                    commit(SET_VENDORS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [GET_VENDORS_ALL]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'vendor/all-vendor', method: 'GET' })
                .then(resp => {
                    commit(SET_VENDORS_ALL, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [SEARCH_VENDORS]: ({commit}, options) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'vendor/all', data: options, method: 'POST' })
                .then(resp => {
                    commit(SET_VENDORS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    [HANDLE_VENDOR_SAVE]: (state, newVendor) => {
        let newVendors = [];
        let handled = false;
        state.vendors.forEach(vendor => {
            if(vendor.id == newVendor.id) {
                newVendors.push(newVendor);
                handled = true;
            } else {
                newVendors.push(vendor);
            }
        });
        if(!handled) {
            newVendors.push(newVendor);
        }
        state.vendors = newVendors;
    },
    [SET_VENDORS]: (state, resp) => {
        state.vendors = resp.data
    },
    [SET_VENDORS_ALL]: (state, resp) => {
        state.vendorsAll = resp.data
    },
    [SET_VENDORS_STATE]: (state) => {
        state.initialized = true;
    },
    [SELECT_VENDOR]: (state, vendor) => {
        state.selectedVendor = vendor.data;
    },
    [SET_VENDOR_FILTER]: (state, filter) => {
        state.filter = filter;
    },
    [SET_VENDOR_SORT]: (state, sort) => {
        state.vendorSort = sort;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
