import {
	GET_VERIFICATION,GET_VERIFICATION_GC, SAVE_NEW_VENDOR, SEND_AGENT_NOTIFICATION, SEND_VERIFICATION, SET_VERIFICATION
} from "../actions/verification";
import HTTP from "../../utilities/http";

const state = {
    verification: {
        project: {
            owner: {}
        },
        policies: []
    },
};

const getters = {
    getVerification: state => state.verification,
};

const actions = {

    [SEND_AGENT_NOTIFICATION]: (context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'policy/notify-agent', method: 'POST', data: data })
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.response.data,
                        type: 'error'
                    });
                    reject(err);
                });
        });
    },

    [GET_VERIFICATION]: ({commit}, code) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'verification/get/' + code, method: 'GET'})
                .then(resp => {
                    commit('SET_VERIFICATION', resp.data.verification);
                    resolve(resp.data.verification);
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.response.data,
                        type: 'error'
                    });
                    reject(err);
                });
        });
    },

	[GET_VERIFICATION_GC]: ({commit}, code) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'verification/get_gc/' + code, method: 'GET'})
                .then(resp => {
                    commit('SET_VERIFICATION', resp.data.verification);
                    resolve(resp.data.verification);
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.response.data,
                        type: 'error'
                    });
                    reject(err);
                });
        });
    },

    [SAVE_NEW_VENDOR]: (context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'verification/new-vendor', method: 'POST', data: data })
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.response.data,
                        type: 'error'
                    });
                    reject(err);
                });
        });
    },

    [SEND_VERIFICATION]: (context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'verification/send', method: 'POST', data: data })
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.response.data,
                        type: 'error'
                    });
                    reject(err);
                });
        });
    },

};

const mutations = {
    [SET_VERIFICATION]: (state, verification) => {
        state.verification = verification;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
