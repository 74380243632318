<template>
    <div>
        <h1>Create New General contractor</h1>
        <router-link to="/agency/dashboard" style="float:right">
            <button class='edit-button' style="height:40px;width:200px;">Back to user list</button>
        </router-link>
        <div class='row'>
            <div class = 'w-1/2'>
                <label>Full Name *</label>
                <CoiInput v-model='gcForm.name'/>
            </div>
            <div class = 'w-1/2'>
                <label>Company Name *</label>
                <CoiInput v-model='gcForm.companyName'/>
            </div>
        </div>
        <div class='row'>
            <div class = 'w-1/2'>
                <label>Street Address *</label>
                <CoiInput v-model='gcForm.address'/>
            </div>
            <div class = 'w-1/2'>
                <label>City *</label>
                <CoiInput v-model='gcForm.city'/>
            </div>
        </div>
        <div class='row'>
            <div class = 'w-1/2'>
                        <label>
                            State *
                        </label><br />
                        <select class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" @change = "changeValue"  v-model = 'gcForm.state'>
                            <option value="">Select One</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VI">Virgin Islands</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
            </div>
            <div class = 'w-1/2'>
                <label>Zip Code *</label>
                <CoiInput v-model='gcForm.zip'/>
            </div>
        </div>
        <div class='row'>
            <div class = 'w-1/2'>
                <label>Password *</label>
                <input type="password" v-model='gcForm.password' class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div class = 'w-1/2'>
                <label>Confirm Password *</label>
                <input type="password" v-model='gcForm.password_confirmation' class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
        </div>
        <div class='row'>
            <div class = 'w-1/2'>
                <label>E-Mail Address *</label>
                <CoiInput v-model='gcForm.email'/>
            </div>
            <div class = 'w-1/2'>
                <label>Industry *</label>
                <select v-model="gcForm.industry" class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option value="">Select One</option>
                    <option value="Commercial Construction">Commercial Construction</option>
                    <option value="Events">Events</option>
                    <option value="Franchise Company">Franchise Company</option>
                    <option value="Property Management">Property Management</option>
                    <option value="Rental Company">Rental Company</option>
                    <option value="Residential Construction">Residential Construction</option>
                    <option value="Restaurant / Bar">Restaurant / Bar</option>
                    <option value="Trucking / Hauling">Trucking / Hauling</option>
                </select>
                <!-- <CoiInput v-model='agency_id'/> -->
            </div>
        </div>
        <div class='row'>
            <div class = 'w-1/2'>
                <label>Phone *</label>
                <CoiInput v-model='gcForm.phone'/>
            </div>
            <div class = 'w-1/2'>
                <label>Project Name *</label>
                <CoiInput v-model='gcForm.projectName'/>
            </div>
        </div>

        <div class='row'>
            <div class = 'w-1/2'>
                <label>Insurance contact email</label>
                <CoiInput v-model='gcForm.insuranceEmail'/>
            </div>
            <div class = 'w-1/2'>
                <label>How did you hear about us?</label>
                <CoiInput v-model='gcForm.referral'/>
            </div>
        </div>

        <div class='row'>
            <button class = 'edit-button' style="width:95%; height:40px;float:right;" v-on:click = "saveData()"> Submit </button>
        </div>

        
    </div>
</template>

<script>
    import CoiInput from '../common/CoiInput.vue';
    import HTTP from "../../utilities/http";
    export default {
        name: 'NewGc',
        data() {
            return {
                possibleDuplicates: [],
                gcForm: {
                    name:'',
                    companyName:'',
                    address:'',
                    city:'',
                    state:'',
                    zip:'',
                    password:'',
                    password_confirmation:'',
                    email:'',
                    industry:'',
                    phone:'',
                    projectName:'',
                    insuranceEmail:'',
                    referral:'',
                    api_token:localStorage.getItem('api-token')
                }
            }
        },
        components: {
            CoiInput,
        },
        methods: {
            changeValue() {
                // console.log(this.vendorData.state);
                // this.vendorData.state = this.vendorData.select
            },

            saveData() {
                // this.$notify({
                //     title: 'Error',
                //     message: this.gcForm,
                //     type: 'error'
                // });
                if (!this.gcForm.name) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Name is required',
                        type: 'warning'
                    });
                    return false;
                } 
                else if (!this.gcForm.companyName) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Company name is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.address) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Address is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.city) {
                    this.$notify({
                        title: 'Warning',
                        message: 'City is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.state) {
                    this.$notify({
                        title: 'Warning',
                        message: 'State is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.zip) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Zip is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.password) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Password is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (this.gcForm.password != this.gcForm.password_confirmation) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Password and Confirm password is must same',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.email) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Email is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.industry) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Industry is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.phone) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Phone is required',
                        type: 'warning'
                    });
                    return false;
                }
                else if (!this.gcForm.projectName) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Project name is required',
                        type: 'warning'
                    });
                    return false;
                }
                HTTP({url: 'onbording/register', method: 'POST', data: this.gcForm})
                    .then(resp => {
                        if (resp.data.error) {
                            this.$notify({
                            title: 'Error',
                            message: 'An error occured',
                            type: 'error'
                           });
                           return false;
                        } else {
                            this.$notify({
                            title: 'Success',
                            message: 'General contractor create successfully!!!',
                            type: 'success',
                            });
                            //this.$router.push({name:'agency-dashboard'});
                            let host = window.location.protocol + "//" + window.location.host;

                            window.location.href=host + "/agency/dashboard";
                        }
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
        },
    }
</script>
<style scoped>
    select{
        width: 90%;
    }
    .row {
        width:100%;
        display: flex;
    }
    input{
        width: 90%;
    }
</style>
