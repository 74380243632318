import {
    USER_INVITE,
    SAVE_USER,
    GET_USERS,
    SET_USERS,
    SET_USERS_STATE,
    SELECT_USER,
    SET_USER_FILTER,
    SET_USER_SORT,
    HANDLE_USER_SAVE,
    INIT_USERS,
    DELETE_USER,
    SEARCH_USERS,
    ALL_GC,
    SEARCH_GC,
    INIT_GC,
    USER,
    DASH_COUNT
} from '../actions/user'
import HTTP from "../../utilities/http";
const state = {
    initialized: false,
    users: [],
    selectedUser: {},
    filter: '',
    userSort:{
        name:'',
        projects:'',
        policies:'',
        status:'',
    },
};

const getters = {
    getUsers: state => state.users,
    getUser: state => state.selectedUser,
    getUserFilter: state => state.filter,
    getUserSort: state => state.userSort,
};

const actions = {

    [USER_INVITE]: ( context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/inviteUser', data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [SAVE_USER]: ( context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/saveUser', data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [INIT_USERS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if (!state.initialized) {
                commit(SET_USERS_STATE);
                HTTP({url: 'user/users', method: 'GET'})
                    .then(resp => {
                        commit(SET_USERS, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    },
    [INIT_GC]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if (!state.initialized) {
                commit(SET_USERS_STATE);
                HTTP({url: 'user/gc', method: 'GET'})
                    .then(resp => {
                        commit(SET_USERS, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    },
    [DELETE_USER]: ({commit}, userId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/delete/' + userId, method: 'GET'})
                .then(resp => {
                    commit(DELETE_USER, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [GET_USERS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/users', method: 'GET' })
                .then(resp => {
                    commit(SET_USERS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [ALL_GC]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/gc', method: 'GET' })
                .then(resp => {
                    commit(SET_USERS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [SEARCH_GC]: ({commit}, options) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/gc', data: options, method: 'POST' })
                .then(resp => {
                    commit(SET_USERS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [SEARCH_USERS]: ({commit}, options) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/users', data: options, method: 'POST' })
                .then(resp => {
                    commit(SET_USERS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [USER]: () => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/agency', method: 'GET' })
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    [DASH_COUNT]: (context, data) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'utility/dashboard-counts-by-id/'+data.id, method: 'GET' })
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
    
};

const mutations = {
    [HANDLE_USER_SAVE]: (state, newVendor) => {
        let newVendors = [];
        let handled = false;
        state.vendors.forEach(vendor => {
            if(vendor.id == newVendor.id) {
                newVendors.push(newVendor);
                handled = true;
            } else {
                newVendors.push(vendor);
            }
        });
        if(!handled) {
            newVendors.push(newVendor);
        }
        state.vendors = newVendors;
    },
    [SET_USERS]: (state, resp) => {
        state.users = resp.data
    },
    [SET_USERS_STATE]: (state) => {
        state.initialized = true;
    },
    [SELECT_USER]: (state, vendor) => {
        state.selectedUser = vendor.data;
    },
    [SET_USER_FILTER]: (state, filter) => {
        state.filter = filter;
    },
    [SET_USER_SORT]: (state, sort) => {
        state.userSort = sort;
    }
};


export default {
    state,
    actions,
    getters,
    mutations
}
