import { render, staticRenderFns } from "./UserInvite.vue?vue&type=template&id=2aad681b&scoped=true&"
import script from "./UserInvite.vue?vue&type=script&lang=js&"
export * from "./UserInvite.vue?vue&type=script&lang=js&"
import style0 from "./UserInvite.vue?vue&type=style&index=0&id=2aad681b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aad681b",
  null
  
)

export default component.exports