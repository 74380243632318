<template>
    <div>
        <div class="bg-white shadow overflow-hidden  sm:rounded-lg">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Insurance Verification Request
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"></p>
            </div>
            <div class="px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Certificate Holder Name
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ this.verification.project.owner.company_name}}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Certificate Holder Address
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ this.verification.project.owner.company_address}}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Certificate Holder Email
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ this.verification.project.owner.email}}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Insured Company
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ this.verification.name}}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Insured Contact Name
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ this.verification.sub_name}}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Insured Contact Email
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ this.verification.sub_email}}
                        </dd>
                    </div>
                    <div class="sm:col-span-2">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Policy Verifications Requested
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            <ul class="border border-gray-200 rounded-md">
                                <li v-for = "policy in policies" :key = "policy.id" class="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                                    <div class="w-0 flex-1 flex items-center">
                                        {{ policy.name }}
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        <div v-if = "status == 'intro'" class="bg-white shadow overflow-hidden  sm:rounded-lg mt-8">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <dl class="grid grid-cols-1 col-gap-4 row-gap-3 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <dt class="text-sm text-center leading-5 font-medium text-gray-500">
                            Are you the insured?
                        </dt>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm text-center leading-5 font-medium text-gray-500">
                            Are you the agent?
                        </dt>
                    </div>
                    <div class="sm:col-span-1 text-center">
                        <span class="inline-flex rounded-md shadow-sm">
                            <button v-on:click = "status = 'insured'" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                Yes I'm The Insured
                            </button>
                        </span>
                    </div>
                    <div class="sm:col-span-1 text-center">
                        <button v-on:click = "status = 'agent'" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150">
                            Yes I'm The Agent
                        </button>
                    </div>
                </dl>
            </div>
        </div>

        <div v-if = "status == 'insured'" class="bg-white shadow overflow-hidden  sm:rounded-lg mt-8">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Send To Your Agent
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    Send your agent a request to complete the verification for you
                </p>
            </div>
            <div class="px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
                    <div class="sm:col-span-2">
                        <dt class="text-sm leading-5 font-medium text-gray-500 flex">
                            <div class = 'w-4/6'>
                                Policy Verifications Requested
                            </div>
                            <div class = 'w-1/6'>
                                Agent Email
                            </div>
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            <ul class="border border-gray-200 rounded-md">
                                <li v-for = "policy in policies" :key = "policy.id" :class="[policy.agent_email && policiesSent.includes(policy.id) && 'bg-gray-200']" class = "border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                                    <div class="w-4/6 ">
                                        {{ policy.name }}
                                    </div>
                                    <div class="w-1/6">
                                        <coi-input v-model = 'policy.agent_email' placeholder = "Agent Email" />
                                    </div>
                                    <div class="w-1/6">
                                        <button v-on:click = "sendEmail(policy)"  type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                            <span v-if = "policy.agent_email && policiesSent.includes(policy.id)">
                                                Re-Send Email
                                            </span>
                                            <span v-else>
                                                Send Email
                                            </span>
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>

        <div v-if = "status == 'agent'" class="bg-white shadow overflow-hidden  sm:rounded-lg mt-8">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Select Policies
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    Select the policies that you are prepared to verify
                </p>
            </div>
            <div class="px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
                    <div class="sm:col-span-2">
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            <ul class="border border-gray-200 rounded-md">
                                <li  class="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                                    <el-checkbox-group v-model = "selectedPolicies">
                                        <el-checkbox v-for = "policy in policies" :key = "policy.id" :label="policy.id">
                                            {{ policy.name}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </li>
                                <li  class="border-t border-gray-200 pl-3 pr-4 py-3 flex text-sm leading-5">
                                    <div class="w-2/6">
                                        <coi-input v-model = 'agentEmail' placeholder = "Your Email Address" />
                                    </div>
                                    <div class = 'w-1/6'>
                                        <button v-on:click = "sendEmailAgent()"  type="button" class="mt-1 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                                            Send Email
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>

    </div>
</template>

<script>

    import {GET_VERIFICATION, SEND_AGENT_NOTIFICATION} from "../../store/actions/verification";
    import CoiInput from "../common/CoiInput";
    const clone = require('lodash/cloneDeep');

    export default {
        name: 'VerificationInvite',
        components: {CoiInput},
        data() {
            return {
                status: 'intro',
                policies: [],
                agentEmail: null,
                selectedPolicies: [],
                policiesSent: [],
            }
        },
        methods: {
            sendEmailAgent() {
                if(!this.selectedPolicies.length) {
                    this.$notify({
                        title: 'Select Policy',
                        message: "Please select at least one policy that you are prepared to verify",
                        type: 'warning',
                        duration: 2500,
                        onClose: (() => {})
                    });
                    return;
                }
                this.$store.dispatch(SEND_AGENT_NOTIFICATION, {
                    policyIds: this.selectedPolicies, agent_email: this.agentEmail
                }).then(() => {
                    this.policiesSent.push(this.selectedPolicies);
                    this.$notify({
                        title: 'Notification Sent',
                        message: "An email with additional details has been sent to your email address",
                        type: 'success',
                        duration: 2500,
                        onClose: (() => {})
                    });
                }).catch();
            },
            sendEmail(policy) {
                this.$store.dispatch(SEND_AGENT_NOTIFICATION, {
                    policyIds: [policy.id], agent_email: policy.agent_email
                }).then(() => {
                    this.policiesSent.push(policy.id);
                    this.$notify({
                        title: 'Notification Sent',
                        message: "An email has been sent to your agent",
                        type: 'success',
                        duration: 2500,
                        onClose: (() => {})
                    });
                }).catch();
            }
        },
        computed: {
            verification: function() {
                return this.$store.getters.getVerification;
            },
        },
        mounted() {
            this.$store.dispatch(GET_VERIFICATION, this.$route.params.inviteCode).then((verification) => {
                this.policies = [];
                verification.policies.forEach((policy) => {
                    if(policy.is_active) {
                        this.policies.push(clone(policy));
                    }
                });
            }).catch();
        }
    }
</script>

<style scoped>

</style>
