<template>
    <div>
        <div class="header flex">
            <div class="w-1/6">
                <div v-on:click = 'goBack' class="back-button flex">
                    <div class="arrow"><font-awesome-icon icon = 'arrow-left'></font-awesome-icon></div>
                    <div style = 'padding-top: 9px; padding-left: 5px;'>Back</div>
                </div>
            </div>
        </div>
        <div class="coi-card mt-5" >
            <div class="coi-card-border">
                <div class="flex-col coi-card-header">
                    <div class="flex ">
                        <div class="w-10/12 flex row-item">
                            <div class="fa-icon mr-2">
                               <h1>Upload vendors</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-col coi-card-row">
                    <div class="flex">
                        <div class="col-md-12">
                           <div class="row">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <h2>Please <b><u><a href="/files/CSVDemo.csv" download>download</a></u></b> Sample file for desired file format and structure in order to validate data successfully.</h2>
                                        <br>
                                        <label class="form-control-label"  for="input-file-import">Upload CSV File</label>
                                        <form enctype="multipart/form-data">
                                            <div>
                                            <input type="file" @change="handleFileUpload( $event )" name='file' accept=".csv"/>
                                            </div>
                                            <br>
                                            <div class="diverror" v-if="errors.length > 0" v-show="!seen">
                                                <div>
                                                    <ul v-if="errors.length > 0">
                                                        <li>{{errors}}</li>
                                                    </ul>
                                                </div>
                                                <br>
                                            </div>
                                            <div v-show="!validationseen"> 
                                                <div class="successmessage" v-show="!seen" v-if="totalsuccess > 0">
                                                    VALIDATION PASSED. Vendor imported successfully.
                                                </div>
                                                <div v-else class="failmessage">
                                                    VALIDATION FAILED. Please <b><u><a @click="getfile" download class="pointer">download</a></u></b> the validation output.
                                                </div>
                                            </div>
                                            <br>
                                            <textarea v-show="!seen" v-model="text" class="textarealog"></textarea>
                                            <br>
                                            <p v-show="!totalseen">Number of rows uploaded = {{ totalsuccess }}</p>
                                            <br>
                                            <div v-on:click="onFileValidate()" >
                                                <div class="fa-icon float-left" title="Validate">
                                                    <el-button
                                                    class = 'float-left'
                                                    type = 'info'
                                                    >
                                                    Upload & Validate
                                                    </el-button>
                                                </div>
                                            </div> 
                                            <div v-show="btnseen" v-on:click="onProcess()" >
                                                <div class="fa-icon float-left" title="Process">
                                                    <el-button
                                                    class = 'float-left'
                                                    type = 'success'
                                                    >
                                                    Process
                                                    </el-button>
                                                </div>
                                            </div> 
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>      
            </div>
        </div>
    </div>
</template>
<script>
    import HTTP from "../../../utilities/http";
    import Validator from "../../common/Validator";
    export default {
        data() {
            return {
                file: '',
                text: '',
                allNames: [],
                errors: [],
                totalrecords: 0,
                readerresult : [],
                fieldsarray: ['Company Name','Address','City','State','Zip','Primary Contact','Primary Email','Primary Phone','EIN / Tax ID','Insurance Email','Vendor Id'],
                seen: true,
                totalseen: true,
                validationseen: true,
                btnseen:false,
                missingfield: 0,
                totalsuccess:0,
                filename :'',
            }
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            handleFileUpload( event ){
                this.validationseen = true;
                this.btnseen=false;
                this.errors = [];
                this.file = event.target.files[0];
                if (!this.file.type == "text/csv"){
                    this.errors = 'Invalid file format';
                    this.$notify({
                        title: 'Invalid file format',
                        message: 'Please upload a valid CSV file',
                        type: 'warning',
                        duration: 2500 
                    });
                    return;
                }
                if (this.file.type == ""){
                    this.errors = 'Invalid file format';
                    this.$notify({
                        title: 'Invalid file format',
                        message: 'Please upload a valid CSV file',
                        type: 'warning',
                        duration: 2500
                    });                                
                    return;
                }
                if(this.file.size >=2000000){
                    this.errors = 'Uploaded file size limit is 2mb';
                    this.$notify({
                        title: 'File size not acceptable',
                        message: 'Please upload file size under 2mb',
                        type: 'warning',
                        duration: 2500
                    });
                    return;
                
                }
            },
            onFileValidate(){
                if(this.file == '')
                {
                    this.errors = "Please select and upload valid CSV file";
                    this.$notify({
                            title: 'No file uploaded',
                            message: 'Please select and upload valid CSV file',
                            type: 'error'
                        });
                    this.btnseen=false;
                    return;
                }
                else
                {
                    if (this.file.type == "text/csv")
                    {
                        this.errors = [];
                        let reader = new FileReader();
                        reader.readAsText(this.file, "UTF-8");
                        reader.onload =  evt => {
                            this.seen =false;
                            this.readerresult = this.$papa.parse(reader.result);
                            for (var i = 0; i < this.readerresult.data.length; i++) {
                                if(this.readerresult.data[0] == "" || this.readerresult.data[1] == "")
                                {
                                    this.errors = "File is empty. No records found";
                                    this.$notify({
                                        title: "File is empty",
                                        message: 'No header columns or records found',
                                        type: 'warning',
                                        duration: 2500
                                    });
                                    this.btnseen=false;
                                    return;
                                    
                                }
                                if (this.fieldsarray.length != this.readerresult.data[0].length)
                                {
                                    this.errors = "Invalid file format";
                                    this.$notify({
                                        title: "Invalid file format",
                                        message: 'Header columns not in proper format',
                                        type: 'warning',
                                        duration: 2500
                                    });
                                    this.btnseen=false;
                                    return;
                                }
                                else{
                                    for (let j = 0;j < this.fieldsarray.length;j++) 
                                    {
                                        if (this.fieldsarray[j] != this.readerresult.data[0][j]) 
                                        {
                                            this.errors = "Invalid file format";
                                            this.$notify({
                                                title: "Invalid file format",
                                                message: 'Header columns not in proper format',
                                                type: 'warning',
                                                duration: 2500
                                            });
                                            this.btnseen=false;
                                            return;
                                        }
                                    }
                                        
                                } 
                                    
                            }
                            this.text = evt.target.result;
                        }
                        reader.onerror = evt => {
                            // console.error(evt);
                            this.errors = evt;
                        }
                    }
                    
                }
                this.btnseen=true;
            },
            onProcess() {
                if(!Validator([
                    {field: this.file, file: 'file'},
                ])) {
                    return;
                }
                let formData = new FormData();
                formData.append('file', this.file);
                HTTP({url: 'vendor/upload/', method: 'POST', data: formData})
                    .then(resp => {
                        this.filename = resp.data.uploadvendor.original.filename;
                        this.seen =false;
                        this.validationseen = false;

                        if(resp.data.uploadvendor.original.totalsuccess > 0)
                        {
                            this.$notify({
                                title: 'Success',
                                // message: resp.data.message,
                                type: 'success',
                                duration: 2500,
                            });
                        }
                        else{
                            this.$notify({
                                title: 'Failed',
                                // message: resp.data.message,
                                type: 'error',
                                duration: 2500,
                            });
                        }
                        this.totalseen =false;
                        this.totalrecords = resp.data.uploadvendor.original.totalrecords;
                        this.missingfield = resp.data.uploadvendor.original.arrayerror.missingfield;
                        this.totalsuccess = resp.data.uploadvendor.original.totalsuccess;
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            getfile(){
    
                HTTP({url: 'vendor/getfile/' + this.filename , method: 'GET',responseType: 'arraybuffer'})
                    .then(resp => {
                        let blob = new Blob([resp.data], { type: 'text/csv' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = this.filename
                        link.click()
                        
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
                
            },

        },
    }
</script>

<style scoped>
    .coi-card-header {
        padding: 25px 0 0 0px;
        border-bottom: 2px solid #e3e8ff;
    }
    .textarealog{
        width: 100%;
        height: 300px;
        margin: 5px 0 5px;
        padding: 10px 0 15px;
        border: 2px solid #e3e8ff;
        border-radius: 8px 8px 1 1;
        overflow-y: auto;
    }
    .diverror{
        color: red;
        border-color: red !important;
        border-style: solid !important;
        padding: 5px;
        height: 100px;
        overflow-x: auto;
        overflow-y: auto;
        border: 1px solid;
        padding-left: 25px;
    }
    .successmessage{
        color: blue;
        padding: 5px;
    }
    .failmessage{
        color: red;
        padding: 5px;
    }
    .errorheader{
        color: black;
        font-size: 15px;
    }
    .pointer {
        cursor: pointer;
    }
</style>

