<template>
    <div>
        <el-button
                size = "mini"
                type = "info"
                v-on:click = "openDrawer = true">
            <translate source = 'Reject'/>
        </el-button>
        <el-drawer
                title = "Policy Reject"
                size = '30%'
                :visible.sync="openDrawer"
                direction="ttb">
            <div class = 'flex w-full is-justify-center px-5'>
                <el-input
                        type = 'textarea'
                        :rows = '3'
                        placeholder = "Reason"
                        v-model = 'noteText'></el-input>
            </div>
            <div class = 'flex w-full is-justify-center px-5'>
                <el-button class = 'mt-6'
                           v-on:click="saveNote"
                           type = 'success'>Save Reject Reason</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
  import HTTP from "../../../../utilities/http";
  export default {
    name: 'PolicyNote',
    props: ['id'],
    data() {
      return {
        openDrawer: false,
        noteText: ''
      }
    },
    methods: {

      saveNote() {
        let data = {
          policyId: this.id,
          noteText: this.noteText
        };
        HTTP({url: 'policy/save-note/', method: 'POST', data: data})
                .then(resp => {
                  this.$notify({
                    title: 'Success',
                    message: resp.data.message,
                    type: 'success',
                    duration: 2500,
                  });
                  this.openDrawer = false;
                  this.noteText = '';
                  this.$emit('note-saved');
                })
                .catch(err => {
                  this.$notify({
                    title: 'Error',
                    message: err.response.data,
                    type: 'error'
                  });
                });
      },
    },
    computed: {

    },
    mounted() {

    }
  }
</script>
<style scoped>

</style>
