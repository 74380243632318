export const INIT_VENDORS = 'INIT_VENDORS';
export const SET_VENDORS_STATE = 'SET_VENDORS_STATE';
export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDORS_ALL = 'GET_VENDORS_ALL';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_VENDORS_ALL = 'SET_VENDORS_ALL';
export const SELECT_VENDOR = 'SELECT_VENDOR';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const SEARCH_VENDORS = 'SEARCH_VENDORS';
export const SET_VENDOR_FILTER = 'SET_VENDOR_FILTER';
export const SET_VENDOR_SORT = 'SET_VENDOR_SORT';
export const HANDLE_VENDOR_SAVE = 'HANDLE_VENDOR_SAVE';
export const CHANGE_VENDOR_STATE = 'CHANGE_VENDOR_STATE';
