<template>
    <div>
        <div v-if='edit' class = 'flex'>
            <div class = 'w-10/12'>
                <div class="flex mt-5">
                    <div class="w-full">
                        <label class = 'mr-2'>
                            Select Field :
                        </label>
                    </div>
                </div>
                <div class="flex mt-5">
                    <div class="w-full">
                        <select v-model="selectedOption" @change="onChange($event)" placeholder = "Select One" class="formctrl selecttemplate">
                                <option value=""> Please Select One</option>
                                <option value="Custom"> Add Custom</option>
                                <option v-for="item in allFields" v-bind:key="item.id" :value='item.id'>
                                    {{item.name}}
                                </option>
                        </select>  
                    </div>
                </div>
            </div>
        </div>
        <transition name = 'slide'>
            <div class = 'flex' v-show="!isShow">
                <div class = 'w-10/12'>
                   <input type="hidden" name="fieldid" class="form-control" v-model="policyFieldData.id">

                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Name:
                            </label>
                            <coi-input v-model = 'policyFieldData.name' placeholder="Name"/>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Type:
                            </label><br>
                            <select name="type" v-model="policyFieldData.type" placeholder = "Please Select One" class = "form-control selecttype" id="FieldType">                            	 
                                <option value=""> Please Select One</option>
                                <option value = "none">none</option>
                                <option value = "dropdown">dropdown</option>
                                <option value = "radio">radio</option>
                                <option value = "text">text</option>
                                <option value = "upload">upload</option>
                                <option value = "date">date</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Required:
                            </label>
                            <input type = "checkbox" v-model="policyFieldData.is_required" name="is_required" value="1" id="isRequired"/>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Answer:
                            </label><br>
                            <coi-input type = "text" v-model = 'policyFieldData.data' /><p>Comma separated values</p>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Required Answer:
                            </label>
                            <coi-input v-model = 'policyFieldData.required_answer' placeholder="Required Answer"/>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Agent Directions:
                            </label>
                            <coi-input v-model = 'policyFieldData.agent_directions' placeholder="Agent Directions"/>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Help Text:
                            </label><br>
                            <textarea rows="3" cols="54" v-model="policyFieldData.help_text" name="help_text" placeholder = "Help Text" class = "form-control selecttype" id="Help-Text"></textarea>

                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Weight:
                            </label>
                            <coi-input v-model = 'policyFieldData.weight' placeholder="Weight" />
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div class="w-full">
                            <label class = 'mr-2'>
                                Question Type:
                            </label>
                            <select v-model="policyFieldData.question_type_id" placeholder = "Please Select One" class="formctrl selecttemplate">
                                <option value="0"> Please-Select-Question-Type </option>
                                <option v-for="item in allQuestions" v-bind:key="item.id" :value='item.id'>
                                    {{item.description}}
                                </option>
                            </select>  
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class = 'w-2/12'>
            <div class = 'flex mt-5'>
                <div class=" w-full mb-3 h-10">
                    <el-button v-on:click = 'saveTemplateFieldData' type="success" icon="el-icon-check" circle class = 'float-right mr-3'></el-button>
                    <el-button v-on:click = 'cancel' type="danger" icon="el-icon-close" circle class = 'float-right'></el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HTTP from "../../../../utilities/http";
    import CoiInput from "../../../common/CoiInput";
    import Validator from "../../../common/Validator";
    const clone = require('lodash/cloneDeep');
    export default {
        name: 'SettingsPolicyData',
        props: ['field', 'dialog'],
        components: {
            CoiInput,
        },
        data() {
            return {
                possibleDuplicates: [],
                allFields : [],
                allQuestions: [],
                selectedOption:'',
                isShow:false,
                name:'',
                edit: clone(this.dialog),
                policyFieldData: clone(this.field),
                index :clone(this.index),
                policy_template_id : '',
                existingfield: '',
            }
        },
        methods: {
            cancel() {
                if(this.dialog) {
                    this.$emit('policyfield-close-dialog');
                }
                this.edit = false
            },
            getallFields() {
                HTTP({url: 'settings-policy/policy-templates-field', method: 'GET'})
                    .then(resp => {
                        this.allFields = resp.data;
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            getallQuestions() {
                HTTP({url: 'settings-policy/question_types', method: 'GET'})
                    .then(resp => {
                        this.allQuestions = resp.data;
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            onChange(e) {
                this.existingfield = this.selectedOption;
                //console.log(this.existingfield,'this.existingfield')
                if(e.target.value != 'Custom')
                {
                    this.isShow = true;
                }
                else{
                    this.isShow = false;
                }
            },
            saveTemplateFieldData() {
            if(this.isShow != true)
                {
                    if(!Validator([
                    {field: this.policyFieldData.name, name: 'Name'}
                    ])) {
                        return;
                    }
                }
                if(typeof this.$route.params.id != 'undefined')
                {
                    this.policy_template_id= this.$route.params.id;
                    //console.log(this.policy_template_id)
                }
                HTTP({url: 'settings-policy/policy-templates-add-field/', method: 'POST', data:{ policyFieldData: this.policyFieldData,
                policy_template_id: this.policy_template_id,
                existingfield: this.existingfield} })
                    .then(resp => {
                        this.$notify({
                            title: 'Success',
                            message: resp.data.message,
                            type: 'success',
                            duration: 2500,
                        });
                        this.$emit('policy-data-saved', resp.data.template);
                        if(this.dialog) {
                            this.$emit('policy-close-dialog');
                        }
                        // this.edit = false;
                        this.$router.go(0);
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
        },
        mounted() {
            this.getallFields();
            this.getallQuestions();
        }
    }
</script>
<style scoped>
    select{
        width: 90%;
    }
    .selecttemplate,.selecttype{
      height: 40px;
      border: 1px solid;
      background-color: white;
    }
    .textbox{
        height: 30px;
        width: 450px;
        border: 1px solid;
    }
</style>
