<template>
    <div>
      <div v-if='!edit' class='flex'>
        <div class='w-10/12'>
          <!-- Display Vendor Information -->
          <div class="flex mt-5">
            <div class="w-1/2">
              <label class='mr-2'>Name:</label>
              {{ vendor.name }}
            </div>
            <div class="w-1/2">
              <label class='mr-2'>Address:</label>
              {{ vendor.address }}
            </div>
          </div>
          <div class='flex mt-5'>
            <div class="w-1/2">
              <label class='mr-2'>Country:</label>
              {{ vendor.country }}
            </div>
            <div class="w-1/2">
              <label class='mr-2'>City:</label>
              {{ vendor.city }}
            </div>
          </div>
          <div class='flex mt-5'>
            <div class="w-1/2">
              <label class='mr-2'>State:</label>
              {{ vendor.state }}
            </div>
            <div class="w-1/2">
              <label class='mr-2'>Zip:</label>
              {{ vendor.zip }}
            </div>
          </div>
          <div class='flex mt-5'>
            <div class="w-1/2">
              <label class='mr-2'>Primary Contact:</label>
              {{ vendor.primary_contact }}
            </div>
            <div class="w-1/2">
              <label class='mr-2'>Primary Email:</label>
              {{ vendor.email }}
            </div>
          </div>
          <div class='flex my-5'>
            <div class="w-1/2">
              <label class='mr-2'>Primary Phone:</label>
              {{ vendor.phone }}
            </div>
            <div class="w-1/2">
              <label class='mr-2'>EIN:</label>
              {{ vendor.ein }}
            </div>
          </div>
          <div class='flex my-5'>
            <div class="w-1/2">
              <label class='mr-2'>Insurance Emails:</label>
              {{ vendor.insurance_email }}
            </div>
            <div class="w-1/2">
              <label class='mr-2'>Vendor Id:</label>
              {{ vendor.vendor_id }}
            </div>
          </div>
        </div>
        <div class='w-2/12'>
          <div class='flex mt-5'>
            <div class="w-full mb-3 h-10">
              <el-button @click='edit = true' type="primary" icon="el-icon-edit" circle class='float-right'></el-button>
            </div>
          </div>
        </div>
      </div>
  
      <div v-else class='flex'>
        <div class='w-10/12'>
          <!-- Edit Vendor Information -->
          <div class="flex mt-5">
            <div class="w-full">
              <label>Name</label>
              <coi-input @keyup="checkVendorName" v-model='vendorData.name' />
            </div>
          </div>
  
          <!-- Possible Duplicates -->
          <div v-if="possibleDuplicates.length" class='flex my-5 px-3' style='background-color: rgba(187,82,82,0.22)'>
            <div class='w-full'>
              <div class='flex my-5'>
                <div class='w-full font-bold'>Possible Matches</div>
              </div>
              <div v-for="vendor in possibleDuplicates" :key='vendor.id' class='flex my-5'>
                <div class='w-10/12'>
                  {{ vendor.name }} {{ vendor.city }} {{ vendor.state }}<br />
                  {{ vendor.primary_contact }}
                </div>
              </div>
            </div>
          </div>
  
          <!-- Country Selection -->
          <div class='flex mt-5'>
            <div class="w-1/2">
              <label>Country</label><br />
              <select
                class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                @change="changeCountryValue"
                v-model='vendorData.country'>
                <option v-for="country in countries" :key="country.code" :value="country.name">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="w-1/2">
              <label>City</label>
              <CoiInput v-model='vendorData.city' />
            </div>
          </div>
  
          <!-- State and Address Information -->
          <div class='flex mt-5'>
            <div class="w-1/2">
              <label>State / Province</label>
              <select
                class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                @change="changeStateValue"
                v-model='vendorData.state'>
                <option v-for="state in states" :key="state.code" :value="state.code">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="w-1/2">
              <label>Zip Code / Postal Code</label>
              <CoiInput v-model='vendorData.zip' />
            </div>
          </div>
  
          <!-- Other Information Fields -->
          <div class='flex mt-5'>
            <div class="w-1/2">
              <label>Primary Contact</label>
              <CoiInput v-model='vendorData.primary_contact' />
            </div>
            <div class="w-1/2">
              <label>Primary Email</label>
              <CoiInput v-model='vendorData.email' />
            </div>
          </div>
          <div class='flex my-5'>
            <div class="w-1/2">
              <label>Primary Phone</label>
              <CoiInput v-model='vendorData.phone' />
            </div>
            <div class="w-1/2">
              <label>EIN</label>
              <CoiInput v-model='vendorData.ein' />
            </div>
          </div>
          <div class='flex my-5'>
            <div class="w-1/2">
              <label>Insurance Emails</label>
              <CoiInput v-model='vendorData.insurance_email' />
            </div>
            <div class="w-1/2">
              <label>Vendor Id</label>
              <CoiInput v-model='vendorData.vendor_id' />
            </div>
          </div>
        </div>
        <div class='w-2/12'>
          <div class='flex mt-5'>
            <div class="w-full mb-3 h-10">
              <el-button @click='cancel' type="danger" icon="el-icon-close" circle class='float-right'></el-button>
              <el-button @click='saveData' type="success" icon="el-icon-check" circle class='float-right mr-3'></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import HTTP from "../../../../utilities/http";
  import CoiInput from "../../../common/CoiInput";
  import Validator from "../../../common/Validator";
  import countries from "../../../../assets/countries.json";
  import countryStates from "../../../../assets/countries_states.json";
  const clone = require('lodash/cloneDeep');
  
  export default {
    name: 'VendorData',
    props: ['vendor', 'dialog'],
    components: {
      CoiInput,
    },
    data() {
      return {
        edit: clone(this.dialog),
        vendorData: clone(this.vendor),
        possibleDuplicates: [],
        countries: countries,
        countryState: countryStates,
        states: [],
      };
    },
    mounted() {
      this.initializeCountryAndState();
    },
    methods: {
      initializeCountryAndState() {
        // Initialize country and state if pre-selected
        if (this.vendorData.country) {
          const selectedCountry = this.countryState.find((item) => item.country === this.vendorData.country);
          if (selectedCountry) {
            this.states = selectedCountry.states;
            // Auto-select the state if it matches
            const matchedState = selectedCountry.states.find(state => state.code === this.vendorData.state);
            if (matchedState) {
              this.vendorData.state = matchedState.code;
            }
          }
        }
      },
      checkVendorName() {
        const searchTerm = this.vendorData.name;
        if (searchTerm.length > 1) {
          const vendorId = this.vendorData.id ? this.vendorData.id : null;
          HTTP({ url: 'vendor/search/', method: 'POST', data: { searchTerm, vendorId } })
            .then(resp => {
              this.possibleDuplicates = resp.data;
            })
            .catch(err => {
              this.$notify({
                title: 'Error',
                message: err.response.data,
                type: 'error',
              });
            });
        }
      },
      changeCountryValue() {
        const selectedCountry = this.countryState.find((item) => item.country === this.vendorData.country);
        this.states = selectedCountry ? selectedCountry.states : [];
      },
      changeStateValue() {
        // Additional logic can be added here if needed
      },
      cancel() {
        if (this.dialog) {
          this.$emit('vendor-close-dialog');
        }
        this.edit = false;
      },
      saveData() {
        if (!Validator([
          { field: this.vendorData.name, name: 'Name' },
          { field: this.vendorData.primary_contact, name: 'Primary Contact' },
          { field: this.vendorData.email, name: 'Primary Email' },
        ])) {
          return;
        }
  
        HTTP({ url: 'vendor/save/', method: 'POST', data: this.vendorData })
          .then(resp => {
            this.$notify({
              title: 'Success',
              message: resp.data.message,
              type: 'success',
              duration: 2500,
            });
            this.$emit('vendor-data-saved', resp.data.vendor);
            if (this.dialog) {
              this.$emit('vendor-close-dialog');
            }
            this.edit = false;
          })
          .catch(err => {
            this.$notify({
              title: 'Error',
              message: err.response.data,
              type: 'error',
            });
          });
      },
    }
  };
  </script>
  
  <style scoped>
  select {
    width: 90%;
  }
  </style>
  