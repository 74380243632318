<template>
    <div>

        <div class="bg-white shadow overflow-hidden  sm:rounded-lg">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Agent Dashboard
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"></p>
            </div>
            <div class="px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-bold font-medium text-gray-500">
                            Agent
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ agent.user.display_name}}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm leading-5 font-bold font-medium text-gray-500">
                            Agent Email
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            {{ agent.user.email }}
                        </dd>
                    </div>

                    <div class="sm:col-span-2">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Policy Verifications
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-gray-900">
                            <ul class="border border-gray-200 rounded-md">
                                <div class="border-t border-gray-200 pl-3 pr-4 font-bold py-3 flex items-center justify-between text-sm leading-5">
                                    <div class = 'w-1/6'>
                                        Status
                                    </div>
                                    <div class="w-3/6">
                                        Name
                                    </div>
                                    <div class="w-3/6">
                                        Vendor Name
                                    </div>
                                    <div class="w-3/6">
                                        Project Name
                                    </div>
                                    <div class = 'w-1/6'>
                                        Expiration
                                    </div>
                                    <div class = 'w-1/6'></div>
                                </div>
                                <div v-for = "policy in agent.policies" :key = "policy.id" :class="getRowClass(policy)">
                                    <div class = 'w-1/6'>
                                        {{ policy.status_group}}
                                    </div>
                                    <div class="w-3/6">
                                        {{ policy.name }}
                                    </div>
                                    <div class="w-3/6">
                                        {{ policy.verification.name }}
                                    </div>
                                    <div class="w-3/6">
                                        {{ policy.title }}
                                    </div>
                                    <div class = 'w-1/6'>
                                        {{ policy.expiration}}
                                    </div>
                                    <div class = 'w-1/6'>
                                        <router-link :to = "{name: 'agent-invite', params: {inviteCode: policy.invitation_code}}">
                                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View</button>
                                        </router-link>
                                    </div>
                                </div>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>

    import {GET_AGENT} from "../../store/actions/agent";

    export default {
        name: 'AgentDashboard',
        data() {
            return {

            }
        },
    methods: {
            getRowClass(policy) {
                if(policy.status_group == 'agent-started' || policy.status_group == 'sub-viewed') {
                    return 'border-t border-gray-200 pl-3 pr-4 py-3 bg-red-300 flex items-center justify-between text-sm leading-5';
                }
                return 'border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5';
            },
        getUrl(policy) {
            return '/agent/invite/' + policy.invitation_code;
        }
    },
    computed: {
        agent: function() {
            return this.$store.getters.getAgent;
        },
    },
    mounted() {
        if(this.$route.query.token) {
            this.$store.dispatch(GET_AGENT, {token: this.$route.query.token}).then().catch();
        }
    }
  }
</script>

<style scoped>

</style>
