<template>
    <div>
        <div class="header flex">
            <div class="w-1/6">
                <div v-on:click = 'back' class="back-button flex">
                    <div class="arrow"><font-awesome-icon icon = 'arrow-left'></font-awesome-icon></div>
                    <div style = 'padding-top: 9px; padding-left: 5px;'>Back</div>
                </div>
            </div>
        </div>
        <div class="coi-card">
            <div class="coi-card-border">
                <div class="coi-card-row flex">
                    <h1>General Settings</h1>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Full Name <span class="req">*</span>
                            </label>
                        </div>
                        <el-input class="formctrl"
                                placeholder = "Full Name"
                                v-model = 'user.display_name'
                                clearable required></el-input>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Company Name 
                            </label>
                        </div>
                        <el-input class="formctrl"
                                placeholder = "Company Name"
                                v-model = 'user.company_name'
                                clearable required></el-input>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-full form-item'>
                        <label>
                            Street Address
                        </label>
                        <el-input class="formctrl"
                                type = 'textarea'
                                :rows = '3'
                                placeholder = "Enter Street Address"
                                v-model = 'user.address'></el-input>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-1/2  flex-col form-item'>
                        <div>
                            <label>
                                State
                            </label>
                        </div>
                        <!-- <el-select v-model = 'user.states' clearable placeholder = "Select One" class="formctrl">
                            <el-option
                                    v-for = 'item in states'
                                    :key = 'item.value'
                                    :label = 'item.key'
                                    v-bind:value="item.key"
                                   >{{item}}</el-option> 
                        </el-select> -->

                        <select v-model="user.state" placeholder = "Select One" class="formctrl selectstate">
                            <option v-for="item in states" v-bind:key="item.abbreviation" :value='item.abbreviation'>
                                {{item.name}}
                            </option>
                        </select>  
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                City
                            </label>
                        </div>
                        <el-input
                                placeholder = "City"
                                v-model = 'user.city'
                                clearable></el-input>
                    </div>
                    
                </div>
                <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Zip Code
                            </label>
                        </div>
                        <el-input
                                placeholder = "Zip Code"
                                v-model = 'user.zip'
                                clearable></el-input>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Email
                            </label>
                        </div>
                        <el-input
                                placeholder = "Email"
                                v-model = 'user.email'
                                clearable></el-input>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Upload Logo
                            </label>
                        </div>
                        <input type="file" @change="uploadFile($event,'logo')"/>
                        <img :src="url+'/user_profile/'+user.logo" height="100" width="100" v-if="user.logo"/>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Upload Sample COI
                            </label>
                        </div>
                        <input type="file" @change="uploadFile($event,'coi')"/>
                        <a :href="url+'/user_profile/'+user.sample_coi" download v-if="user.sample_coi" target="_blank">Download File</a>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Upload Sample Contract
                            </label>
                        </div>
                        <input type="file" @change="uploadFile($event,'sample')"/>
                        <a :href="url+'/user_profile/'+user.sample_contract" download v-if="user.sample_contract" target="_blank">Download File</a>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-full'>
                        <el-button 
                                v-on:click="saveForm"
                                type = 'primary'>Update Info</el-button>
                    </div>
                </div>
                <hr>
                <div class = 'flex'>
                    <div class = 'w-full'>
                        <el-button v-show="!isShow"
                                v-on:click="showblock"
                                type = 'primary'>Change Password</el-button>
                    </div>
                </div>
                <div v-show="!seen">
                    <div class = 'flex'>
                        <div class = 'w-2/4 flex-col form-item'>
                            <div>
                                <label>
                                    Password
                                </label>
                            </div>
                            <el-input class="formctrl"
                                    type="password"
                                    v-model = 'password'
                                    clearable></el-input>
                        </div>
                        <div class = 'w-1/2 flex-col form-item'>
                            <div>
                                <label>
                                    Confirm Password 
                                </label>
                            </div>
                            <el-input class="formctrl"
                                    type="password"
                                    v-on:blur="validate"
                                    v-model = 'password_confirmation'
                                    clearable></el-input>
                        </div>
                    </div>
                    <div class = 'flex'>
                        <div class = 'w-full'>
                            <el-button
                                    v-on:click="changePassword"
                                    type = 'primary'>Change Password</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import HTTP from "../../../utilities/http";
    export default {
        name: 'UserProfile',
        data() {
            return {
                user: {
                    display_name : '',
                    company_name: '',
                    address: '',
                    state: '',
                    city: '',
                    zip: '',
                    email: '',
                    logo:'',
                    sample_coi:'',
                    sample_contract:'',
                },
                states: '',
                password : '',
                password_confirmation : '',
                seen: true,
                isShow: false,
                logo:'',
                sample_coi:'',
                sample_contract:'',
                url: process.env.VUE_APP_ROOT
            }
        },
        methods: {
            uploadFile(e, type) {
                let file = e.target.files[0];
                if (type == 'logo') {
                    this.logo = file;
                } else if (type == 'coi') {
                    this.sample_coi = file;
                } else if (type == 'sample') {
                    this.sample_contract = file;
                }
            },
            back() {
                this.$router.go(-1);
            },
            showblock() {
                this.seen = !this.seen;
                this.isShow = true;
            },
            validate(){
                if(this.password != this.password_confirmation){
                    this.$notify({
                            title: 'Error',
                            message: 'Your passwords do not match',
                            type: 'error'
                        });
                }
                
            },
            saveForm() {
                let formData = new FormData();
                formData.append('display_name',this.user.display_name);
                formData.append('company_name',this.user.company_name);
                formData.append('address',this.user.address);
                formData.append('state',this.user.state);
                formData.append('city',this.user.city);
                formData.append('zip',this.user.zip);
                formData.append('email',this.user.email);
                formData.append('logo',this.logo);
                formData.append('sample_coi',this.sample_coi);
                formData.append('sample_contract',this.sample_contract);
                HTTP({url: 'user/user-info', method: 'POST', data: formData})
                    .then(resp => {                       
                        this.$notify({
                            title: 'Success',
                            message: resp.data.message,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            changePassword() {
                HTTP({url: 'user/change-password', method: 'POST',data: {
                    password: this.password,
                    password_confirmation: this.password_confirmation}})
                    .then(resp => {                       
                        this.$notify({
                            title: 'Success',
                            message: resp.data.message,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            getuserinfo() {
                HTTP({url: 'user/', method: 'GET'})
                    .then(resp => {
                        this.user.display_name = resp.data.display_name;
                        this.user.company_name = resp.data.company_name;
                        this.user.address = resp.data.address;
                        this.user.state = resp.data.state;
                        this.user.city = resp.data.city;
                        this.user.zip = resp.data.zip;
                        this.user.email = resp.data.email;
                        this.user.logo=resp.data.logo;
                        this.user.sample_coi=resp.data.sample_coi;
                        this.user.sample_contract=resp.data.sample_contract;

                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            getstate() {
                HTTP({url: 'user/getstate', method: 'GET'})
                    .then(resp => {
                        this.states = resp.data;
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            }

        },
        mounted() {
            this.getuserinfo();
            this.getstate();

        }
    }
</script>

<style scoped>
  body{
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
    .el-button{
        margin:30px 0px;
    }
  .coi-card-row{
      border-radius: 8px 8px 0px 0px;
  }
  .req{
      color: red;
  }
  .selectstate{
      height: 40px;
      border: 1px solid;
      background-color: white;
  }
</style>
