<template>
    <div class="projects mt-5">
        <div class="flex header">
            <div class="w-1/3 count">
                <h1>Our Policies ({{ policies.length}})</h1>
            </div>
            <div class="w-1/3 mx-auto">
                <div class="search-bar">
                    <div class="search-area">
                        <span> &#x1F50D;  </span><input type="text" v-on:keyup="search"  placeholder='Search Policy Template'>
                    </div>
                </div>
                <el-button
                    class = 'float-left'
                    type = 'success'
                    >
                    Search
                </el-button>
            </div>
            <div class="ml-3">
                <el-button
                        class = 'float-right'
                        type = 'success'
                        @click = "showNewPolicy = true"
                        >
                    Add Policy Template
                </el-button>
            </div>
        </div>
        <div class = 'flex my-5 coi-card'>
            <div class="coi-card-border">
                <div class="coi-card-header flex">
                    <div class="flex w-full lg:w-5/6">
                        <div class="w-3/12  flex" v-on:click = "sortBy('name')">
                                <div class = 'mr-3'>
                                    <translate source ='Name'/>
                                </div>
                                <div class="sort-none" v-if="sort.name == sortDirection[0]">
                                    <font-awesome-icon icon = 'arrows-alt-v'/>
                                </div>
                                <div class="sort-ascending" v-if="sort.name == sortDirection[1]">
                                    <font-awesome-icon icon = 'arrow-up' />
                                </div>
                                <div class="sort-descending" v-if="sort.name == sortDirection[2]">
                                    <font-awesome-icon icon = 'arrow-down' />
                                </div>
                            </div>
                    </div>
                    <div class="w-1/6 actions"></div>
                </div>
                <div class="w-full">
                    <div class = 'w-full scrollable-box'>
                        <div v-for = "(policy) in policies" v-bind:key='policy.id'  class = 'flex-col '>
                            <div class = 'w-full vendor-row flex'>
                                <div class="flex w-full" :class="policy.status">
                                    <div class="w-full lg:w-5/6 clickable flex">
                                        <div class="w-6/12 lg:w-5/12 flex row-item">
                                            <div class = 'fa-icon float-left h-full'><font-awesome-icon icon = 'chess-pawn'></font-awesome-icon></div>
                                            <div>{{policy.name}}</div>
                                        </div>
                                    </div>
                                    <div class="w-1/6 flex actions">
                                        <div class=" view" title="View">
                                            <el-button
                                                class = 'float-left'
                                                type = 'primary'
                                                v-on:click="goToEditTemplate(policy.id)"
                                                >
                                                Edit
                                             </el-button>
                                        </div>
                                       <!-- <div class=" view" v-on:click="removePolicy(policy.id)">
                                            <div class="fa-icon float-left" style="color:red;" title="Delete">
                                                <el-button
                                                class = 'float-left'
                                                type = 'danger'
                                                >
                                                Delete
                                                </el-button>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <el-dialog
                title = 'Add Policy Template'
                :visible.sync = 'showNewPolicy'>
            <SettingsPolicyData
                    :policy = "{}"
                    :dialog = true
                    v-on:policy-data-saved = 'handlePolicyDataSave'
                    v-on:policy-close-dialog = 'handlePolicyCloseDialog' />
        </el-dialog>
    </div>
</template>

<script>
    import {
        INIT_SETTINGS_POLICIES,
        DELETE_POLICY,
       // HANDLE_VENDOR_SAVE, 
        SEARCH_POLICIES,
    } from "../../../../store/actions/settings-policy";
    import SettingsPolicyData from "../components/SettingsPolicyData";

    export default {
        name: 'Policies',
        components: {
            SettingsPolicyData
        },
        data() {
            return {
                showNewPolicy: false,
                sortDirection:['','desc','asc'],
            }
        },
        methods: {
            _getPolicies() {
                this.$store.dispatch(INIT_SETTINGS_POLICIES);
            },
            // handlePolicyDataSave(policy) {
            //     this.$store.dispatch(HANDLE_VENDOR_SAVE, policy);
            // },
            handlePolicyCloseDialog() {
                this.showNewPolicy = false;
                this.$store.dispatch(INIT_SETTINGS_POLICIES).then(() => {}).catch();
            },
            removePolicy(policyId) {
                this.$store.dispatch(DELETE_POLICY, policyId).then(() => {
                    this.$router.go(0)
                }).catch();
            },
            goToEditTemplate(policyId) {
                this.$router.push({ name: 'edit-template',params: {id: policyId}});
            },
            sortBy(col){
                let sort = JSON.parse(JSON.stringify(this.sort));
                let index = this.sortDirection.indexOf(sort[col]);
                index >=2 ? index = 0 : index ++;
                sort[col] = this.sortDirection[index];
                for (const column in sort){
                    if (column !== col && sort.hasOwnProperty(column)){
                        sort[column] = this.sortDirection[0];
                    }
                }
                this.sort = sort;
                // this.search();
            },
            getSort() {
                for (let column in this.sort) {
                    if(this.sort[column].length) {
                        return {
                            column: column,
                            direction: this.sort[column]
                        }
                    }
                }
                return {column: '', direction: ''};
            },
            search() {
                let sort = this.getSort();
                this.$store.dispatch(SEARCH_POLICIES, {
                    searchText: this.searchText,
                    column: sort.column,
                    direction: sort.direction
                }).then().catch();
            },
            
        },
        computed: {
            policies: function() {
                return this.$store.getters.getPolicies;
            },
            searchText: {
                get() {
                    return this.$store.getters.getPolicySearchText;
                },
                set(value) {
                    this.$store.commit('SET_POLICY_SEARCH_TEXT', value);
                }
            },
            sort: {
                get() {
                    return this.$store.getters.getPolicySort;
                },
                set(value) {
                    this.$store.commit('SET_POLICY_SORT', value);
                }
            },
        },
        mounted() {
            this._getPolicies();
        }
    }
</script>

<style scoped>
    .vendor-row{
        width: 100%;
        margin: 5px 0 5px;
        padding: 10px 0 15px;
        border-bottom: 1px solid #e3e8ff;
        border-radius: 8px 8px 0 0;
    }
    .row-item{
        margin-right: 40px;
        overflow: hidden;
    }
    .actions{
    }
    .vendor-row .actions{
        text-align: right;
        border-left: 1px solid #e3e8ff;
        padding-left: 20px;
        margin-left: 25px;
    }
    .coi-card-body-border{
        padding: 0 50px
    }
    .coi-card-body-border .subscript{
        border-top: 1px solid #e3e8ff;
        margin-top: 3px;
        padding-top: 2px;
    }
    .coi-card-body-border .actions{
        margin: 25px 0;
    }
    .details{
        margin: 20px 50px;
    }
    .details .flex-col{
        margin: 15px 0;
        color: #255897;
        font-size: 16px;
        line-height: 20px;
    }
    .view{
        margin-right: 15px;
    }
    .red .vendor-row{
        border-bottom: 1px solid #fb3030;
    }
    /* .uploadbtn{
        margin-right: 60px;
    } */
    .scrollable-box{
        height: 580px;
    }
    .sort-name{
        margin-right: 0;
    }
    .sort-projects{
        margin-right: 30px;
    }
    .sort-policies{
        margin-right: 25px;
    }
    .sort-status{
        margin-right: 10px;
    }
    @media only screen and (max-width: 1024px){
        .vendor-row .actions, .coi-card-header .actions{
            display: none;
        }
        .sort-name{
            margin-right: 20px;
        }
        .sort-projects{
            /*margin-right: 10px;*/
        }
        .sort-policies{
            margin-right: 30px;
        }
        .sort-status{
            margin-right: 0;
        }
    }
</style>
