<template>
    <div>
        <input
                v-model = 'content'
                :placeholder = 'placeholder'
                @input = "handleInput"
                @keyup = "handleKeyUp"
                type="text"
                class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
    </div>
</template>

<script>
    export default {
        name: 'CoiInput',
        props: ['value', 'placeholder'],
        data() {
            return {
                content: this.value
            }
        },
        methods: {
            handleInput() {
                this.$emit('input', this.content);
            },
            handleKeyUp() {
              this.$emit('keyup');
            }
        }
    }
</script>
<style scoped>
    input{
        width: 90%;
    }
</style>
