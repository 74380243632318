import { render, staticRenderFns } from "./VerificationInvite.vue?vue&type=template&id=44a1d379&scoped=true&"
import script from "./VerificationInvite.vue?vue&type=script&lang=js&"
export * from "./VerificationInvite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a1d379",
  null
  
)

export default component.exports