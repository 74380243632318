<template>
  <div class="projects mt-5">
    <div class="flex header">
      <div class="w-1/3 count">
        <h1>Reports ({{ reports.length }})</h1>
      </div>
    </div>
    <div class="mt-5 grid grid-cols-4">
      <router-link
          v-for="report in reports"
          :key="report.id"
          :to="{ name: 'export-report', params: { reportId: report.id } }">
        <el-card
            class="py-10 text-center text-xl"
            shadow="always"
        >
          {{ report.title }}
        </el-card>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "Reports",
  data() {
    return {
      reports: [
        {id: 'export-certificate', title: "Export Certificates"}
      ]
    };
  },
  methods: {

  }
}
</script>


<style>
.daterange-picker {
  width: 24rem !important;
}
</style>