<template>
    <div>
vendor dashboard
    </div>
</template>

<script>

    export default {
        name: 'VendorDashboard',
        data() {
            return {

            }
        },
    methods: {

    },
    computed: {

    },
    mounted() {

    }
  }
</script>

<style scoped>

</style>
