<template>
    <div>
        <div class="header flex">
            <div class="w-1/6">
                <router-link :to="{name: 'projects'}">
                    <button class = 'edit-button'>Back to projects</button>
                </router-link>
            </div>
            <div class="w-5/6 flex justify-end">
		<a v-bind:href="url + '/app/export/project-detail/' + paramsId" target="_blank">
                   <button class="edit-button mr-3">Export</button>
                </a>
                <router-link :to = "{name: 'project-edit', params: {id: project.id}}">
                    <button class="edit-button mr-3">Edit project</button>
                </router-link>
                <router-link :to = "{name: 'verification-new'}">
                    <el-button
                            class = 'float-right'
                            type = 'success'>New Verification</el-button>
                </router-link>
            </div>
        </div>
        <div class="coi-card mt-5">
            <div class="project_header flex-col">
                <div class="header_row_1 flex">
                    <div class="w-1/3">
                        <h1>{{ project.title}}</h1>
                    </div>
                    <div class="w-2/3 flex justify-end">
                        <div class="flex-col header-item">
                            <div>{{ project.start || "None"}}</div>
                            <div class="subscript">Start Date</div>
                        </div>
                        <div class="flex-col header-item">
                            <div>{{ project.end || "None"}}</div>
                            <div class="subscript">End Date</div>
                        </div>
                        <div class="flex-col header-item">
                            <div>{{project.risk || "None"}}</div>
                            <div class="subscript">Risk</div>
                        </div>
                        <div class="flex-col">
                            <div :class=project.status>{{ project.status === 'green' ? 'Compliant' : 'Needs Attention'}}</div>
                            <div class="subscript">Status</div>
                        </div>
                        <div>
                            <div v-if="project.status === 'green'" class="status-mark">&#x2713;</div>
                            <div v-else class="status-mark">&#x26a0;</div>
                        </div>
                    </div>
                </div>
                <div class="header_row_2 flex">
                    <div class="w-4/6">
                        <h3>Project Details</h3>
                    </div>
                    <div class="w-2/6 flex justify-end">
                    </div>
                </div>
            </div>
            <div class="flex-col">
                <div></div>
                <div v-for = "(subcontractor, key,  index) in project.user.general_contractor.subcontractors" v-bind:key = 'subcontractor.id' class = 'flex'>
                    <div class="vendor-row flex" :class="index % 2 === 0 ? 'even ' + index : 'odd ' + index" >
                        <div class="w-1/4 flex clickable" v-on:click="showMore(index)">
                            <div class = 'flex-col'>
                                <div>
                                    <div class="awkward_dot">&#x25CF;</div>
                                </div>
                            </div>
                            <div class = 'flex-col row-item'>
                                <div>
                                    {{ subcontractor.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if = 'subcontractor.verifications' class = 'w-full policy-box' :class="{'policy-box-full' : show[index]}">
                            <div v-for = 'verification in subcontractor.verifications' v-bind:key = 'verification.id'>
                                <div v-if = 'verification.project_id == paramsId'>
                                <div v-for = 'policy in verification.policies' v-bind:key = 'policy.id' class = 'flex policy-row clickable' :class = getClass(policy.status_group)>
                                    <div class = 'w-1/3 flex row-item' v-on:click="viewPolicy(policy.id)">
                                        <div>
                                            <div v-if="policy.image">
                                                <img :src="policy.image">
                                            </div>
                                            <div v-else class="awkward_dot">&#x25CF;</div>
                                        </div>
                                        <div class='flex-col'>
                                            <div>
                                                {{ policy.name || "Policy Title"}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-2/3 flex justify-end">
                                        <div class="w-3/12 col row-item" v-on:click="viewPolicy(policy.id)">
                                            <div>
                                                {{ policy.status_group | capitalize }}
                                            </div>
                                            <div class="subscript">Verification Status</div>
                                        </div>
                                        <div class="w-3/12 col row-item" v-on:click="viewPolicy(policy.id)">
                                            <div>
                                                {{policy.expiration || "None"}}
                                            </div>
                                            <div class="subscript">Expiration</div>
                                        </div>
                                        <div class="w-3/12 col row-item" v-on:click="viewPolicy(policy.id)">
                                            <div>{{policy.agent_email || "None"}}</div>
                                            <div class="subscript">Agent Email</div>
                                        </div>
                                        <div class = 'w-3/12 col row-item'>
                                            <el-button
                                                    v-if = "policy.is_active"
                                                    @click = "deactivate(policy.id)"
                                                    type = 'info'
                                                    plain
                                                    round
                                                    size = 'mini'
                                            >
                                                De-activate
                                            </el-button>
                                            <el-button
                                                    v-else
                                                    @click = "activate(policy.id)"
                                                    type = 'info'
                                                    plain
                                                    round
                                                    size = 'mini'
                                            >
                                                Activate
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
            </div>
        </div>
    </div>
</template>

<script>
    import {SELECT_PROJECT} from "../../../store/actions/project";
    import {ACTIVATE_POLICY, DEACTIVATE_POLICY, SELECT_POLICY} from "../../../store/actions/policy";
    export default {
        name: 'Project',
        data() {
            return {
		url: process.env.VUE_APP_ROOT,
                sort:{},
                sortDirection:['','asc','desc'],
                show: [],
                paramsId:''
            }
        },
        methods: {
            getClass(status) {
                if(status === 'expiring') {
                    return 'text-orange-500';
                }
                if(
                    status === 'initiated' ||
                    status === 'sub-viewed' ||
                    status === 'sent-to-agent' ||
                    status === 'agent-started' ||
                    status === 'does-not-meet-requirements' ||
                    status === 'expired'
                ) {
                    return 'text-red-700';
                }

            },
            activate(id) {
                this.$store.dispatch(ACTIVATE_POLICY, id).then(() => {
                    this.initProject();
                    this.$notify({
                        title: 'Success',
                        message: 'The policy has been activated',
                        type: 'success',
                        duration: 2500
                    });
                });
            },
            deactivate(id) {
                this.$store.dispatch(DEACTIVATE_POLICY, id).then(() => {
                    this.initProject();
                    this.$notify({
                        title: 'Success',
                        message: 'The policy has been deactivated',
                        type: 'success',
                        duration: 2500
                    });
                });
            },
            sortBy(col){
                let index = this.sortDirection.indexOf(this.sort[col]);
                index >=2 ? index = 0 : index ++;
                this.sort[col] = this.sortDirection[index];
                for (const column in this.sort){
                    if (column !== col && this.sort.hasOwnProperty(column)){
                        this.sort[column] = this.sortDirection[0];
                    }
                }
            },
            viewPolicy(id) {
                this.$store.dispatch(SELECT_POLICY, id).then(() => {
                    this.$router.push({name: 'policy', params: {id: id}});
                }).catch();
            },
            showMore(index) {
                if(this.show[index]) {
                    this.$set(this.show, index, false);
                }
                else {
                    this.$set(this.show, index, true);
                }
            },
            initProject() {
                this.$store.dispatch(SELECT_PROJECT, this.$route.params.id).then(() => {
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }
        },
        computed: {
            project: function() {
                return this.$store.getters.getProject;
            },
        },
        mounted() {
            this.paramsId = this.$route.params.id;
            this.initProject();
        }
    }
</script>

<style scoped>
    .project_header h1{
        color: #255897;
    }
    .header_row_1{
        width: 100%;
        font-size: 16px;
        text-align: start;
    }
    .header_row_1 .header-item{
        margin-right: 50px;
    }
    .header_row_2{
        margin: 33px 0 15px;
    }
    .header_row_2 h3{
        color: #255897;
        font-size: 20px;
        line-height: 30px;
    }
    .vendor-row{
        width: 100%;
        vertical-align: top;
        padding: 10px 25px 10px 40px;
        overflow: hidden;
        position: relative;
    }
    .vendor-row .row-item{
        margin-right: 25px;
    }
    .policy-row{
        margin: 0 0 20px;
    }
    .policy-box{
        height: 200px;
        position: relative;
        overflow: auto;
    }
    .policy-box-full{
        height: 100%;
    }
    .project_header{
        margin: 25px;
        border-bottom: 2px solid #e3e8ff;
    }
    .footer{
        margin-top: 40px;
        width:100%;
        border-bottom: 4px solid #566cd3;
    }
    .view{
        text-align: end;
    }
</style>
