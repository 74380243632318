<template>
    <div class="projects mt-5">
        <div class="flex header">
            <div class="w-1/3 count">
                <div v-on:click = 'goBack' class="back-button flex">
                    <div class="arrow"><font-awesome-icon icon = 'arrow-left'></font-awesome-icon></div>
                    <div style = 'padding-top: 9px; padding-left: 5px;'>Back</div>
                </div>
            </div>
            <div class="w-1/3 mx-auto">
            </div>
            <div class="ml-3">
                <el-button
                        class = 'float-right'
                        type = 'success'
                        @click = "showNewField = true">
                    Add New Field
                </el-button>
            </div>
        </div>
        <div class = 'flex my-5 coi-card'>
            <div class="coi-card-border">
                <div class="coi-card-header flex">
                    <div class="flex w-full lg:w-5/6">
                        <h1 v-if="!showEditPolicy">Policy : {{policy.name}}</h1>
                        <el-button
                        type = 'warning'
                        @click = "showEditPolicy = true"
                        v-if="!showEditPolicy"
                        style="margin-left:10px;"
                        >
                    Edit Name
                   </el-button>
                        <div class="flex" style = "width:100%;" v-if="showEditPolicy">
                            <input type="text" name="fieldid" class="form-control" v-model="policy_name" style="width:80%;border:1px solid black;border-radius:10px;padding:10px;float:left;margin-bottom:10px;">  
                            <div>
                                <el-button v-on:click = 'updatePolicyName' type="success" icon="el-icon-check" circle class = 'float-right mr-3'></el-button>
                                <el-button @click = "showEditPolicy = false" type="danger" icon="el-icon-close" circle class = 'float-right'></el-button>
                            </div>
                        
                        </div>   
                                                                       
                    </div>
                    <!-- <div class="w-1/2 actions">
                    
                    </div> -->
                </div>
                <div class="w-full">
                    <div class = 'w-full scrollable-box'>
                        <div v-for = "(field, index) in templatefield" v-bind:key='field.id'  class = 'flex-col '>
                            <div class = 'w-full vendor-row flex' :class="{'show' : show[index]}">
                                <div class="flex w-full" :class="field.status">
                                    <div class="w-full lg:w-5/6 clickable flex styleadd" v-on:click = 'showMore(index)'>
                                        <div class="w-6/12 lg:w-5/12 flex row-item">
                                            <div class = 'fa-icon float-left h-full'></div>
                                            <div>{{field.name}}</div>
                                        </div>
                                        <div class="w-6/12 flex-col row-item">
                                            <div>{{field.help_text}}</div>
                                        </div>
                                        <div class="w-2/12 flex-col row-item" v-if="field.type == 'switch'">
                                            <div>
                                                <input type="checkbox" class="custom-control-input" id="'switch_' . field->id" checked>
                                                <label class="custom-control-label" for="customSwitch1">{{field.data}}</label>
                                            </div>
                                        </div>
                                        <div class="w-2/12 flex-col row-item" v-else-if="field.type == 'text'">
                                            <div>
                                                <input type="text" class="form-control textinput">
                                            </div>
                                        </div>
                                        <div class="w-2/12 flex-col row-item" v-else-if="field.type == 'upload'">
                                            <div>
                                                <input type="file" class="form-control">
                                            </div>
                                        </div>                             
                                    </div>
                                </div>
                                <div class="w-1/6 flex actions">
                                    <div class=" view" v-on:click="removePolicyField(field.id)">
                                        <div class="fa-icon float-left" style="color:red;" title="Delete">
                                            <el-button
                                            class = 'float-left'
                                            type = 'danger'
                                            >
                                            Delete
                                            </el-button>
                                        </div>
                                    </div>
                                </div>       
                            </div>
                            <transition name = 'slide'>
                                <div v-if = 'show[index]' class = 'coi-card-body'>
                                    <div class="coi-card-body-border flex-col">
                                        <SettingsPolicyFieldData
                                            :field = "field"
                                            :dialog = false
                                            v-on:policyfield-close-dialog = 'handlePolicyFieldCloseDialog'/>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
                title = 'Add New Field'
                :visible.sync = 'showNewField'>
            <SettingsPolicyFieldData
                    :field = "{}"
                    :dialog = true
                    v-on:policyfield-close-dialog = 'handlePolicyFieldCloseDialog' />
        </el-dialog>
    </div>
</template>

<script>
    import HTTP from "../../../../utilities/http";
    import {
        SELECT_TEMPLATE_FILEDS,
        INIT_SETTINGS_POLICIES,
       // DELETE_POLICY_FIELD,
      // DELETE_FIELD
    } from "../../../../store/actions/settings-policy";
    import SettingsPolicyFieldData from "../components/SettingsPolicyFieldData";
    import axios from 'axios';
    export default {
        name: 'PolicySettingsEdit',
        components: {
            SettingsPolicyFieldData
        },
        data() {
            return {
                show: [],
                templatefield: {},
                policy:{},
                showNewField: false,
                policy_name: '',
                showEditPolicy:false,
            }
        },
        methods: {
            updatePolicyName() {
                // console.log("Helloooooo");
                HTTP({url: 'settings-policy/policy-templates-edit/'+this.$route.params.id, method: 'PUT', data:{name:this.policy_name}})
                    .then(resp => {
                        this.$notify({
                            title: 'Success',
                            message: resp.data.message,
                            type: 'success'
                        });
                        this.$router.go(0);
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            goBack() {
                this.$router.go(-1);
            },
            handlePolicyFieldCloseDialog() {
                this.showNewField = false;
                this.$store.dispatch(INIT_SETTINGS_POLICIES).then(() => {}).catch();
            },
            showMore(index) {
                if(this.show[index]) {
                    this.$set(this.show, index, false);
                }
                else {
                    this.$set(this.show, index, true);
                }
            },
            removePolicyField(fieldId) {
                //console.log("===>", fieldId);
                //this.$store.dispatch(DELETE_FIELD, fieldId).then(() => {}).catch();
                let token = localStorage.getItem('api-token');
                const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
                }
                axios.get(process.env.VUE_APP_ROOT + '/api/settings-policy/policy-templates-field-delete/'+fieldId, {
                    headers: headers
                }).then(()=>{
                    this.$router.go(0);
                }).catch()
                            },
                },
        mounted() {
            if(typeof this.$route.params.id != 'undefined') {
                this.$store.dispatch(SELECT_TEMPLATE_FILEDS, this.$route.params.id).then((templatefield) => {
                    this.templatefield = JSON.parse(JSON.stringify(templatefield.fields)); 
                    this.policy = JSON.parse(JSON.stringify(templatefield.policy));
                    this.policy_name = this.policy.name;
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }
            else {
                this.$store.dispatch(SELECT_TEMPLATE_FILEDS).then(() => {
                    this.templatefield = {};
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }
        }
    }
</script>

<style scoped>
    .vendor-row{
        width: 100%;
        margin: 5px 0 5px;
        padding: 10px 0 15px;
        border-bottom: 1px solid #e3e8ff;
        border-radius: 8px 8px 0 0;
    }
    .row-item{
        margin-right: 40px;
        /* overflow: hidden; */
    }
    .actions{
    }
    .vendor-row .actions{
        text-align: right;
        border-left: 1px solid #e3e8ff;
        padding-left: 20px;
        margin-left: 25px;
    }
    .coi-card-body-border{
        padding: 0 50px
    }
    .coi-card-body-border .subscript{
        border-top: 1px solid #e3e8ff;
        margin-top: 3px;
        padding-top: 2px;
    }
    .coi-card-body-border .actions{
        margin: 25px 0;
    }
    .details{
        margin: 20px 50px;
    }
    .details .flex-col{
        margin: 15px 0;
        color: #255897;
        font-size: 16px;
        line-height: 20px;
    }
    .view{
        margin-right: 15px;
    }
    .red .vendor-row{
        border-bottom: 1px solid #fb3030;
    }
    /* .uploadbtn{
        margin-right: 60px;
    } */
    .scrollable-box{
        height: 580px;
    }
    .sort-name{
        margin-right: 0;
    }
    .sort-projects{
        margin-right: 30px;
    }
    .sort-policies{
        margin-right: 25px;
    }
    .sort-status{
        margin-right: 10px;
    }
    .textinput{
        border: black;
        border-style: solid;
    }
    .styleadd{
        width: 100%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1024px){
        .vendor-row .actions, .coi-card-header .actions{
            display: none;
        }
        .sort-name{
            margin-right: 20px;
        }
        .sort-projects{
            /*margin-right: 10px;*/
        }
        .sort-policies{
            margin-right: 30px;
        }
        .sort-status{
            margin-right: 0;
        }
    }
</style>
