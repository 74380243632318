<template>
    <div>
        <div class="header flex">
            <div class="w-1/6">
                <div v-on:click = "back" class="back-button flex">
                    <div class="arrow">&#x2B60;</div>
                    <div>Back to contract</div>
                </div>
            </div>
        </div>
        <div class="coi-card">
            <div class="coi-card-border">
                <div class="coi-card-row flex">
                    
                    <h1><Emb>Edit Contract</Emb></h1>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Project
                            </label>
                        </div>
                        <el-select v-model = 'project.project_id' clearable placeholder = "Select One" class="formctrl">
                            <el-option
                                    v-for = 'item in projects'
                                    :key = 'item.id'
                                    :label = 'item.title'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Subcontractor
                            </label>
                        </div>
                        <el-select v-model = 'project.subcontractor_id' clearable placeholder = "Select One" class="formctrl">
                            <el-option
                                    v-for = 'item in vendors'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 flex-col form-item'>
                        <label>
                            Contract title
                        </label>
                        <el-input class="formctrl"
                                type = 'input'
                                placeholder = "Enter title"
                                v-model = 'project.title'></el-input>
                    </div>

                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Edit by Subcontractor
                            </label>
                        </div>
                        <el-select v-model = "project.editable" clearable placeholder = "Select One" class="formctrl">
                            <el-option
                                    v-for = 'item in editOptions'
                                    :key = 'item.value'
                                    :label = 'item.label'
                                    :value = 'item.value'></el-option>
                        </el-select>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 flex-col form-item'>
                        <label>
                            Price
                        </label>
                        <el-input class="formctrl"
                                type = 'input'
                                placeholder = "Enter Price"
                                v-model = 'project.price'></el-input>
                    </div>

                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Expiration Date
                            </label>
                        </div>
                        <datepicker
                                v-model="project.expiration_date"
                                format = 'M/dd/yyyy'
                                input-class = 'datepicker-input mb-1 shadow appearance-none border rounded py-2 px-3 mt-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        >
                        </datepicker>
                    </div>
                </div>
                <!-- <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>Start Date</label>
                        </div>
                        <el-date-picker class="formctrl w-full"
                                        v-model = 'project.start'
                                        type = 'date'
                                        format = 'MM/dd/yyyy'
                                        value-format = 'MM/dd/yyyy'
                                        placeholder = 'Select start date'></el-date-picker>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>End Date</label>
                        </div>
                        <el-date-picker class="formctrl"
                                        v-model = 'project.end'
                                        type = 'date'
                                        format = 'MM/dd/yyyy'
                                        value-format = 'MM/dd/yyyy'
                                        placeholder = 'Select end date'></el-date-picker>
                    </div>
                </div> -->
                <!-- <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Location
                            </label>
                        </div>
                        <el-input
                                placeholder = "Project Location"
                                v-model = "project.location"
                                clearable></el-input>
                    </div>
                    <div v-if = 'hasPayModels' class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Pay Group
                            </label>
                        </div>
                        <el-select v-model = 'project.pay_group_id' clearable placeholder = "Select One If Needed" class="formctrl">
                            <el-option
                                    v-for = 'item in payGroups'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div v-if = 'hasPayModels' class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Pay Model
                            </label>
                        </div>
                        <el-select v-model = 'project.pay_model_id' clearable placeholder = "Select One If Needed" class="formctrl">
                            <el-option
                                    v-for = 'item in payModels'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Insurance Model
                            </label>
                        </div>
                        <el-select v-model = 'project.insurance_model_id' clearable placeholder = "Select One If Needed" class="formctrl">
                            <el-option
                                    v-for = 'item in insuranceModels'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                External Key
                            </label>
                        </div>
                        <el-input class="formctrl"
                                placeholder = "External Key"
                                v-model = "project.external_key"
                                clearable></el-input>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                    </div>
                </div> -->
                <div class = 'flex'>
                    <div class = 'w-2/3'>
                        <el-button
                                v-on:click="showSection"
                                type = 'primary'>Add New Section</el-button>
                                &nbsp;
                                <a  target="_blank" :href = "`${baseRoot}/app/contract/contract-print/${contractId}`">
                                    <el-button type="info">
                                    Print Contract
                                    </el-button>
                                </a>
                                &nbsp;
                        <el-button
                                v-on:click="notifySub"
                                type = 'success'>Complete and Notify Sub-Contractor</el-button>
                    </div>
                    
                    <div class = 'w-2/4'>
                        Current Version: {{ project.version }}
                        <el-button
                                v-on:click="deleteContract"
                                type = 'warning'>Delete Contract</el-button>
                    </div>
                    
                </div>
                <div>
                    <h1>Sections</h1>
             <div class="flex" v-for="sectionItem in contractSetion" :key="sectionItem.id">
                <br />
                <div class="w-2/6">
                    <div v-html="sectionItem.content"></div>
                </div>
                <br />
                <div class="w-2/6">
                    <h1>{{ sectionItem.title }}</h1>
                </div>
                <div class="w-2/6">
                    <el-button type="info" v-on:click="showDialogOnEditMode(sectionItem.id)">Edit</el-button>
                    <el-button type="warning" v-on:click="deleteSection(sectionItem.id)">Delete</el-button>
                </div>
             </div>   
             <br />    
            <h1>Audit Trail</h1>
            <div class="flex" v-for="logItem in logs.data" :key="logItem.id">
                <div class="w-2/4">
                    {{ logItem.message }}
                </div>
                <div class="w-2/4">
                    {{ logItem.created_at }}
                </div>
            </div>
        </div>
            </div>
        </div>
        
        <el-dialog
                title = 'Add New Section'
                :visible.sync = 'showNewSection'>
                <div class = 'flex'>
                    <div class = 'w-full form-item'>
                        <div>
                            <label>
                                Contract Section
                            </label>
                        </div>
                        <el-select v-model = 'section.section_id' clearable placeholder = "Choose One" class="formctrl" v-on:change="_setNewSetionOnSelect()">
                            <el-option
                                    v-for = 'item in defaultSections'
                                    :key = 'item.id'
                                    :label = 'item.title'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 flex-col form-item'>
                        <label>
                            Title
                        </label>
                        <el-input class="formctrl"
                                type = 'input'
                                placeholder = "Enter title"
                                v-model = 'section.title'></el-input>
                    </div>

                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Display Order
                            </label>
                        </div>
                        <el-input class="formctrl"
                                type = 'input'
                                placeholder = "Enter order"
                                v-model = 'section.display_order'></el-input>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-full form-item'>
                        <div>
                            <label>
                               Content
                            </label>
                        </div>
                        <vue-editor v-model="section.content"></vue-editor>
                        <!-- <el-input class="formctrl"
                                type = 'textarea'
                                placeholder = "Enter title"
                                v-model = 'section.content'></el-input> -->
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/3'>
                        <el-button
                                v-if="!editSection"
                                v-on:click="saveSection"
                                type = 'primary'>Save</el-button>
                        <el-button
                                v-else
                                v-on:click="updateSection"
                                type = 'primary'>Save</el-button>
                                &nbsp;
                        <el-button
                                v-on:click="saveDefault"
                                type = 'primary'>Save as new default section</el-button>
                    </div>
                    
                </div>
        </el-dialog>
    </div>
    
</template>

<script>
    import Datepicker from "vuejs-datepicker";
    import {SELECT_PROJECT, GET_PROJECTS, GET_EMPTY_PROJECT, INIT_PROJECTS} from "../../../store/actions/project";
    import {
        INIT_VENDORS,
    } from "../../../store/actions/vendor";
    import HTTP from "../../../utilities/http";
    import { VueEditor } from "vue2-editor";
    export default {
        name: 'ContractUpdate',
        components:{
            Datepicker,
            VueEditor,
        },
        data() {
            return {
                activeOptions: [{
                    value: 1,
                    label: 'Active'
                }, {
                    value: 0,
                    label: 'Archived'
                }],
                project: {},
                editOptions: [{"value":0, "label":"No"}, {"value":1, "label":"Yes"}],
                selected:"No",
                contract:null,
                showNewSection:false,
                defaultSections:[],
                logs:{},
                section:{},
                defSection:"",
                contractSetion:[],
                editSection:false,
                contractId:this.$route.params.contractId,
                baseRoot:process.env.VUE_APP_ROOT
            }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            showSection() {
                this.updateContract();
                this.showNewSection=true;
                this._getDefaultSection();
            },
            hasPayModels() {
                return this.$store.getters.getUser.pay_models
            },
            saveForm() {
                HTTP({url: 'contract/create/contract', method: 'POST', data: this.project})
                    .then(()=>{
                        this.$store.dispatch(GET_PROJECTS);
                        this.$notify({
                            title: 'Success',
                            message: "Contract created successfully",
                            type: 'success',
                            duration: 2500,
                            onClose: (() => {
                                this.$router.go(-1);
                            })
                        });
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            _getProjects() {
                this.$store.dispatch(INIT_PROJECTS).then(() => {
                }).catch();
            },
            _getVendors() {
                this.$store.dispatch(INIT_VENDORS).then(() => {}).catch();
            },
            _getContract() {
                HTTP({ url: 'contract/get/'+this.$route.params.contractId, method: 'GET' })
                    .then(resp => {
                        this.project = resp.data;
                        if(resp.data.editable == null) {
                            this.project.editable = 0;
                        }
                    })
                    .catch();
            },
            _getDefaultSection() {
                HTTP({url: 'contract/get/section/'+this.$route.params.contractId, method: 'GET'}).then(resp => {
                        this.defaultSections = resp.data.defaultSections;
                        this.logs = resp.data.logs;
                        this.section.display_order = this.defaultSections.length + 1;
                        this.contractSetion=resp.data.contractSection
                    }).catch()
            },
            _setNewSetionOnSelect() {
                // console.log("===>", this.section.section_id);
                let sectionData = this.defaultSections.filter((item)=>{
                    return item.id == this.section.section_id;
                });
                // console.log("====>", sectionData);
                if (sectionData.length) {
                    this.section = sectionData[0];
                    this.section.section_id = sectionData[0].id;
                }
            },
            saveDefault() {
                this.section.default = 1;
                this.saveSection();
            },
            saveSection() {
                delete this.section.id;
                this.section.contract_id = this.$route.params.contractId;
                HTTP({url:'contract/section/create', method: 'POST', data: this.section}).then(()=>{ 
                   this._getProjects();
                   this._getContract();
                   this.section = {};
                   this.showNewSection = false;
                   this._getDefaultSection();
                }).catch();
            },
            updateSection() {
                this.section.contract_id = this.$route.params.contractId;
                HTTP({url:'contract/update-section', method: 'POST', data: this.section}).then(()=>{ 
                   this._getProjects();
                   this._getContract();
                   this.section = {};
                   this.showNewSection = false;
                   this._getDefaultSection();
                }).catch();
            },
            updateContract() {
                this.project.contractId = this.$route.params.contractId;
                HTTP({url:'contract/update', method: 'POST', data:this.project}).then(()=>{
                    this._getContract();
                }).catch();
            },
            deleteSection(id) {
                if(confirm("Are you sure?")){
                    HTTP({url:'contract/delete-section/'+id, method: 'GET'}).then(()=>{
                        this._getProjects();
                        this._getContract();
                        this.section = {};
                        this.showNewSection = false;
                        this._getDefaultSection();
                    }).catch();
                }
            },
            notifySub() {
                HTTP({url:'contract/notify-sub/'+this.project.subcontractor_id+'/'+this.project.id, method: 'GET'}).then(()=>{
                    this.$notify({
                    title: 'Success',
                    message: "Subcontractor notified",
                    type: 'success',
                    duration: 2500,
                    onClose: (() => {})
                });
                    }).catch();
            },
            showDialogOnEditMode(id) {
                this.editSection = true;
                this.section.section_id = id;
                this.showNewSection = true;
                // this._setNewSetionOnSelect();
                let sectionData = this.contractSetion.filter((item)=>{
                    return item.id == this.section.section_id;
                });
                // console.log("====>", sectionData);
                if (sectionData.length) {
                    this.section = sectionData[0];
                    this.section.section_id = sectionData[0].id;
                }
            },
            deleteContract() {
                if(confirm("Are you sure?")) {
                    HTTP({url:'contract/delete-contract/'+this.project.id, method: 'GET'}).then(()=>{
                    this.$router.go(-1);
                    }).catch();
                }
                
            }
        },
        computed: {
            projects: function() {
                return this.$store.getters.getProjects;
            },
            vendors: function() {
                return this.$store.getters.getVendors;
            },
            payModels: function() {
                return this.$store.getters.getPayModels;
            },
            payGroups: function() {
                return this.$store.getters.getPayGroups;
            },
            insuranceModels: function() {
                return this.$store.getters.getInsuranceModels;
            },
        },
        mounted() {
            this._getProjects();
            this._getVendors();
            this._getContract();
            this._getDefaultSection();
            if(typeof this.$route.params.id != 'undefined') {
                this.$store.dispatch(SELECT_PROJECT, this.$route.params.id).then((project) => {
                    this.project = JSON.parse(JSON.stringify(project)); //deep clone
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }
            else {
                this.$store.dispatch(GET_EMPTY_PROJECT).then(() => {
                    this.project = {};
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }

        }
    }
</script>

<style scoped>
  body{
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
    .el-button{
        margin:30px 0px;
    }
  .coi-card-row{
      border-radius: 8px 8px 0px 0px;
  }
</style>
