import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueSimpleMarkdown from 'vue-simple-markdown';
import Translate from "./components/common/Translate";
import VueCsvImport from 'vue-csv-import';
import VuePapaParse from 'vue-papa-parse';
import ToggleButton from 'vue-js-toggle-button';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/tailwind.css';
import '@/assets/css/common.css';
import 'vue-simple-markdown/dist/vue-simple-markdown.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft, faChessPawn, faExclamationTriangle, faCheck, faUserTie, faBuilding,
    faEnvelope, faAddressCard, faPhone, faArrowsAltV, faArrowUp, faArrowDown, faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
library.add(
    faArrowLeft, faChessPawn, faExclamationTriangle, faCheck, faUserTie, faBuilding,
    faEnvelope, faAddressCard, faPhone, faArrowsAltV, faArrowUp, faArrowDown, faEye
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueSimpleMarkdown);
Vue.use(VueCsvImport);
Vue.use(VuePapaParse);
Vue.use(ToggleButton);
Vue.component('translate', Translate);

Vue.use(Element, { locale });
Vue.config.productionTip = false;

Vue.filter('capitalize', function (value) {
  if(!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

