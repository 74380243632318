<template>
    <div>
        <div v-if = "showModal" class="bg-white shadow overflow-hidden sm:rounded-lg mb-5">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Insurance Verification Request
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    {{ policy.name }}
                </p>
            </div>
        </div>
        <!-- <PolicyData :policy = 'policy' v-if = "policy.agent_email"/> -->
        <!-- <div v-if="passwordCreated">
            <p>Click <a :href="`${title}/login`" style='color:blue'>here</a> to login</p>
        </div> -->
        <div class = 'wrapper mx-5 p-3'>
            <div  class="flex mb-5 p-2 ">
                <div class="w-2/6 flex">
                    <div>Password</div>
                </div>
                <div class="w-2/6">
                    <div class="subscript">
                        <input type="password" class = 'textarea mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' v-model="password">
                    </div>
                </div>
            </div>

            <div  class="flex mb-5 p-2 ">
                <div class="w-2/6 flex">
                    <div>Confirm Password</div>
                </div>
                <div class="w-2/6">
                    <div class="subscript">
                        <input type="password" class = 'textarea mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' v-model="confirm_password">
                    </div>
                </div>
            </div>
            <div  class=" w-full mb-5">
                <button v-on:click = 'saveData' class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right mr-3">Save</button>
                <!-- <router-link v-if = "showBack" :to = "{name: 'agent-dashboard'}">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right mr-3">Dashboard</button>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import PolicyData from "../agent/components/PolicyData";
    const clone = require('lodash/cloneDeep');
    import {GET_POLICY_FOR_INVITE} from "../../store/actions/policy";
    import {AGENT_SUBMITTED_POLICY, CREATE_AGENT, GET_AGENT} from "../../store/actions/agent";
    import {SAVE_USER} from "../../store/actions/user";
    export default {
        name: 'UserInvite',
        components: {
            // PolicyData, 
        },
        data() {
            return {
                policy: {
                    fields: {
                        current: []
                    },
                    verification: {
                        sub_contractor: {},
                    },
                },
                showModal: false,
                showErroDiv:false,
                agent_name: null,
                agent_password: null,
                title: process.env.VUE_APP_ROOT,
                password: '',
                confirm_password: '',
                paramsId:null,
                passwordCreated:false
            }

        },
        methods: {
            saveData() {
                if(!this.password.length) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Please enter password',
                        type: 'warning'
                    });
                    return;
                } else if (this.password != this.confirm_password) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Confirm password must be same as password',
                        type: 'warning'
                    });
                    return;
                }
                this.$store.dispatch(SAVE_USER,  {
                    password: this.password,
                    confirm_password: this.confirm_password,
                    user_id: this.paramsId
                }).then(() => {
                    this.$notify({
                        title: 'Success',
                        message: 'Your registration successfully now you can login and manage gc policies',
                        type: 'success'
                    });
                    this.passwordCreated = true;
                    localStorage.setItem('password-created', true);
                    window.location.href = this.title + "/login"
                //     setTimeout(function(){ 
                //         ; 
                //    }, 2000);
            //         this.$store.dispatch(GET_POLICY_FOR_INVITE, this.$route.params.inviteCode).then((policy) => {
            //         this.policy = clone(policy);
            //         this.$notify({
            //             title: 'Success',
            //             message: 'Your query submitted',
            //             type: 'success'
            //         });
            //         return;
            // }).catch(()=>{
            //     this.showErroDiv = true;
            // });
                })
                .catch();
            },
            showBack() {
                return this.$store.getters.getAgent && this.$store.getters.getAgent.id;
            },
            createAgent() {
                if(!this.agent_name.length || !this.agent_password.length) {
                    this.$notify({
                        title: 'Warning',
                        message: 'You need to enter your name and desired password',
                        type: 'warning'
                    });
                    return;
                }
                this.$store.dispatch(CREATE_AGENT, {
                    name: this.agent_name,
                    email: this.policy.agent_email,
                    password: this.agent_password
                }).then((agent) => {
                    this.$router.push('/agent?token=' + agent.user.api_token);
                })
                .catch();
            },
            getFieldsForForm(policy) {
                if(policy.fields.current.length) {
                    return policy.fields.current;
                }
                return policy.fields.draft;
            },
            handlePolicyFieldsSave() {
                this.$store.dispatch(AGENT_SUBMITTED_POLICY, {policyId: this.policy.id}).then(() => {
                    this.$store.dispatch(GET_AGENT, {email: this.policy.agent_email}).then((agent) => {
                        if(!agent) {
                            this.showModal = true;
                        } else {
                            if (agent.policies[0].status_group == "non-compliant") {
                                this.$notify({
                                title: 'Error',
                                message: 'Your client will not be qualified to work for this certificate holder.  Otherwise if you change it to the required answer we will accept it.',
                                type: 'error'
                            });
                            } else {
                                this.$notify({
                                title: 'Success',
                                message: 'Your policy data has been saved',
                                type: 'success'
                            });
                            }
                            
                            this.$router.push('/agent?token=' + agent.user.api_token);
                        }
                    }).catch();
                }).catch();
            }
        },
        mounted() {
            this.paramsId = this.$route.params.id;
            if (localStorage.getItem('password-created')) {
                this.passwordCreated = true;
            }
            this.$store.dispatch(GET_POLICY_FOR_INVITE, this.$route.params.inviteCode).then((policy) => {
                this.policy = clone(policy);
            }).catch(()=>{
                this.showErroDiv = true;
            });
        }
    }
</script>

<style scoped>
    .wrapper{
        background-color: #f1f4ff;
    }
    .file-select{
        width: 90%;
        background-color: #FFFFFF;
    }
    .textarea{
        width: 90%;
    }
</style>
