export const USER_INVITE = 'USER_INVITE';
export const SAVE_USER = 'SAVE_USER';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const SET_USERS_STATE = 'SET_USERS_STATE';
export const SELECT_USER = 'SELECT_USER';
export const SET_USER_FILTER = 'SET_USER_FILTER';
export const SET_USER_SORT = 'SET_USER_SORT';
export const HANDLE_USER_SAVE = 'HANDLE_USER_SAVE';
export const INIT_USERS = 'INIT_USERS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const DELETE_USER = 'DELETE_USER';
export const ALL_GC = 'ALL_GC';
export const SEARCH_GC = 'SEARCH_GC';
export const INIT_GC = 'INIT_GC';
export const USER = 'USER';
export const DASH_COUNT = 'DASH_COUNT';
//api/user/inviteUser