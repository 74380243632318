import {COMBINE_CERTIFICATES, SEARCH_CERTIFICATES} from "@/store/actions/report";
import HTTP from "@/utilities/http";

const state = {

}

const getters = {

}

let actions;

actions = {
    [SEARCH_CERTIFICATES]: (context, dateRange) => {
        return new Promise((resolve, reject) => {
            HTTP({
                url: 'report/certificates', method: 'GET', params: {
                    startDt: dateRange[0],
                    endDt: dateRange[1],
                }
            })
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    [COMBINE_CERTIFICATES]: (context, dateRange) => {
        return new Promise((resolve, reject) => {
            HTTP({
                url: 'report/export/certificates', method: 'GET', params: {
                    startDt: dateRange[0],
                    endDt: dateRange[1],
                },
                responseType: 'blob'
            })
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
};

const mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations,
}