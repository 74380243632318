<template>
    <div>
        <div v-if = '!edit' class = 'flex'>
            <div class = 'w-full'>
                <div class="flex mt-5">
                    <div class="w-1/5">
                        <label class = 'mr-2'>
                            Projects:
                        </label>
                        {{vendor.dash_count.project_count}}
                    </div>
                    <div class="w-1/5">
                        <label class = 'mr-2'>
                            Vendors:
                        </label>
                        {{vendor.dash_count.vendor_count}}
                    </div>
                    <div class="w-1/5">
                        <label class = 'mr-2'>
                            Policies:
                        </label>
                        {{vendor.dash_count.policy_count}}
                    </div>
                    <div class="w-1/5">
                        <label class = 'mr-2'>
                            Non-Compliant:
                        </label>
                        {{vendor.dash_count.risk}} %
                    </div> 
                    <div class="w-1/5 ml-5">
                        <label class = 'mr-2'>
                            Compliant:
                        </label>
                        {{vendor.dash_count.compliant}} %
                    </div>
                </div>
                
            </div>
            <!-- <div class = 'w-2/12'>
                <div class = 'flex mt-5'>
                    <div class=" w-full mb-3 h-10">
                        <el-button v-on:click = 'edit = true' type="primary" icon="el-icon-edit" circle class = 'float-right'></el-button>
                    </div>
                </div>
            </div> -->
        </div>
        <div v-else class = 'flex'>
            <div class = 'w-10/12'>
                <div class="flex mt-5">
                    <div class="w-full">
                        <label>Name</label>
                        <coi-input @keyup = "checkVendorName" v-model = 'vendorData.name'/>
                    </div>
                </div>
                <!-- <div v-if = possibleDuplicates.length class = 'flex my-5 px-3' style = 'background-color: rgba(187,82,82,0.22)'>
                    <div class = 'w-full'>
                        <div class = 'flex my-5'>
                            <div class = 'w-full font-bold'>
                                Possible Matches
                            </div>
                        </div>
                        <div v-for = "vendor in possibleDuplicates" :key = 'vendor.id' class = 'flex my-5'>
                            <div class = 'w-10/12'>
                                {{ vendor.name }} {{ vendor.city }} {{ vendor.state }}
                                <br/>
                                {{ vendor.primary_contact }}
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class = 'flex mt-5'>
                    <div class="w-1/2">
                        <label>
                            Address
                        </label>
                        <CoiInput v-model = 'vendorData.address'/>
                    </div>
                    <div class="w-1/2">
                        <label>
                            City
                        </label>
                        <CoiInput v-model = 'vendorData.city'/>
                    </div>
                </div> -->
                <!-- <div class = 'flex mt-5'>
                    <div class="w-1/2">
                        <label>
                            State
                        </label>
                        <select class="mb-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" @change = "changeValue" v-model = 'vendorData.state'>
            
                                                                                                    <option value="AK" selected="">Alaska</option>
                                                                                                    <option value="AZ">Arizona</option>
                                                                                                    <option value="AR">Arkansas</option>
                                                                                                    <option value="CA">California</option>
                                                                                                    <option value="CO">Colorado</option>
                                                                                                    <option value="CT">Connecticut</option>
                                                                                                    <option value="DE">Delaware</option>
                                                                                                    <option value="DC">District of Columbia</option>
                                                                                                    <option value="FL">Florida</option>
                                                                                                    <option value="GA">Georgia</option>
                                                                                                    <option value="HI">Hawaii</option>
                                                                                                    <option value="ID">Idaho</option>
                                                                                                    <option value="IL">Illinois</option>
                                                                                                    <option value="IN">Indiana</option>
                                                                                                    <option value="IA">Iowa</option>
                                                                                                    <option value="KS">Kansas</option>
                                                                                                    <option value="KY">Kentucky</option>
                                                                                                    <option value="LA">Louisiana</option>
                                                                                                    <option value="ME">Maine</option>
                                                                                                    <option value="MD">Maryland</option>
                                                                                                    <option value="MA">Massachusetts</option>
                                                                                                    <option value="MI">Michigan</option>
                                                                                                    <option value="MN">Minnesota</option>
                                                                                                    <option value="MS">Mississippi</option>
                                                                                                    <option value="MO">Missouri</option>
                                                                                                    <option value="MT">Montana</option>
                                                                                                    <option value="NE">Nebraska</option>
                                                                                                    <option value="NV">Nevada</option>
                                                                                                    <option value="NH">New Hampshire</option>
                                                                                                    <option value="NJ">New Jersey</option>
                                                                                                    <option value="NM">New Mexico</option>
                                                                                                    <option value="NY">New York</option>
                                                                                                    <option value="NC">North Carolina</option>
                                                                                                    <option value="ND">North Dakota</option>
                                                                                                    <option value="OH">Ohio</option>
                                                                                                    <option value="OK">Oklahoma</option>
                                                                                                    <option value="OR">Oregon</option>
                                                                                                    <option value="PA">Pennsylvania</option>
                                                                                                    <option value="RI">Rhode Island</option>
                                                                                                    <option value="SC">South Carolina</option>
                                                                                                    <option value="SD">South Dakota</option>
                                                                                                    <option value="TN">Tennessee</option>
                                                                                                    <option value="TX">Texas</option>
                                                                                                    <option value="UT">Utah</option>
                                                                                                    <option value="VT">Vermont</option>
                                                                                                    <option value="VI">Virgin Islands</option>
                                                                                                    <option value="VA">Virginia</option>
                                                                                                    <option value="WA">Washington</option>
                                                                                                    <option value="WV">West Virginia</option>
                                                                                                    <option value="WI">Wisconsin</option>
                                                                                                    <option value="WY">Wyoming</option>
        </select>

                        <CoiInput v-model = 'vendorData.state'/>
                    </div> -->
                    <!-- <div class="w-1/2">
                        <label>
                            Zip
                        </label>
                        <CoiInput v-model = 'vendorData.zip'/>
                    </div> 
                </div>-->
                <div class = 'flex mt-5'>
                    <!-- <div class="w-1/2">
                        <label>
                            Primary Contact
                        </label>
                        <CoiInput v-model = 'vendorData.primary_contact'/>
                    </div> -->
                    <div class="w-full">
                        <label>
                            Email
                        </label>
                        <CoiInput v-model = 'vendorData.email'/>
                    </div>
                </div>
                <!-- <div class = 'flex my-5'>
                    <div class="w-1/2">
                        <label>
                            Primary Phone
                        </label>
                        <CoiInput v-model = 'vendorData.phone'/>
                    </div>
                    <div class="w-1/2">
                        <label>
                            EIN
                        </label>
                        <CoiInput v-model = 'vendorData.ein'/>
                    </div>
                </div> -->
                <!-- <div class = 'flex my-5'>
                    <div class="w-1/2">
                        <label>
                            Insurance Emails
                        </label>
                        <CoiInput v-model = 'vendorData.insurance_email'/>
                    </div>

                    <div class="w-1/2">
                        <label>
                            Vendor Id
                        </label>
                        <CoiInput v-model = 'vendorData.vendor_id'/>
                    </div>
                </div> -->
            </div>
            <div class = 'w-2/12'>
                <div class = 'flex mt-5'>
                    <div class=" w-full mb-3 h-10">
                        <el-button v-on:click = 'cancel' type="danger" icon="el-icon-close" circle class = 'float-right'></el-button>
                        <el-button v-on:click = 'saveData' type="success" icon="el-icon-check" circle class = 'float-right mr-3'></el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HTTP from "../../../utilities/http";
    import CoiInput from "../../common/CoiInput";
    import Validator from "../../common/Validator";
    const clone = require('lodash/cloneDeep');
    export default {
        name: 'UserData',
        props: ['vendor', 'dialog'],
        components: {
            CoiInput,
        },
        data() {
            return {
                edit: clone(this.dialog),
                vendorData: clone(this.vendor),
                possibleDuplicates: [],
            }
        },
        methods: {
            checkVendorName() {
                const searchTerm = this.vendorData.name;
                if(searchTerm.length > 1) {
                    const vendorId = this.vendorData.id ? this.vendorData.id : null;
                    HTTP({url: 'vendor/search/', method: 'POST', data: {searchTerm, vendorId}})
                        .then(resp => {
                            this.possibleDuplicates = resp.data;
                        })
                        .catch(err => {
                            this.$notify({
                                title: 'Error',
                                message: err.response.data,
                                type: 'error'
                            });
                        });
                }
            },

            changeValue() {
                // console.log(this.vendorData.state);
                // this.vendorData.state = this.vendorData.select
            },

            cancel() {
                if(this.dialog) {
                    this.$emit('vendor-close-dialog');
                }
                this.edit = false
            },
            saveData() {
                if(!Validator([
                    {field: this.vendorData.name, name: 'Name'},
                    // {field: this.vendorData.primary_contact, name: 'Primary Contact'},
                    {field: this.vendorData.email, name: 'Primary Email'},
                ])) {
                    return;
                }

                HTTP({url: 'user/inviteUser/', method: 'POST', data: this.vendorData})
                    .then(resp => {
                        this.$notify({
                            title: 'Success',
                            message: 'Invitation sent successfully',
                            type: 'success',
                            duration: 2500,
                        });
                        this.$emit('vendor-data-saved', resp.data.vendor);
                        if(this.dialog) {
                            this.$emit('vendor-close-dialog');
                        }
                        this.edit = false;
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
        },
    }
</script>
<style scoped>
    select{
        width: 90%;
    }
</style>
