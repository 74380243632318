<template>
    <div>
        <div class="header flex">
            <div v-on:click = 'goBack' class="back-button flex">
                <div class="arrow"><font-awesome-icon icon = 'arrow-left'></font-awesome-icon></div>
                <div style = 'padding-top: 9px; padding-left: 5px;'>Back</div>
            </div>
        </div>
        <div class="coi-card mt-5" >
            <div class="header_row_1 flex">
                <div class = 'fa-icon'><font-awesome-icon icon = 'chess-pawn'></font-awesome-icon></div>
                <div class="w-4/12">
                    <h2>{{ policy.name}}</h2>
                </div>
                <div class="w-2/12">
                    <div v-if="policy.expiration">
                        <div>{{policy.formattedExpiration}}</div>
                        <div class="subscript"><translate source = 'Expiration'/></div>
                    </div>
                </div>
                <div class="w-2/12">
                    {{ policy.status_group | capitalize}}
                </div>
                <div class = 'w-2/12'>
                    <PolicyReceive
                                    v-if = "policy.status_group == 'agent-submitted' || policy.status_group == 'does-not-meet-requirements' || policy.status_group =='non-compliant' || policy.status_group =='resubmitted-meets-req'"
                                    :id = "policy.id"
                                    v-on:policy-received = "handlePolicyReceive"
                                    class = 'mr-3 float-right'/>
                    <!-- <PolicyReceive
                            :id = "policy.id"
                            v-on:policy-received = "handlePolicyReceive"
                            class = 'mr-3 float-right'/> -->
                    <PolicyNote
                            :id = "policy.id"
                            v-on:note-saved = "handleNoteSave"
                            class = 'mr-3 float-right' />
                </div>
                <div class="w-2/12">
                    <div class="dropdown">
                        <button class="dropbtn">Actions</button>
                        <div class="dropdown-content">
                            <a href="javascript:void(0)" v-on:click = "makeInactive(policy.id)" v-if = "policy.is_active">De-Activate</a>
                            <a href="javascript:void(0)"  v-if = "!policy.is_active" v-on:click = "makeActive(policy.id)">Activate</a>
                            <a href="javascript:void(0)"  v-on:click = "makePause(policy.id)">Pause</a>
                            <a href="javascript:void(0)"  v-on:click = "makeNonEligible(policy.id)">Non eligible</a>
                            <a href="javascript:void(0)"  v-on:click = "copyLink(policy.invitation_code)">Invitation Link</a>
                        </div>
                    </div>
                </div>
            </div>
            <PolicyData
                    :policy = 'policy'
                    v-on:policy-data-saved = 'handlePolicyDataSave'
            />
            <PolicyFields
                    v-if = 'policy.fields.current.length'
                    :fields = 'policy.fields.current'
                    :associated-files="policy.fields.associatedFiles"
                    :editable="true"
                    :policy-id="policy.id"
                    v-on:policy-fields-saved = "handlePolicyFieldsSave"
            />
            <div class = 'flex'>
                <h2 class = 'px-5 py-10'>
                    <translate source = 'Associated Policies'/>
                </h2>
            </div>

            <el-collapse v-model = 'selectedPolicies'>
                <el-collapse-item
                        v-for = "(associatedPolicy, index_b) in policy.associatedPolicies"
                        :name = index_b
                        :title = associatedPolicy.name
                        class = 'p-5'
                        :key = 'index_b'>
                    <div class="header_row_1 flex">
                        <div class = 'fa-icon'><font-awesome-icon icon = 'chess-pawn'></font-awesome-icon></div>
                        <div class="w-4/12">
                            <h2>{{ associatedPolicy.name}}</h2>
                        </div>
                        <div class="w-2/12" v-if="associatedPolicy.expiration">
                            <div>{{associatedPolicy.expiration}}</div>
                            <div class="subscript"><translate source = 'Expiration'/></div>
                        </div>
                        <div class="w-2/12">
                            {{ associatedPolicy.status_group | capitalize}}
                        </div>
                        <div class = 'w-2/12'>
                            <PolicyReceive
                                    v-if = "associatedPolicy.status_group == 'agent-submitted' || associatedPolicy.status_group == 'does-not-meet-requirements' || associatedPolicy.status_group =='non-compliant' || associatedPolicy.status_group =='resubmitted-meets-req'"
                                    :id = "associatedPolicy.id"
                                    v-on:policy-received = "handlePolicyReceive"
                                    class = 'mr-3 float-right'/>
                            <!-- <PolicyReceive
                                    :id = "associatedPolicy.id"
                                    v-on:policy-received = "handlePolicyReceive"
                                    class = 'mr-3 float-right'/> -->
                            <PolicyNote
                                    :id = "associatedPolicy.id"
                                    v-on:note-saved = "handleNoteSave"
                                    class = 'mr-3 float-right' />

                        </div>
                        <div class="w-2/12">
                            <div class="dropdown">
                                <button class="dropbtn">Actions</button>
                                <div class="dropdown-content">
                                    <a href="javascript:void(0)" v-on:click = "makeInactive(policy.id)" v-if = "policy.is_active">De-Activate</a>
                                    <a href="javascript:void(0)"  v-if = "!policy.is_active" v-on:click = "makeActive(policy.id)">Activate</a>
                                    <a href="javascript:void(0)"  v-on:click = "makePause(policy.id)">Pause</a>
                                    <a href="javascript:void(0)"  v-on:click = "makeNonEligible(policy.id)">Non eligible</a>
                                    <!-- <a href="javascript:void(0)"  v-on:click = "copyLink(policy.invitation_code)">Invitation Link</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <PolicyData :policy = 'associatedPolicy' v-on:policy-data-saved = 'handlePolicyDataSave'/>
                    <PolicyFields
                            v-if = 'associatedPolicy.fields.current.length'
                            :fields = 'associatedPolicy.fields.current'
                            :associated-files="associatedPolicy.fields.associatedFiles"
                            :editable="true"
                            :policy-id="associatedPolicy.id"
                            v-on:policy-fields-saved = "handlePolicyFieldsSave"
                    />
                </el-collapse-item>
            </el-collapse>
            <div class = 'flex'>
                <h2 class = 'px-5 py-10'>
                    <translate source = 'Policy Logs Status'/>
                </h2>
            </div>
            <el-collapse v-model = "activeNames">
                <el-collapse-item
                        v-for = "status in statusLog"
                        :key = "status.id"
                        :title = "`Updated Date: ${status.updated_at?status.updated_at:'NA'}, Status: ${status.status_group?status.status_group:'NA'}, Agent Email:${status.agent_email?status.agent_email:'NA'}, Sub Email:${status.sub_email?status.sub_email:'NA'}, User that made change:${status.user?status.user:'NA'}, User IP: ${status.user_ip?status.user_ip:'NA'}, GC_NOTES: ${status.notes} `"
                        :name = "status.id"  class="p-5">
                    <PolicyFields
                            :fields = 'status.fields'
                            :editable = "false"
                            :policyId = "policy.id"
                            :policy-fields-saved = "handlePolicyFieldsSave"/>
                </el-collapse-item>
            </el-collapse>
            <div class = 'flex'>
                <h2 class = 'px-5 py-10'>
                    <translate source = 'Policy History'/>
                </h2>
            </div>
            <el-collapse v-model = "activeNames">
                <el-collapse-item
                        v-for = "(fieldGroup, groupIndex, index_a) in policy.fields.historical"
                        :key = "groupIndex"
                        :title = "`Updated Date: ${fieldGroup.label} Email: ${fieldGroup.email}`"
                        :name = "index_a"  class="p-5">
                    <PolicyFields
                            :fields = 'fieldGroup.fields'
                            :editable = "false"
                            :policyId = "policy.id"
                            :policy-fields-saved = "handlePolicyFieldsSave"/>
                </el-collapse-item>
            </el-collapse>
		<div class = 'flex'>
                <h2 class = 'px-5 py-10'>
                    <translate source = 'Event Notifications'/>
                </h2>
            </div>
            <el-collapse v-model = "eventNotification">
                <el-collapse-item
                        v-for="data in ticklerHistory" :key="data.id"
                        :title = "`Event Date: ${data.tickler_date}`"
                        :name = "data.id"  class="p-5">
                        <div class="flex w-12/12">
                            <div class="w-4/12">{{data.tickler_type}}</div>
                            <div class="w-4/12">{{data.email}}</div>
                            <div class="w-4/12">{{data.tickler_date}}</div>
                        </div>
                </el-collapse-item>
                <div></div>
            </el-collapse>
            <div class="footer">
            </div>
        </div>
    </div>
</template>

<script>
    import {SELECT_POLICY,ACTIVATE_POLICY, DEACTIVATE_POLICY, PAUSE_POLICY, NON_ELIGIBLE, GET_TICKLER_HISTORY} from '../../../store/actions/policy';
    import PolicyNote from "./components/PolicyNote";
    import PolicyReceive from "./components/PolicyReceive";
    import PolicyData from "./components/PolicyData";
    import PolicyFields from "./components/PolicyFields";
    import HTTP from "../../../utilities/http";
    export default {
        name: 'Policy',
        components: {
            PolicyNote, PolicyReceive, PolicyData, PolicyFields
        },
        data() {
            return {
                activeNames: ['1'],
                selectedPolicies: ['1'],
		eventNotification: ['1'],
                edit: false,
		ticklerHistory:[],
        statusLog:[]
            }
        },
        methods: {
            handlePolicyFieldsSave() {
                this.$store.dispatch(SELECT_POLICY, this.policy.id).then(() => {
                    HTTP({url: 'policy/get-changeset/'+this.$route.params.id, method: 'GET'})
                    .then(resp => {
                        this.statusLog = resp.data;
                    })
                    .catch();
                }).catch();
            },
            handleNoteSave() {
                this.$store.dispatch(SELECT_POLICY, this.policy.id).then(() => {
                    HTTP({url: 'policy/get-changeset/'+this.$route.params.id, method: 'GET'})
                    .then(resp => {
                        this.statusLog = resp.data;
                    })
                    .catch();
                }).catch();
            },
            handlePolicyReceive() {
                this.$store.dispatch(SELECT_POLICY, this.policy.id).then(() => {
                    HTTP({url: 'policy/get-changeset/'+this.$route.params.id, method: 'GET'})
                    .then(resp => {
                        this.statusLog = resp.data;
                        this.goBackPolicies(); 
                    })
                    .catch();
                }).catch();
            },
            handlePolicyDataSave() {
                this.$store.dispatch(SELECT_POLICY, this.policy.id).then(() => {
                    HTTP({url: 'policy/get-changeset/'+this.$route.params.id, method: 'GET'})
                    .then(resp => {
                        this.statusLog = resp.data;
                    })
                    .catch();
                }).catch();
            },
            goBackPolicies() {
                this.$router.push('/dashboard/policies');
            },
            goBack() {
                this.$router.go(-1);
            },    
            makeInactive(id) {
                if (confirm("Are you sure that you want to deactivate policy?")){
                    this.$store.dispatch(DEACTIVATE_POLICY, id).then(() => {
                        this.$notify({
                            title: 'Success',
                            message: 'The policy has been deactivated',
                            type: 'success',
                            duration: 2500
                        });
                    });
                }
            },
            makeActive(id) {
                if (confirm("Are you sure that you want to activate policy?")){
                    this.$store.dispatch(ACTIVATE_POLICY, id).then(() => {
                        this.search();
                        this.$notify({
                            title: 'Success',
                            message: 'The policy has been activated',
                            type: 'success',
                            duration: 2500
                        });
                    });
                }
            },
            makePause(id) {
                if (confirm("Are you sure that you want to pause this policy?")){
                    this.$store.dispatch(PAUSE_POLICY, id).then(() => {
                        this.$store.dispatch(SELECT_POLICY, this.$route.params.id).then(() => {
                        this.$notify({
                            title: 'Success',
                            message: 'The policy has been paused',
                            type: 'success',
                            duration: 2500
                        });
                        }).catch();
                    });
                }
            },
            makeNonEligible(id) {
                if (confirm("Are you sure that you want to marked as non-eligible this policy?")){
                    this.$store.dispatch(NON_ELIGIBLE, id).then(() => {
                        this.$store.dispatch(SELECT_POLICY, this.$route.params.id).then(() => {
                            this.$notify({
                            title: 'Success',
                            message: 'The policy has non eligabled',
                            type: 'success',
                            duration: 2500
                        });
            }).catch();
                    });
                }
            },
            getTicklerHistory() {
                this.$store.dispatch(GET_TICKLER_HISTORY, this.$route.params.id).then((rs) => {
                this.ticklerHistory = rs
                    }).catch();
            },
            // add link to copy
            copyLink(invitation_code) {
                    let copyText = window.location.protocol + "//" + window.location.host + '/agent/invite/'+invitation_code;
                    var input = document.body.appendChild(document.createElement("input"));
                    input.value = copyText;
                    input.focus();
                    input.select();
                    document.execCommand('copy');
                    input.parentNode.removeChild(input);
                    this.$notify({
                                title: 'Success',
                                message: 'Invitation link copied successfully!!',
                                type: 'success'
                            });
            }  
        },
        computed: {
            policy: function() {
                return this.$store.getters.getPolicy;
            },
        },
        mounted() {
            this.$store.dispatch(SELECT_POLICY, this.$route.params.id).then(() => {
            }).catch();
		this.getTicklerHistory();
        HTTP({url: 'policy/get-changeset/'+this.$route.params.id, method: 'GET'})
                    .then(resp => {
                        this.statusLog = resp.data;
                    })
                    .catch();
        }
    }
</script>

<style scoped>
    h2{
        font-size: 1.5rem;
    }
    .button{
        height: 50px;
    }
   .header  .red .tickle-button{
      color: #fb0606;
      border: 2px solid #fb0606;
  }
  .header_row_1{
      width: 100%;
      font-size: 16px;
      padding: 15px 33px;
  }
  .header_row_1 h2{
      color: #255897;
      font-size: 18px;
  }
  .header_row_1 .header-item{
      margin-right: 50px;
  }
  .header_row_3{
      margin: 33px 25px 15px 25px;
      border-bottom: 1px solid #e3e8ff;
  }
  .header_row_3 h3{
      color: #255897;
      font-size: 20px;
      line-height: 30px;
      margin-left: 60px;
  }
  .header_row_2{
      background-color: #f1f4ff;
      padding: 15px;
  }
  .fields-list{
      margin: 0 25px;
      font-size: 16px;
      text-align: center;
  }
  .fields-list .subscript{
      font-size: 12px;
  }
  .details-col{
      margin: 0 50px;
  }
  .coi-card-row{
      margin: 12px 0px;
      padding: 12px;
  }
  .data-row{
      padding: 12px;
  }
  @media (max-width: 767px){
      .details-col{
          justify-content: center;
          text-align: center;
          padding: 0 20px 12px;
          border-bottom: 2px solid #566cd3;
      }
  }
  .footer{
      margin-top: 40px;
      width:100%;
      border-bottom: 4px solid #566cd3;
  }
  .view{
      text-align: end;
  }
</style>
