<template>
    <div class = 'mb-10'>
        <div class="header flex">
            <div v-on:click = 'goBack' class="back-button flex">
                <div class="arrow"><font-awesome-icon icon = 'arrow-left'></font-awesome-icon></div>
                <div style = 'padding-top: 9px; padding-left: 5px;'>Back</div>
            </div>
        </div>
        <div class="coi-card">
            <div class="coi-card-border">
                <div class = 'flex my-3'>
                    <div class = 'w-full'>
                        <h3 class = 'text-xl'>New Verification</h3>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 form-item'>
                        <div class = 'flex'>
                            <div class = 'w-1/2'>
                                <label>Vendor</label>
                            </div>
                            <div class = 'w-1/2'>
                                <el-button
                                        v-on:click = 'showNewVendor = true'
                                        type = 'success'
                                        size = 'mini'
                                        class = 'mb-1 float-right'>New Vendor</el-button>
                            </div>
                        </div>
                        <div class = 'flex'>
                            <div class = 'w-full'>
                                <v-select v-model = 'selectedVendor'
                                          :options = 'vendors'
                                          label = 'name'
                                          ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class = 'w-2/4 form-item'>
                        <label>
                            Expiration (5pm PST)
                        </label>
                        <datepicker
                                v-model="expiration"
                                format = 'M/dd/yyyy'
                                input-class = 'datepicker-input mb-1 shadow appearance-none border rounded py-2 px-3 mt-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        >
                        </datepicker>
                    </div>
                </div>
                <div class = 'flex my-3'>
                    <div class = 'w-full'>
                        <h4 class = 'text-lg'>
                            Policies to verify
                        </h4>
                    </div>
                </div>
                <div v-for = "template in policyTemplates" :key = 'template.id' class = 'flex my-3'>
                    <div class = 'w-1/6'>
                        <el-checkbox v-model = "selectedTemplates[template.id]"></el-checkbox>
                    </div>
                    <div class = 'w-5/6'>
                        {{ template.name}}
                    </div>
                </div>
                <div class = 'flex my-10'>
                    <div class = 'w-full'>
                        <el-button
                                v-on:click="saveForm"
                                type = 'primary'>Send Verification</el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
                title = 'New Vendor'
                :visible.sync = 'showNewVendor'>
            <VendorData
                    :vendor = "{}"
                    :dialog = true
                    v-on:vendor-data-saved = 'handleVendorDataSave'
                    v-on:vendor-close-dialog = 'handleVendorCloseDialog' />
        </el-dialog>

        <!-- <el-dialog
                title = 'New Vendor'
                :visible.sync = "showNewVendor"
                width = "70%"
        >
            <div class = 'flex'>
                <div class = 'w-1/2'>
                    <label>
                        Company Name
                    </label>
                    <CoiInput @keyup="checkVendorName" v-model = 'newVendor.name'></CoiInput>
                </div>
                <div class = 'w-1/2'>
                    <label>
                        Contact Name
                    </label>
                    <CoiInput v-model = 'newVendor.contactName'></CoiInput>
                </div>
            </div>
            <div class = 'flex'>
                <div class = 'w-1/2'>
                    <label>
                        Contact Email
                    </label>
                    <CoiInput v-model = 'newVendor.contactEmail'></CoiInput>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showNewVendor = false">Cancel</el-button>
                <el-button type="primary" @click="saveNewVendor">Save</el-button>
            </span>
        </el-dialog> -->

    </div>
</template>

<script>
    import Datepicker from "vuejs-datepicker";
    import {SAVE_NEW_VENDOR, SEND_VERIFICATION} from "../../../store/actions/verification";
    import {GET_VENDORS_ALL, HANDLE_VENDOR_SAVE} from "../../../store/actions/vendor";
    // import CoiInput from "../../common/CoiInput";
    import Validator from "../../common/Validator";
    import VSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';
    import {SELECT_PROJECT} from "@/store/actions/project";
    import {UTILITY_GET_DASHBOARD_COUNTS} from "@/store/actions/utility";
    import VendorData from "../vendor/components/VendorData"
    export default {
        name: 'VerificationNew',
        components: {
            Datepicker, VSelect, VendorData
        },
        data() {
            return {
                form: {},
                selectedVendor: null,
                expiration: null,
                selectedTemplates: [],
                showNewVendor: false,
		invitation_code:'',
                newVendor: {
                    name: '',
                    contactName: '',
                    contactEmail: ''
                }
            }
        },
        methods: {
            handleVendorCloseDialog() {
                this.showNewVendor = false;
		this.$store.dispatch(GET_VENDORS_ALL).then((data) => {
                    let sortedVendors = data.data.sort((a, b) => {
                        return b.id - a.id;
                    });
                    this.selectedVendor = sortedVendors[0];
                }).catch();
            },
            handleVendorDataSave(vendor) {
                this.$store.dispatch(HANDLE_VENDOR_SAVE, vendor);
            },
            checkVendorName() {
                // console.log(this.vendors);
                // console.log(this.newVendor.name);
            },
            saveNewVendor() {
                if(!Validator([
                    {field: this.newVendor.name, name: 'Name'},
                    {field: this.newVendor.contactName, name: 'Contact Name'},
                    {field: this.newVendor.contactEmail, name: 'Contact Email'},
                ])) {
                    return;
                }
                this.$store.dispatch(SAVE_NEW_VENDOR, this.newVendor).then(() => {
                    this.$store.dispatch(GET_VENDORS_ALL, this.$store.getters.getProject.id).then().catch();
                    this.showNewVendor = false;
                    this.$notify({
                        title: 'Success',
                        message: 'The vendor has been added',
                        type: 'success',
                        duration: 2500,
                        onClose: (() => {

                        })
                    });
                }).catch();
            },
            goBack() {
                this.$router.go(-1);
            },
            saveForm() {
                if(!Validator([
                    {field: this.selectedVendor, name: 'Vendor'},
                    {field: this.expiration, name: 'Expiration'},
                ])) {
                    return;
                }
                let hasTemplate = false;
                this.selectedTemplates.forEach(template => {
                    if(template) {
                        hasTemplate = true;
                    }
                });
                if(!hasTemplate) {
                    this.$notify({
                        title: 'Missing Information',
                        message: 'Please select a policy',
                        type: 'warning',
                        duration: 2500
                    });
                    return;
                }
                let data = {
                    vendorId: this.selectedVendor.id,
                    expiration: this.expiration,
                    templates: this.selectedTemplates,
                    projectId: this.$store.getters.getProject.id,
                };
		this.$store.dispatch(SEND_VERIFICATION, data).then((res) => {
                    this.invitation_code = res.data.invitation_code
                    this.$store.dispatch(UTILITY_GET_DASHBOARD_COUNTS).then(() => {
                        this.$notify({
                            title: 'Success',
                            message: 'The verification request has been sent',
                            type: 'success',
                            duration: 2500,
                            onClose: (() => {
				this.$router.push('/dashboard/verification/agent/'+this.invitation_code);
                            })
                        });
                    })
                }).catch();
            }
        },
        computed: {
            vendors: function() {
                return this.$store.getters.getVendorsAll;
            },
            policyTemplates: function() {
                return this.$store.getters.getUser.templates;
            }
        },
        mounted() {
            if(this.$route.params.projectId) {
                this.$store.dispatch(SELECT_PROJECT, this.$route.params.projectId).then(() => {
                    this.$store.dispatch(GET_VENDORS_ALL, this.$store.getters.getProject.id).then().catch();
                    this.$store.getters.getUser.templates.forEach(template => {
                        this.selectedTemplates[template.id] = false;
                    });
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            } else {
                this.$store.dispatch(GET_VENDORS_ALL, this.$store.getters.getProject.id).then().catch();
                this.$store.getters.getUser.templates.forEach(template => {
                    this.selectedTemplates[template.id] = false;
                });
            }
        }
    }
</script>

<style scoped>
.el-checkbox__inner {
    border: 1px solid black;
}
  body{
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
    .coi-card, .coi-card-border{
        min-height: 500px
    }


</style>
