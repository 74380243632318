<template>
    <div>
        <el-button
                size = "mini"
                type = "success"
                v-on:click = "receive">
            <translate source = 'Receive'/>
        </el-button>
    </div>
</template>

<script>
  import {ACCEPT_POLICY} from "../../../../store/actions/policy";
  export default {
    name: 'PolicyReceive',
    props: ['id'],
    data() {
      return {
      }
    },
    methods: {
        goBackPolicies() {
                this.$router.push('/dashboard/policies');
            },
        receive() {
            this.$store.dispatch(ACCEPT_POLICY, this.id).then(() => {
                this.$notify({
                    title: 'Success',
                    message: "This policy has been received",
                    type: 'success',
                    duration: 2500,
                    onClose: (() => {})
                });
                this.$emit('policy-received');
                this.goBackPolicies(); // go back to policies dashboard
            }).catch(err => {
                this.$notify({
                    title: 'Error',
                    message: err.response.data,
                    type: 'error'
                });
            });
        },

    },
  }
</script>
<style scoped>

</style>
