<template>
    <div>
        <div class="header flex">
            <div class="w-1/6">
                <div v-on:click = "back" class="back-button flex">
                    <div class="arrow">&#x2B60;</div>
                    <div>Back to contract</div>
                </div>
            </div>
        </div>
        <div class="coi-card">
            <div class="coi-card-border">
                <div class="coi-card-row flex">
                    <h1 v-if = 'project.id'>Editing: {{project.title}}</h1>
                    <h1 v-else>Create a contract</h1>
                </div>
                <div class = 'flex'>
                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Project
                            </label>
                        </div>
                        <el-select v-model = 'project.project_id' clearable placeholder = "Select One" class="formctrl">
                            <el-option
                                    v-for = 'item in projects'
                                    :key = 'item.id'
                                    :label = 'item.title'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                    <div class = 'w-2/4 flex-col form-item'>
                        <div>
                            <label>
                                Subcontractor
                            </label>
                        </div>
                        <el-select v-model = 'project.subcontractor_id' clearable placeholder = "Select One" class="formctrl">
                            <el-option
                                    v-for = 'item in vendors'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div>
                <div class = 'flex'>
                    <div class = 'w-full form-item'>
                        <label>
                            Contract title
                        </label>
                        <el-input class="formctrl"
                                type = 'input'
                                placeholder = "Enter title"
                                v-model = 'project.title'></el-input>
                    </div>
                </div>
                <!-- <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>Start Date</label>
                        </div>
                        <el-date-picker class="formctrl w-full"
                                        v-model = 'project.start'
                                        type = 'date'
                                        format = 'MM/dd/yyyy'
                                        value-format = 'MM/dd/yyyy'
                                        placeholder = 'Select start date'></el-date-picker>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>End Date</label>
                        </div>
                        <el-date-picker class="formctrl"
                                        v-model = 'project.end'
                                        type = 'date'
                                        format = 'MM/dd/yyyy'
                                        value-format = 'MM/dd/yyyy'
                                        placeholder = 'Select end date'></el-date-picker>
                    </div>
                </div> -->
                <!-- <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Location
                            </label>
                        </div>
                        <el-input
                                placeholder = "Project Location"
                                v-model = "project.location"
                                clearable></el-input>
                    </div>
                    <div v-if = 'hasPayModels' class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Pay Group
                            </label>
                        </div>
                        <el-select v-model = 'project.pay_group_id' clearable placeholder = "Select One If Needed" class="formctrl">
                            <el-option
                                    v-for = 'item in payGroups'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div v-if = 'hasPayModels' class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Pay Model
                            </label>
                        </div>
                        <el-select v-model = 'project.pay_model_id' clearable placeholder = "Select One If Needed" class="formctrl">
                            <el-option
                                    v-for = 'item in payModels'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                Insurance Model
                            </label>
                        </div>
                        <el-select v-model = 'project.insurance_model_id' clearable placeholder = "Select One If Needed" class="formctrl">
                            <el-option
                                    v-for = 'item in insuranceModels'
                                    :key = 'item.id'
                                    :label = 'item.name'
                                    :value = 'item.id'></el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div class = 'flex'>
                    <div class = 'w-1/2 flex-col form-item'>
                        <div>
                            <label>
                                External Key
                            </label>
                        </div>
                        <el-input class="formctrl"
                                placeholder = "External Key"
                                v-model = "project.external_key"
                                clearable></el-input>
                    </div>
                    <div class = 'w-1/2 flex-col form-item'>
                    </div>
                </div> -->
                <div class = 'flex'>
                    <div class = 'w-full'>
                        <el-button
                                v-on:click="saveForm"
                                type = 'primary'>Save</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SELECT_PROJECT, GET_PROJECTS, GET_EMPTY_PROJECT, INIT_PROJECTS} from "../../../store/actions/project";
    import {
        INIT_VENDORS,
    } from "../../../store/actions/vendor";
    import HTTP from "../../../utilities/http";
    export default {
        name: 'ContractEdit',
        data() {
            return {
                activeOptions: [{
                    value: 1,
                    label: 'Active'
                }, {
                    value: 0,
                    label: 'Archived'
                }],
                project: {},
            }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            hasPayModels() {
                return this.$store.getters.getUser.pay_models
            },
            saveForm() {
                HTTP({url: 'contract/create/contract', method: 'POST', data: this.project})
                    .then(()=>{
                        this.$store.dispatch(GET_PROJECTS);
                        this.$notify({
                            title: 'Success',
                            message: "Contract created successfully",
                            type: 'success',
                            duration: 2500,
                            onClose: (() => {
                                this.$router.go(-1);
                            })
                        });
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                    });
            },
            _getProjects() {
                this.$store.dispatch(INIT_PROJECTS).then(() => {
                }).catch();
            },
            _getVendors() {
                this.$store.dispatch(INIT_VENDORS).then(() => {}).catch();
            },
        },
        computed: {
            projects: function() {
                return this.$store.getters.getProjects;
            },
            vendors: function() {
                return this.$store.getters.getVendors;
            },
            payModels: function() {
                return this.$store.getters.getPayModels;
            },
            payGroups: function() {
                return this.$store.getters.getPayGroups;
            },
            insuranceModels: function() {
                return this.$store.getters.getInsuranceModels;
            }
        },
        mounted() {
            this._getProjects();
            this._getVendors();
            if(typeof this.$route.params.id != 'undefined') {
                this.$store.dispatch(SELECT_PROJECT, this.$route.params.id).then((project) => {
                    this.project = JSON.parse(JSON.stringify(project)); //deep clone
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }
            else {
                this.$store.dispatch(GET_EMPTY_PROJECT).then(() => {
                    this.project = {};
                }).catch(()=> {
                    this.$store.dispatch('NOTIFY_ERROR', 'There was a problem fetching project data');
                });
            }

        }
    }
</script>

<style scoped>
  body{
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
    .el-button{
        margin:30px 0px;
    }
  .coi-card-row{
      border-radius: 8px 8px 0px 0px;
  }
</style>
