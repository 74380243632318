import {AUTH_LOGOUT, AUTH_SET_TOKEN, AUTH_LOAD_USER, AUTH_LOAD_PAY_MODELS} from '../actions/auth'
import HTTP from "../../utilities/http";

const state = {
    token: localStorage.getItem('api-token') || '',
    user: {
        templates: [],
    },
    payModels: [],
    insuranceModels: [],
    payGroups: []
};

const getters = {
    isAuthenticated: state => !!state.token,
    getUser: state => state.user,
    getPayModels: state => state.payModels,
    getPayGroups: state => state.payGroups,
    getInsuranceModels: state => state.insuranceModels
};

const actions = {

    [AUTH_LOGOUT]: ({commit}) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT);
            localStorage.clear();
            delete HTTP.defaults.headers.common['Authorization'];
            resolve();
        });
    },

    [AUTH_SET_TOKEN]: ({commit, dispatch}, query) => {
        let token = query.token;
        if(!token || token == 'null') {
            token = localStorage.getItem('api-token');
        }
        if(token) {
            localStorage.setItem('api-token', token);
            commit(AUTH_SET_TOKEN, token);
            dispatch(AUTH_LOAD_USER).then((user) => {
                localStorage.setItem('parent-gc', user.parent_gc);
                if(user.pay_models) {
                    dispatch(AUTH_LOAD_PAY_MODELS);
                }
            });
        }
    },

    [AUTH_LOAD_USER]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user', method: 'GET'})
                .then(resp => {
                    commit(AUTH_LOAD_USER, resp.data);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [AUTH_LOAD_PAY_MODELS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'user/paymodels', method: 'GET'})
                .then(resp => {
                    commit(AUTH_LOAD_PAY_MODELS, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    [AUTH_SET_TOKEN]: (state, token) => {
        state.token = token;
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = '';
    },
    [AUTH_LOAD_USER]: (state, user) => {
        state.user = user;
    },
    [AUTH_LOAD_PAY_MODELS]: (state, resp) => {
        state.payGroups = resp.data.payGroups;
        state.payModels = resp.data.payModels;
        state.insuranceModels = resp.data.insuranceModels;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
