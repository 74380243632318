export const INIT_PROJECTS = 'INIT_PROJECTS';
export const SET_PROJECTS_STATE = 'SET_PROJECTS_STATE';
export const GET_PROJECTS = 'GET_PROJECTS';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const SEARCH_PROJECTS = 'SEARCH_PROJECTS';
export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER';
export const SET_PROJECT_SORT = 'SET_PROJECT_SORT';
export const SET_PROJECT_SELECTION = 'SET_PROJECT_SELECTION';
export const GET_EMPTY_PROJECT = 'GET_EMPTY_PROJECT';
