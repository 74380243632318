import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from '../components/dashboard/project/Projects'
import Project from "../components/dashboard/project/Project";
import ProjectEdit from "../components/dashboard/project/ProjectEdit";
import Vendors from "../components/dashboard/vendor/Vendors";
import Vendor from "../components/dashboard/vendor/Vendor";
import VendorImport from "../components/dashboard/vendor/VendorImport";
import Policies from "../components/dashboard/policy/Policies";
import Policy from "../components/dashboard/policy/Policy";
import VerificationNew from "../components/dashboard/verification/VerificationNew";
import VendorDashboard from "../components/vendor/VendorDashboard";
import VerificationInvite from "../components/vendor/VerificationInvite";
import VerificationInviteGc from "../components/dashboard/verification/VerificationInviteGc";
import AgentDashboard from "../components/agent/AgentDashboard";
import AgentInvite from "../components/agent/AgentInvite";
import AgentReject from "../components/agent/AgentReject";
import UserInvite from "../components/gc/UserInvite";
import Users from "../components/dashboard/user/Users";
import AgencyDeshboard from "../components/agency/AgencyDashboard";
import Login from "../components/login/Login";
import Forgotpassword from "../components/login/Forgotpassword";
import Resetpassword from "../components/login/Resetpassword";
import UserProfile from "../components/dashboard/user/UserProfile";
import PolicySettings from "../components/dashboard/policy/settings/Policies";
import PolicySettingsEdit from "../components/dashboard/policy/settings/PolicySettingsEdit";
import NewGc from "../components/agency/NewGc";
import Contracts from "../components/dashboard/contract/Contracts";
import Contract from "../components/dashboard/contract/Contract";
import ContractEdit from "../components/dashboard/contract/ContractEdit";
import ContractUpdate from "../components/dashboard/contract/ContractUpdate";
import Report from "@/components/dashboard/export/Report.vue";
import Reports from "@/components/dashboard/export/Reports.vue";
import BulkNotify from "@/components/dashboard/notify/BulkNotify.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/login",
        name: "home",
    },
    {
        path: '/agent',
        component: () => import(/* webpackChunkName: "agent" */ '../views/Agent.vue'),
        children: [
            {
                path: '',
                name: 'agent-dashboard',
                component: AgentDashboard
            },
            {
                path: 'invite/:inviteCode',
                name: 'agent-invite',
                component: AgentInvite
            },
            {
                path: 'change/:inviteCode',
                name: 'agent-change',
                component: AgentReject
            }
        ]
    },
    {
        path: '/vendor',
        component: () => import(/* webpackChunkName: "vendor" */ '../views/Vendor.vue'),
        children: [
            {
                path: '',
                name: 'vendor-dashboard',
                component: VendorDashboard
            },
            {
                path: 'verification/invite/:inviteCode',
                name: 'verification-invite',
                component: VerificationInvite
            }
        ]
    },
    {
        path: '/gc',
        component: () => import(/* webpackChunkName: "vendor" */ '../views/Gc.vue'),
        children: [
            {
                path: 'new-user/:id',
                name: 'user-invite',
                component: UserInvite
            }
        ]
    },
    {
        path: '/agency',
        component: () => import(/* webpackChunkName: "vendor" */ '../views/Agency.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'agency-dashboard',
                component: AgencyDeshboard
            },
            {
                path: 'create-gc',
                name: 'new-gc',
                component: NewGc
            }
        ]
    },
    {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        children: [
            {
                path: '',
                name: 'projects',
                component: Projects
            },
            {
                path: 'project/:id',
                name: 'project',
                component: Project
            },
            {
                path: 'project/edit/:id',
                name: 'project-edit',
                component: ProjectEdit
            },
            {
                path: 'project/create',
                name: 'project-create',
                component: ProjectEdit
            },
            {
                path: 'vendors',
                name: 'vendors',
                component: Vendors
            },
            {
                path: 'users',
                name: 'users',
                component: Users
            },
            {
                path: 'vendor/:id',
                name: 'vendor',
                component: Vendor
            },
            {
                path: 'vendorimport',
                name: 'vendorimport',
                component: VendorImport
            },
            {
                path: 'policies',
                name: 'policies',
                component: Policies
            },
            {
                path: 'policy/:id',
                name: 'policy',
                component: Policy
            },
            {
                path: 'verification/new/:projectId?',
                name: 'verification-new',
                component: VerificationNew
            },
            {
                path: 'verification/agent/:inviteCode',
                name: 'verification-agent',
                component: VerificationInviteGc
            },
            {
                path: 'profile',
                name: 'profile-edit',
                component: UserProfile
            },
            {
                path: 'settings/policies',
                name: 'settings-policies',
                component: PolicySettings
            },
            {
                path: 'settings/edit-template/:id',
                name: 'edit-template',
                component: PolicySettingsEdit
            },
            {
                path: 'contracts',
                name: 'contracts',
                component: Contracts
            },
            {
                path: 'contract',
                name: 'contract',
                component: Contract
            },
            {
                path: 'contract/create',
                name: 'create-contract',
                component: ContractEdit
            },
            {
                path: 'contract/edit/:contractId',
                name: 'edit-contract',
                component: ContractUpdate
            },
            {
                path: 'report',
                name: 'reports',
                component: Reports
            },
            {
                path: 'report/:reportId',
                name: 'export-report',
                component: Report
            },
            {
                path: 'bulk-notify',
                name: 'bulk-notify',
                component: BulkNotify
            },
        ]
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Login.vue'),
        children: [
            {
                path: '',
                name: 'login',
                component: Login
            },
        ]
    },
    {
        path: '/reset',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Login.vue'),
        children: [
            {
                path: 'password',
                name: 'Forgotpassword',
                component: Forgotpassword
            },
            {
                path: 'password/:id',
                name: 'Resetpassword',
                component: Resetpassword
            },
        ]
    },


];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
