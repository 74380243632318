export const INIT_POLICIES = 'INIT_POLICIES';
export const SET_POLICIES_STATE = 'SET_POLICIES_STATE';
export const GET_POLICIES = 'GET_POLICIES';
export const SET_POLICIES = 'SET_POLICIES';
export const SELECT_POLICY = 'SELECT_POLICY';
export const SEARCH_POLICIES = 'SEARCH_POLICIES';
export const SET_POLICY_SEARCH_TEXT = 'SET_POLICY_SEARCH_TEXT';
export const SET_POLICY_FILTER_GROUP = 'SET_POLICY_FILTER_GROUP';
export const SET_POLICY_SORT = 'SET_POLICY_SORT';
export const GET_STATUSES = 'GET_STATUSES';
export const SET_STATUSES = 'SET_STATUSES';
export const ACCEPT_POLICY = 'ACCEPT_POLICY';
export const SAVE_POLICY_CHANGESET = 'SAVE_POLICY_CHANGESET';
export const SAVE_POLICY_CHANGESET_AGENT = 'SAVE_POLICY_CHANGESET_AGENT';
export const GET_ATTACHMENT_LINK = 'GET_ATTACHMENT_LINK';
export const GET_POLICY_FOR_INVITE = 'GET_POLICY_FOR_INVITE';
export const DEACTIVATE_POLICY = 'DEACTIVATE_POLICY';
export const ACTIVATE_POLICY = 'ACTIVATE_POLICY';
export const PAUSE_POLICY = 'PAUSE_POLICY';
export const NON_ELIGIBLE = 'NON_ELIGIBLE';
export const CHANGE_AGENT = 'CHANGE_AGENT';
export const SET_POLICY_TEMPLATE = 'SET_POLICY_TEMPLATE';
export const GET_TICKLER_HISTORY = 'GET_TICKLER_HISTORY';

export const COUNT_BY_STATUS = "COUNT_BY_STATUS";
export const BULK_NOTIFY = "BULK_NOTIFY";