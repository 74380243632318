import {
    INIT_PROJECTS, GET_PROJECTS, SET_PROJECTS_STATE, SET_PROJECTS,
    SELECT_PROJECT, SEARCH_PROJECTS, SET_PROJECT_FILTER, SET_PROJECT_SELECTION,
    SET_PROJECT_SORT, GET_EMPTY_PROJECT
} from '../actions/project'
import HTTP from "../../utilities/http";

const state = {
    initialized: false,
    projects: [],
    selectedProject: {
        user: {
            general_contractor: {
                subcontractors: {
                    policies: []
                }
            }
        }
    },
    filter: '',
    selection: 'all',
    projectSort: {
        title: 'asc',
        status: '',
        risk: '',
        compliant: ''

    },
};

const getters = {
    getProjects: state => state.projects,
    getProject: state => state.selectedProject,
    getProjectFilter: state => state.filter,
    getProjectSelection: state => state.selection,
    getProjectSort: state => state.projectSort,
};

const actions = {



    [GET_EMPTY_PROJECT]: ({commit}) => {
        commit(GET_EMPTY_PROJECT);
    },

    [SEARCH_PROJECTS]: ({commit}, options) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'project/all', data: options, method: 'POST' })
                .then(resp => {
                    commit(SET_PROJECTS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.response.data,
                        type: 'error'
                    });
                    reject(err);
                });
        });
    },

    [SELECT_PROJECT]: ({commit}, projectId) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'project/detail/' + projectId, method: 'GET'})
                .then(resp => {
                    commit(SELECT_PROJECT, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [INIT_PROJECTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if (!state.initialized) {
                commit(SET_PROJECTS_STATE);
                HTTP({url: 'project/all', method: 'GET'})
                    .then(resp => {
                        commit(SET_PROJECTS, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        this.$notify({
                            title: 'Error',
                            message: err.response.data,
                            type: 'error'
                        });
                        reject(err);
                    });
            }
        });
    },

    [GET_PROJECTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'project/all', method: 'GET' })
                .then(resp => {
                    commit(SET_PROJECTS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {

    [SET_PROJECTS]: (state, resp) => {
        state.projects = resp.data;
    },
    [SET_PROJECTS_STATE]: (state) => {
        state.initialized = true;
    },
    [SELECT_PROJECT]: (state, project) => {
        state.selectedProject = project.data;
    },
    [SET_PROJECT_FILTER]: (state, filter) => {
        state.filter = filter;
    },
    [SET_PROJECT_SELECTION]: (state, selection) => {
        state.selection = selection;
    },
    [SET_PROJECT_SORT]: (state, sort) => {
        state.projectSort = sort;
    },
    [GET_EMPTY_PROJECT]: (state) => {
        state.selectedProject = {
            user: {
                general_contractor: {
                    subcontractors: {
                        policies: []
                    }
                }
            }
        };
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
