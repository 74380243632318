import {Notification} from "element-ui";

let Validator = function(fields) {
    let errors = [];
    fields.forEach(element => {
        if(!element.field) {
            errors.push('Please enter ' + element.name);
        }
    });
    if(errors.length) {
        Notification({
            title: 'Missing Information',
            dangerouslyUseHTMLString: true,
            message: errors.join('<br/>'),
            type: 'warning',
            duration: 2500
        });
        return false;
    }
    return true;
};

export default Validator;