import {
    UTILITY_GET_STATES, UTILITY_SET_STATES,
    UTILITY_INIT_DASHBOARD_COUNTS, UTILITY_GET_DASHBOARD_COUNTS,
    UTILITY_SET_DASHBOARD_STATE, UTILITY_SET_DASHBOARD_COUNTS,
} from "../actions/utility";
import HTTP from "../../utilities/http";

const state = {
    states: [],
    initialized: false,
    dashboardCounts: {},
};

const getters = {
    getStates: state => state.states,
    getDashboardCounts: state => state.dashboardCounts,
};

const actions = {

    [UTILITY_INIT_DASHBOARD_COUNTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if(!state.initialized) {
                commit(UTILITY_SET_DASHBOARD_STATE);
                HTTP({url: 'utility/dashboard-counts', method: 'GET' })
                    .then(resp => {
                        commit(UTILITY_SET_DASHBOARD_COUNTS, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
            resolve();
        });
    },

    [UTILITY_GET_DASHBOARD_COUNTS]: ({commit}) => {
        return new Promise((resolve, reject) => {
            HTTP({url: 'utility/dashboard-counts', method: 'GET' })
                .then(resp => {
                    commit(UTILITY_SET_DASHBOARD_COUNTS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    [UTILITY_GET_STATES]: ({commit}) => {
        return new Promise((resolve, reject) => {
            if(!state.states.length) {
                HTTP({url: 'utility/states', method: 'GET' })
                    .then(resp => {
                        commit(UTILITY_SET_STATES, resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
            resolve();
        });
    }
};

const mutations = {
    [UTILITY_SET_STATES]: (state, resp) => {
        state.states = resp.data
    },
    [UTILITY_SET_DASHBOARD_STATE]: (state) => {
        state.initialized = true;
    },
    [UTILITY_SET_DASHBOARD_COUNTS]: (state, resp) => {
        state.dashboardCounts = resp.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
