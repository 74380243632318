import {
    NOTIFY_ERROR, NOTIFY_INFO, NOTIFY_SUCCESS
} from "../actions/notify";

import {Notification} from 'element-ui';

const actions = {

    [NOTIFY_ERROR]: (context, message) => {
        Notification({
            title: 'Error',
            message: message,
            type: 'error'
        });
    },

    [NOTIFY_SUCCESS]: (context, message) => {
        Notification({
            title: 'Success',
            message: message,
            type: 'success'
        });
    },

    [NOTIFY_INFO]: (context, message) => {
        Notification({
            title: 'Message',
            message: message,
            type: 'info'
        });
    },
};

export default {
    actions,
}
