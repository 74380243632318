import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
import project from "./modules/project";
import vendor from "./modules/vendor";
import utility from "./modules/utility";
import policy from "./modules/policy";
import report from "./modules/report";
import notify from "./modules/notify";
import translate from "./modules/translate";
import verification from "./modules/verification";
import agent from "./modules/agent";
import user from "./modules/user";
import settings_policy from "./modules/settings_policy";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({

  modules: {
    auth, project, vendor, utility, policy, notify, report,
    translate, verification, agent, user, settings_policy
  },
  strict: debug,
})
