import axios from 'axios';
const HTTP = axios.create({
    baseURL: process.env.VUE_APP_ROOT + '/api'
});

HTTP.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('api-token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default HTTP;