<template>
    <div class="projects mt-5">
        <div class="flex header">
            <div class="w-1/4">
                <h1 class = 'count pt-3'><translate source = 'Contracts' class = 'float-left mr-2'></translate> ({{ contractCount }})</h1>
            </div>
            <div class="w-1/4 inner-nav">
                <!-- <div class="nav-box flex content-center">
                    <div class="nav-box-all " :class="{ 'nav-selected': selection === 'all'}" v-on:click="selection = 'all'">
                        <translate source = 'All Projects'></translate>
                    </div>
                    <div class="nav-box-issues" :class="{ 'nav-selected': selection === 'issues'}" v-on:click="selection = 'issues'">
                        <translate source = 'Issues'></translate>
                    </div>
                    <div class="nav-box-compliant" :class="{ 'nav-selected': selection === 'compliant'}"  v-on:click="selection = 'compliant'">
                        <translate source = 'Compliant'></translate>
                    </div>
                </div> -->
            </div>
            <div class="w-1/4 ">
                <!-- <div class="search-bar">
                    <div class="search-area">
                        <span > &#x1F50D;  </span>
                        <input v-model = 'filter' v-on:keyup="search" type="text" placeholder='Search Projects'>
                    </div>
                </div> -->
            </div>
            <div class = 'w-1/4'>
                <div class = 'flex'>
                    <div class = 'w-full text-right'>
                        <router-link :to="{name: 'create-contract'}">
                            <button class = 'edit-button float-right'>
                                <translate source = 'New Contract'></translate>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class = 'coi-card'>
            <div class = 'coi-card-border'>
                <div class="coi-card-header flex">
                    <div class="w-6/12 flex sort" v-on:click="sortBy('title')">
                        <div class = 'mr-3'><translate source = 'Project Title'></translate></div>
                        <!-- <div class="sort-none" v-if="sort.title == sortDirection[0]">
                            <font-awesome-icon icon = 'arrows-alt-v'/>
                        </div> -->
                        <!-- <div class="sort-ascending" v-if="sort.title == sortDirection[1]">
                            <font-awesome-icon icon = 'arrow-up' />
                        </div> -->
                        <!-- <div class="sort-descending" v-if="sort.title == sortDirection[2]">
                            <font-awesome-icon icon = 'arrow-down' />
                        </div> -->
                    </div>
                    <div class="w-2/12 flex sort">
                        <div class = 'mr-3'><translate source = 'Contract Title'></translate></div>
                    </div>
                    <div class="w-2/12 flex sort" v-on:click="sortBy('status')">
                        <div class = 'mr-3'><translate source = 'Effective From'></translate></div>
                        <!-- <div class="sort-none" v-if="sort.status == sortDirection[0]">
                            <font-awesome-icon icon = 'arrows-alt-v'/>
                        </div>
                        <div class="sort-ascending" v-if="sort.status == sortDirection[1]">
                            <font-awesome-icon icon = 'arrow-up' />
                        </div>
                        <div class="sort-descending" v-if="sort.status == sortDirection[2]">
                            <font-awesome-icon icon = 'arrow-down' />
                        </div> -->
                    </div>
                    <div class="w-2/12 flex sort" v-on:click="sortBy('risk')">
                        <div class = 'mr-3'><translate source = 'Expiration'></translate></div>
                        <!-- <div class="sort-none" v-if="sort.risk == sortDirection[0]">
                            <font-awesome-icon icon = 'arrows-alt-v'/>
                        </div>
                        <div class="sort-ascending" v-if="sort.risk == sortDirection[1]">
                            <font-awesome-icon icon = 'arrow-up' />
                        </div>
                        <div class="sort-descending" v-if="sort.risk == sortDirection[2]">
                            <font-awesome-icon icon = 'arrow-down' />
                        </div> -->
                    </div>
                    <!-- <div class="w-2/12 flex sort" v-on:click="sortBy('compliant')">
                        <div class = 'mr-3'><translate source = 'Compliant'></translate></div>
                        <div class="sort-none" v-if="sort.compliant == sortDirection[0]">
                            <font-awesome-icon icon = 'arrows-alt-v'/>
                        </div>
                        <div class="sort-ascending" v-if="sort.compliant == sortDirection[1]">
                            <font-awesome-icon icon = 'arrow-up' />
                        </div>
                        <div class="sort-descending" v-if="sort.compliant == sortDirection[2]">
                            <font-awesome-icon icon = 'arrow-down' />
                        </div>
                    </div> -->
		<div class = 'w-2/12'>
			<div>Action</div>
		</div>
                </div>
                <div v-for = "contract in contracts" v-bind:key = 'contract.id'>
                    <div  class = 'coi-card flex my-3'>
                        <div class = 'w-full project-inner'>
                            <div class = 'flex project-header' v-on:click = 'showMore(contract.id)'>
                                <div class = 'w-6/12'>
                                    <h1 :class=contract.id>{{ contract.project_name}}</h1>
                                </div>
                                <div class = 'w-2/12'>
                                    {{ contract.title}}
                                </div>
                                <div class = 'w-2/12'>
                                    <div :class=contract.project_id>{{ contract.effective_date }}</div>
                                </div>
                                <div class = 'w-2/12'>
                                    <div :class=contract.project_id>{{ contract.expiration_date }}</div>
                                </div>
                                <!-- <div class = 'w-2/12'>
                                    <div v-if = "project.status == 'green'">
                                        <div class = 'mt-1 mr-2 green float-left'>compliant</div>
                                        <div class="fa-icon float-left"><font-awesome-icon icon = 'check'></font-awesome-icon></div>
                                    </div>
                                    <div v-else>
                                        <div class = 'mt-1 mr-2 red float-left'>needs attention</div>
                                        <div class="fa-icon fa-icon-red float-left"><font-awesome-icon icon = 'exclamation-triangle'></font-awesome-icon></div>
                                    </div>
                                </div> -->
			<div class = 'w-2/12'>
				<router-link :to = "{name: 'edit-contract', params: {contractId: contract.id}}">
				<el-button
				type = 'success'>Edit</el-button>
				</router-link>
			</div>
                            </div>
                            <!-- <transition name = 'slide'>
                                <div v-if = 'show[index]' class = 'flex-col details'>
                                    <div class="policies">
                                        <div class = 'w-full policy-row mt-3'>
                                            <div v-for = "subcontractor in project.subcontractors" v-bind:key = 'subcontractor.id' class = 'flex policy-detail mb-5 hover:bg-gray-100'>
                                                <div class="w-1/12 fa-icon">
                                                    <font-awesome-icon icon = 'user-tie' />
                                                </div>
                                                <div class="w-11/12">
                                                    <router-link class = 'link' :to = "{name: 'vendor', params: {id: subcontractor.id}}">
                                                        <div class = 'flex w-full'>
                                                            <div class = 'w-6/12'>
                                                                {{ subcontractor.name}}
                                                            </div>
                                                            <div  class = 'w-3/12'>
                                                                <div v-if = 'subcontractor.address'>
                                                                    {{ subcontractor.address }}
                                                                </div>
                                                                <div v-if = 'subcontractor.city || subcontractor.state'>
                                                                    {{ subcontractor.city }} {{ subcontractor.state }} {{ subcontractor.zip}}
                                                                </div>
                                                            </div>
                                                            <div class = 'w-3/12'>
                                                                {{ subcontractor.primary_contact }} : {{ subcontractor.email}}
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-footer flex">
                                        <div class="w-1/6 view">
                                            <a v-on:click = 'goToProject(project.id)' class = 'clickable'><i class="far fa-file-alt">&#10064;</i> View Project</a>
                                        </div>
                                        <div class="w-1/6 edit">
                                            <span v-on:click = 'goEditProject(project.id)' class = 'clickable'><i class="fas fa-pencil-alt">&#9998;</i> Edit Project</span>
                                        </div>
                                    </div>
                                </div>
                            </transition> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HTTP from "../../../utilities/http";
    export default {
        name: 'Contracts',
        data() {
            return {
                show: [],
                sortDirection:['','asc','desc'],
                contracts:[],
                contractCount:0
            }
        },
        methods: {
            newProject() {
                this.$router.push({name: 'project-create'});
            },
            // search() {
            //     let sort = this.getSort();
            //     this.$store.dispatch(SEARCH_CONTRACTS, {
            //         filter: this.filter,
            //         column: sort.column,
            //         direction: sort.direction
            //     }).then().catch();
            // },
            getSort() {
                for (let column in this.sort) {
                    if(this.sort[column].length) {
                        return {
                            column: column,
                            direction: this.sort[column]
                        }
                    }
                }
                return {column: '', direction: ''};
            },
            checkIfShow(status) {
                if(this.selection == 'all')
                {
                    return true;
                }
                if(this.selection == 'issues') {
                    if(status == 'red') {
                        return true;
                    }
                }
                if(this.selection == 'compliant') {
                    if(status != 'red') {
                        return true;
                    }
                }
                return false;
            },
            sortBy(col){
                let sort = JSON.parse(JSON.stringify(this.sort));
                let index = this.sortDirection.indexOf(sort[col]);
                index >=2 ? index = 0 : index ++;
                sort[col] = this.sortDirection[index];
                for (const column in sort){
                    if (column !== col && sort.hasOwnProperty(column)){
                        sort[column] = this.sortDirection[0];
                    }
                }
                this.sort = sort;
                // this.search();
            },
            goToProject(projectId) {
                this.$router.push({name: 'project', params: {id: projectId}});
            },
            goToPolicy(policyId) {
                this.$router.push({name: 'policy', params: {id: policyId}});
            },
            goEditProject(projectId) {
                this.$router.push({name: 'project-edit', params: {id: projectId}});
            },
            showMore(index) {
                if(this.show[index]) {
                    this.$set(this.show, index, false);
                }
                else {
                    this.$set(this.show, index, true);
                }
            },
            _getProjects() {
                HTTP({ url: 'contract/list/', method: 'GET' })
                    .then(resp => {
                        // console.log(resp.data);
                        this.contracts = resp.data;
                        this.contractCount = resp.data.length;
                    })
                    .catch();
                // this.$store.dispatch(INIT_CONTRACTS).then(() => {
                // }).catch();
            },
        },
        computed: {
            // projects: function() {
            //     return this._getProjects();
            // },
            // projectCount: function() {
            //     if(this.selection == 'all')
            //     {
            //         return this.projects.length;
            //     }
            //     let count = 0;
            //     if(this.selection == 'issues') {
            //         this.projects.forEach(function(project) {
            //             if(project.status == 'red') {
            //                 count++;
            //             }
            //         });
            //     }
            //     if(this.selection == 'compliant') {
            //         this.projects.forEach(function(project) {
            //             if(project.status != 'red') {
            //                 count++;
            //             }
            //         });
            //     }
            //     return count;
            // },
            // filter: {
            //     get() {
            //         return this.$store.getters.getProjectFilter;
            //     },
            //     set(value) {
            //         this.$store.commit('SET_PROJECT_FILTER', value);
            //     }
            // },
            // selection: {
            //     get() {
            //         return this.$store.getters.getProjectSelection;
            //     },
            //     set(value) {
            //         this.$store.commit('SET_PROJECT_SELECTION', value);
            //     }
            // },
            // sort: {
            //     get() {
            //         return this.$store.getters.getProjectSort;
            //     },
            //     set(value) {
            //         this.$store.commit('SET_PROJECT_SORT', value);
            //     }
            // }
        },
        mounted() {
            this._getProjects();
        }
    }
</script>

<style scoped>
    .count{
        color: #255897;
        font-family: "Open Sans";
        font-size: 25px;
        font-weight: bold;
        line-height: 34px;
    }
    .nav-box {
        border-radius: 8px;
        color: #566CD3;
        font-family: Muli;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
        width: 300px;
        margin-left: auto;
        margin-right: auto;;
    }

    .nav-box-all, .nav-box-compliant, .nav-box-issues{
        padding: 15px;
        background-color: #E3E8FF;
        text-align: center;
        cursor: pointer;
    }
    .nav-box-all{
        border-radius: 8px 0px 0px 8px;
        border-right: 0.5px solid #566CD3;;
    }
    .nav-box-compliant{
        border-radius: 0px 8px 8px 0px;
        border-left: 0.5px solid #566CD3;;
    }
    .nav-selected{
        background-color: #566CD3;
        color: #ffffff;
    }
    .project-header{
        cursor: pointer;
    }
    .project-inner h1{
        text-align: left;
        margin-right: 30px;
    }
    .project-inner .description{
        font-family: Muli;
        margin-left: 50px;
        padding-top: 7px;
        vertical-align: center;
    }
    .sort{
        cursor: pointer;
    }
    .details{
        background-color: #ffffff;
        text-align: left;
        overflow: hidden;
        position: relative;
    }
    .details .policies {
        height: 300px;
    }
    .policy-row {
        height: 100%;
        position: relative;
        overflow: auto;
    }
    .details .subscript{
        color: grey;
        text-align: left;
    }
    .details .policy-detail{
        padding: 5px 30px 5px 30px;
    }
    .project-footer {
        padding: 2px 30px 22px;
        font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
    }
    .project-footer i{
        font-size: 22px;
        font-weight: bold;
    }
    .project-footer .view{
        padding-top: 35px;
    }
    .project-footer .edit{
        padding-top: 35px;
    }
    .project-footer .note{
        padding-top: 35px;
    }
    .project-footer .tickle {
        text-align: right;
    }
    .project-footer .tickle button{
        padding: 15px 85px;
        border-radius: 16px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.15px;
        line-height: 24px;
        text-align: center;
    }


    .project-inner{
        border-bottom: 4px solid #7286dd;
    }
    .project-header {
        background-color: #ffffff;
        padding: 22px;
    }

    .project-inner .description{
        color: #969696;
    }
    .project-footer .tickle button{
        color: #255897;
        border: 2px solid #7286dd;
    }
    .red {
        color: #be0606;
    }
    .red .project-inner{
        border-bottom: 4px solid #fb3030;
    }
    .red .project-header {
        background-color: #ffecec;
        padding: 22px;
    }
    .red .project-inner h1{
        color: #be0606;
    }
    .red .project-inner .description{
        color: #969696;
    }
    .red .project-footer .tickle button{
        color: #FB3030;
        border: 2px solid red;
    }
    .link {
        cursor:pointer;
    }
</style>
