<template>
  <div>
    <div class="wrapper flex mb-5 mx-5 p-3">
      <div class="w-full">
        <div class="flex w-full mb-5">
          <div class="w-1/2">
            <div class="flex">
              <div class="w-1/3 flex-col">
                <!-- <font-awesome-icon icon="building" />  -->
                Project
              </div>
              <div class="w-5/3 flex-col">
                {{ policy.verification.project.user.general_contractor.name }}
                <!-- <div class="subscript">Subcontractor</div> -->
              </div>
            </div>
          </div>
          <div class="w-1/2">
            <div class="flex">
              <div class="w-1/3 flex-col">Subcontractor</div>
              <div class="flex-col w-5/3">
                {{ policy.verification.sub_name }}
                <!-- <div class="subscript">Name Verification Sent To</div> -->
              </div>
            </div>
          </div>
          <div class="w-1/2">
            <div class="flex">
              <div class="w-1/3 flex-col">
                <!-- <font-awesome-icon icon="envelope" /> -->
                Contract Title
              </div>
              <div class="flex-col w-5/3">
                <div>
                  {{ policy.contract.title }}
                  <!-- <div class="subscript">Email Verification Sent To</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full mb-5">
          <div class="w-1/2">
            <div class="flex">
              <div class="w-1/3 flex-col">
                <!-- <font-awesome-icon icon="phone" /> -->
                Expiration Date
              </div>
              <div class="flex-col w-5/3">
                <div>
                  {{ policy.contract.expiration_date }}
                  <!-- <div class="subscript">'Subcontractor Phone</div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/2">
            <div class="flex">
              <div class="w-1/3 flex-col">
                <!-- <font-awesome-icon icon="envelope" /> -->
                Effective Date
              </div>
              <div class="flex-col w-5/3">
                <div>
                  {{ policy.contract.effective_date }}
                  <!-- <div class="subscript">Agent Email</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full mb-5">
          <div class="w-1/3">
            <div class="flex">
              <div class="w-1/3 flex-col">
                <a
                  target="_blank"
                  :href="`${baseRoot}/app/contract/contract-print/${policy.contract.id}`"
                >
                  <el-button type="info"> Print Contract </el-button>
                </a>
              </div>
              <div class="flex-col w-5/6">
                <div></div>
              </div>
            </div>
          </div>

          <div class="w-1/3">
            <div class="flex"></div>
          </div>
        </div>
        <!-- <div v-if="policy.gc_notes" class="flex w-full mb-5">
          <b class="pr-3">Note:</b>
          {{ policy.gc_notes }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractData",
  props: ["contract", "policy"],
  components: {},
  data() {
    return {
      baseRoot: process.env.VUE_APP_ROOT,
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped>
.wrapper {
  background-color: #f1f4ff;
}
</style>
