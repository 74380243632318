<template>
    <div class="projects mt-5">
        <div class="flex header">
            <div class="w-1/3 count">
                <h1 class="pt-3">Bulk Notification ({{ agents }})</h1>
            </div>
            <div class="w-2/3 inner-nav">
                <div class="nav-box flex content-center">
                    <div class="nav-box-start " :class="{ 'nav-selected': selection === 'agent-started' }"
                        v-on:click="setStatus('agent-started')">
                        <translate source='Agent started'></translate>
                    </div>
                    <div class="nav-box-normal" :class="{ 'nav-selected': selection === 'sent-to-agent' }"
                        v-on:click="setStatus('sent-to-agent')">
                        <translate source='Sent to agent'></translate>
                    </div>
                    <div class="nav-box-normal" :class="{ 'nav-selected': selection === 'does-not-meet-requirements' }"
                        v-on:click="setStatus('does-not-meet-requirements')">
                        <translate source='Does not meet the requirements'></translate>
                    </div>
                    <div class="nav-box-normal" :class="{ 'nav-selected': selection === 'expired' }"
                        v-on:click="setStatus('expired')">
                        <translate source='Expired'></translate>
                    </div>
                    <div class="nav-box-end" :class="{ 'nav-selected': selection === 'expiring' }"
                        v-on:click="setStatus('expiring')">
                        <translate source='Expiring'></translate>
                    </div>
                </div>
            </div>
        </div>

        <el-input type='textarea' :rows='3' placeholder="Notification" v-model='noteText'></el-input>

        <div class="text-right">
            <el-checkbox v-model="gc" label="Copy Sub-contractor"></el-checkbox>
            <el-button type="success" class="mt-2 ml-5" @click="bulkNotify">Send</el-button>
        </div>

    </div>
</template>

<script>
import { COUNT_BY_STATUS, BULK_NOTIFY } from "../../../store/actions/policy";
export default {
    name: "Reports",
    data() {
        return {
            selection: 'agent-started',
            agents: 0,
            gc: false,
            noteText: '',
        };
    },
    mounted() {
        this.setStatus(this.selection);
    },
    methods: {
        setStatus(status) {
            this.selection = status;
            this.$store.dispatch(COUNT_BY_STATUS, { status })
                .then((count) => { this.agents = count; });
        },
        bulkNotify() {
            const _this = this;
            if (this.noteText.length == 0) {
                this.$notify({
                    title: 'Invalid text',
                    message: 'Please type the message input',
                    type: 'error',
                    duration: 2500
                });
                return;
            }

            this.$store.dispatch(BULK_NOTIFY, {
                status: this.selection,
                text: this.noteText,
                gc: this.gc
             }).then(() => {
                
                this.$notify({
                    title: 'Success',
                    message: 'We have added your bulk notification request in queue and will be processed shortly. Thank you!',
                    type: 'success',
                    duration: 5000
                });

                _this.noteText = "";
            });
        }
    }
}
</script>

<style>
.nav-box {
    border-radius: 8px;
    color: #566CD3;
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    ;
}

.nav-box-start,
.nav-box-normal,
.nav-box-end {
    padding: 15px;
    background-color: #E3E8FF;
    text-align: center;
    cursor: pointer;
}

.nav-box-start {
    border-radius: 8px 0px 0px 8px;
    border-right: 0.5px solid #566CD3;
    ;
}

.nav-box-normal {
    border-right: 0.5px solid #566CD3;
}

.nav-box-end {
    border-radius: 0px 8px 8px 0px;
}

.nav-selected {
    background-color: #566CD3;
    color: #ffffff;
}
</style>